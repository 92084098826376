import * as React from "react";
import { Subtract } from "utility-types";

export interface CookieManager {
  getCookie: (name: string) => string | undefined | null;
  setCookie: (name: string, value: string) => void;
  removeCookie: (name: string) => void;
}

const CookieManagerContext = React.createContext<CookieManager>();

export const useCookieManager = () =>
  React.useContext<CookieManager>(CookieManagerContext);

export const useCookieState = (name: string, defaultValue?: any) => {
  const cookieManager = useCookieManager();
  const [state, setState] = React.useState(
    cookieManager.getCookie(name) || defaultValue
  );
  return [
    state,
    (value) => {
      cookieManager.setCookie(name, value);
      setState(value);
    },
  ];
};

type InjectedProps = { cookieManager: CookieManager };

export function withCookieManager<P extends InjectedProps>(
  Component: React.ComponentType<P>
): React.FC<Subtract<P, InjectedProps>> {
  return (props: Subtract<P, InjectedProps>) => {
    const cookieManager = useCookieManager();
    return <Component cookieManager={cookieManager} {...(props as P)} />;
  };
}

export default CookieManagerContext;
