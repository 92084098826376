/**
 * @generated SignedSource<<bcc373e83176e7094bc06244d9830b7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModelGrid_models$data = ReadonlyArray<{
  readonly birthDate: any | null | undefined;
  readonly eyeColor: string | null | undefined;
  readonly hairColor: string | null | undefined;
  readonly height: number | null | undefined;
  readonly id: string;
  readonly lastContentUpdateDate: any | null | undefined;
  readonly location: string | null | undefined;
  readonly male: boolean | null | undefined;
  readonly maxSize: number | null | undefined;
  readonly minSize: number | null | undefined;
  readonly publicName: string | null | undefined;
  readonly shoeSize: number | null | undefined;
  readonly size: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ModelBox_model">;
  readonly " $fragmentType": "ModelGrid_models";
}>;
export type ModelGrid_models$key = ReadonlyArray<{
  readonly " $data"?: ModelGrid_models$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModelGrid_models">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ModelGrid_models",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ModelBox_model"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "male",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eyeColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shoeSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hairColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastContentUpdateDate",
      "storageKey": null
    }
  ],
  "type": "Model",
  "abstractKey": null
};

(node as any).hash = "11902f94b465dee83fc0ae8ec7faf15c";

export default node;
