export default class NumberParser {
  group: RegExp;

  decimalCharacter: string;

  groupingCharacter: string;

  decimal: RegExp;

  numeral: RegExp;

  index: (d: string) => number | undefined;

  allowGrouping: boolean;

  constructor({
    locale,
    allowGrouping = true,
  }: { locale?: string; allowGrouping?: boolean } = {}) {
    this.allowGrouping = allowGrouping;
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const numerals = [
      ...new Intl.NumberFormat(locale, { useGrouping: false }).format(
        9876543210
      ),
    ].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this.groupingCharacter = parts.find((d) => d.type === "group").value;
    this.group = new RegExp(`[${this.groupingCharacter}]`, "g");
    this.decimalCharacter = parts.find((d) => d.type === "decimal").value;
    this.decimal = new RegExp(`[${this.decimalCharacter}]`);
    this.numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this.index = (d) => index.get(d);
  }

  parse(string: string): number {
    const actualString = this.translate(string);
    return actualString ? +actualString : NaN;
  }

  translate(string: string): string | undefined {
    if (string?.endsWith(this.decimalCharacter)) return undefined;
    if (!this.allowGrouping && string?.includes(this.groupingCharacter))
      return undefined;
    return string
      ?.trim()
      .replace(this.group, "")
      .replace(this.decimal, ".")
      .replace(this.numeral, this.index);
  }
}
