import { commitMutation, Environment } from "react-relay";

export default function commitMutationPromise(
  environment: Environment,
  config
): Promise<unknown> {
  return new Promise(
    (resolve: (value: unknown) => void, reject: (reason: any) => void) => {
      commitMutation(environment, {
        ...config,
        onCompleted: (payload, errors) => {
          if (errors) {
            reject(errors[0]);
            return;
          }
          resolve(payload);
        },
        onError: reject,
      });
    }
  );
}
