import * as React from "react";
import { Theme, withTheme } from "@emotion/react";
import Textarea from "react-autosize-textarea";
import InputMask from "react-input-mask";
import composeRefs from "@seznam/compose-react-refs";
import Field, { ChangeValueGetter } from "./Field.tsx";
import CloseIcon from "../icons/CloseIcon";
import { roundedBorderedCss } from "../css";
import SearchIcon from "../icons/SearchIcon";
import MeasuredDiv from "../MeasuredDiv";
import Button from "../Button.tsx";
import DownArrowIcon from "../icons/DownArrowIcon";
import HoverBox, { HoverContent } from "../HoverBox";
import { withUserAgent } from "../UserAgentContext";
import Table from "../Table";
import { validateNonWhitespaceRequired } from "../validators";

const optionColumn = "option";
const columns = [optionColumn];

type Props = {
  multiline?: boolean;
  autoSize?: boolean;
  onEnterKeyPress?: () => void;
  mask?: string;
  maskFormatChars?: Record<string, any>;
  changeValueGetter?: (value: any) => string;
  spellCheck?: string;
  minRows?: number;
  maxRows?: number;
  naked?: boolean;
  paddingTop?: number;
  paddingBottom?: number;
  cancelable?: boolean;
  placeholder?: string;
  options?: any[];
  labelGenerator?: (option: any) => React.ReactNode;
  valueGenerator?: (option: any) => string;
  hoverContent?: HoverContent;
  hoverContentMaxWidth?: number;
  inputColor?: string;
  autoComplete?: string;
  autoCorrect?: string;
  autoCapitalize?: string;
  rightNote?: React.ReactNode;
  showClearButton?: boolean;
} & Omit<React.ComponentPropsWithoutRef<typeof Field>, "renderInput">;

type State = {
  leftControlsWidth?: number;
  rightControlsWidth?: number;
};

class TextField extends React.PureComponent<Props & { theme: Theme }, State> {
  static defaultProps = {
    type: "text",
    autoCorrect: "off",
    autoCapitalize: "off",
    labelGenerator: (option: any) => option,
    valueGenerator: (option: any) => option,
  };

  state: State = {};

  inputRef = React.createRef<HTMLInputElement | HTMLTextAreaElement>();

  fieldRef = React.createRef<HTMLDivElement>();

  lastRemovedCharacter?: string;

  changeValueGetter: ChangeValueGetter = (event) => {
    const { changeValueGetter } = this.props;
    const value =
      event?.target?.value !== undefined ? event?.target?.value : event;
    return changeValueGetter ? changeValueGetter(value) : value;
  };

  focus = () => this.inputRef.current?.focus();

  onLeftControlsMeasuredChange = ({ width }: { width: number }) =>
    this.setState({ leftControlsWidth: width });

  onRightControlsMeasuredChange = ({ width }: { width: number }) =>
    this.setState({ rightControlsWidth: width });

  copy() {
    this.inputRef.current?.select();
    document.execCommand("copy");
    this.inputRef.current?.setSelectionRange(0, 0);
  }

  render() {
    const {
      name,
      spellCheck,
      minRows,
      maxRows,
      placeholder,
      autoComplete,
      autoCapitalize,
      autoCorrect,
      onEnterKeyPress,
      cancelable,
      userAgent,
      multiline,
      autoSize,
      mask,
      maskFormatChars,
      type,
      theme,
      naked,
      paddingTop,
      paddingBottom,
      options,
      valueGenerator,
      labelGenerator,
      inputColor,
      hoverContent: hoverContentProp,
      hoverContentMaxWidth: hoverContentMaxWidthProp,
      rightNote,
    } = this.props;

    let hoverContent = hoverContentProp;
    let hoverContentMaxWidth = hoverContentMaxWidthProp;

    return (
      <Field
        {...this.props}
        requiredValidator={
          type === "password" ? undefined : validateNonWhitespaceRequired
        }
        changeValueGetter={this.changeValueGetter}
        focus={this.focus}
        renderInput={(
          { id, value, onChange, onFocus, onBlur, readOnly },
          { focused, validationErrorMessages, focusNextField }
        ) => {
          const { leftControlsWidth, rightControlsWidth } = this.state;
          const searchType = type === "search";
          const enableClearButton = searchType;
          const showClearButton =
            this.props.showClearButton || enableClearButton;

          const mainPaddingTop =
            paddingTop !== undefined
              ? paddingTop
              : naked
              ? 0
              : Math.floor(5 * theme.size);
          const mainPaddingBottom =
            paddingBottom !== undefined
              ? paddingBottom
              : naked
              ? 0
              : Math.floor(5 * theme.size);

          const renderField = ({
            ref,
            hideContent,
          }: { ref?: any; hideContent?: boolean } = {}) => {
            const paddingLeftRight = "calc(8 * var(--size))";

            const css = {
              main: {
                border: 0,
                paddingLeft: naked || searchType ? 0 : paddingLeftRight,
                paddingRight: naked ? 0 : paddingLeftRight,
                paddingTop: mainPaddingTop,
                paddingBottom: mainPaddingBottom,
                boxSizing: "border-box",
                fontFamily: "inherit",
                fontSize: "var(--font-size)",
                fontWeight: "inherit",
                color: inputColor || "inherit",
                "::placeholder": {
                  color: "var(--note-color)",
                },
                background: "transparent",
                width: "100%",
                margin: 0,
                appearance: "none", // Remove native styling
                backgroundClip: "padding-box", // For some reason the appearance:none doesn't remove inner drop shadow for iOS, but this does
                overflow: "auto", // Remove scrollbars in ie
                display: "block", // Fix bottom margin in ff & chrome (as it is inline-block by default)
                textOverflow: "ellipsis",
                resize: multiline ? "none" : undefined,
                lineHeight: multiline ? "var(--line-height)" : undefined,
                height: multiline
                  ? undefined
                  : mainPaddingTop +
                    mainPaddingBottom +
                    theme.lineHeight * theme.fontSize, // Because of problems setting lineHeight in various browsers we need to set it using height, and thus include top & bottom padding there as well
              },
            };

            const inputProps = {
              name,
              type,
              spellCheck,
              placeholder,
              autoComplete,
              autoCorrect,
              autoCapitalize,
              id,
              value,
              onChange,
              onFocus,
              onBlur,
              readOnly,
              css: css.main,
              visibility: hideContent ? "hidden" : "visible",
              onKeyUp:
                onEnterKeyPress || searchType || focusNextField
                  ? (event: React.KeyboardEvent<HTMLInputElement>) => {
                      if (event.key === "Enter" && !multiline) {
                        if (onEnterKeyPress) onEnterKeyPress();
                        else if (focusNextField) focusNextField();
                      } else if (searchType && event.key === "Escape")
                        onChange();
                    }
                  : undefined,
            };

            if (inputProps.value === undefined || inputProps.value === null)
              inputProps.value = "";

            // iOS bug fix (https://bugs.webkit.org/show_bug.cgi?id=148504)
            if (
              inputProps.autoCapitalize === "words" &&
              (userAgent?.includes("iPhone") || userAgent?.includes("iPad"))
            ) {
              inputProps.autoCapitalize = "on";
              inputProps.onChange = (event) => {
                let { value: newValue } = event.target;
                const lengthDiff = newValue.length - value?.length;

                if (lengthDiff === -1) {
                  this.lastRemovedCharacter = value.charAt(value.length - 1);
                } else {
                  if (lengthDiff === 1 && value?.endsWith(" ")) {
                    const newCharacter = newValue.charAt(newValue.length - 1);
                    if (
                      newCharacter.toLocaleLowerCase() !==
                      this.lastRemovedCharacter?.toLocaleLowerCase()
                    )
                      newValue = `${value}${newCharacter.toLocaleUpperCase()}`;
                  }
                  this.lastRemovedCharacter = undefined;
                }

                onChange(newValue);
              };
            }

            if (searchType) inputProps.type = "text";
            else if (type === "numeric") {
              // Fallback for older browsers
              inputProps.inputMode = "numeric";
              inputProps.pattern = "\\d*";
              inputProps.noValidate = true;
            } else if (type === "decimal") inputProps.inputMode = "decimal";

            let field;

            if (multiline) {
              field = autoSize ? (
                <Textarea
                  {...inputProps}
                  ref={this.inputRef}
                  rows={minRows}
                  maxRows={maxRows}
                />
              ) : (
                <textarea {...inputProps} ref={this.inputRef} rows={minRows} />
              );
            } else
              field = mask ? (
                <InputMask
                  {...inputProps}
                  mask={mask}
                  formatChars={maskFormatChars}
                  inputRef={(ref) => {
                    this.inputRef.current = ref;
                  }}
                />
              ) : (
                <input {...inputProps} ref={this.inputRef} />
              );

            return (
              <div
                ref={composeRefs(this.fieldRef, ref)}
                css={{
                  ...(naked
                    ? {}
                    : roundedBorderedCss({
                        color:
                          (focused && "var(--main-color)") ||
                          (validationErrorMessages && "var(--error-color)") ||
                          "var(--separator-color)",
                      })),
                  background: naked ? undefined : "var(--background-color)",
                  paddingRight: rightControlsWidth,
                  paddingLeft: leftControlsWidth,
                }}
              >
                {field}
              </div>
            );
          };

          const clear: React.MouseEventHandler = () => onChange();

          if (options?.length) {
            hoverContent = ({
              fullscreen,
              safeAreaInsets,
              hideHoverContent,
              maxHeight,
            }) => (
              <MeasuredDiv
                width
                height
                css={{
                  width: "100%",
                  height: fullscreen ? "100%" : undefined,
                  display: "flex",
                }}
              >
                {({ width, height }) =>
                  !!width && (
                    <Table
                      css={{ margin: "auto" }}
                      width={width}
                      maxHeight={fullscreen ? height : maxHeight}
                      items={options}
                      selectedItemKeys={
                        (value !== undefined &&
                          (Array.isArray(value) ? value : [value])) ||
                        undefined
                      }
                      itemKeyGenerator={valueGenerator}
                      columns={columns}
                      hideRowSeparator
                      rowCellRenderer={({ item }) => (
                        <Button
                          css={{ display: "block" }}
                          type="discrete"
                          labelAlign={fullscreen ? "center" : "left"}
                        >
                          {labelGenerator(item)}
                        </Button>
                      )}
                      onRowClick={({ item }) => {
                        onChange(valueGenerator(item));
                        hideHoverContent();
                      }}
                      paddingTop={
                        safeAreaInsets.top + Math.floor(theme.spacing / 2)
                      }
                      paddingBottom={
                        safeAreaInsets.bottom + Math.floor(theme.spacing / 2)
                      }
                      contentPaddingLeft={theme.spacing + safeAreaInsets.right}
                      contentPaddingRight={theme.spacing + safeAreaInsets.right}
                    />
                  )
                }
              </MeasuredDiv>
            );

            hoverContentMaxWidth =
              this.fieldRef.current?.getBoundingClientRect().width;
          }

          const renderImpl = ({ ref, toggleHoverContent } = {}) => {
            return searchType ||
              enableClearButton ||
              cancelable ||
              toggleHoverContent ||
              rightNote ? (
              <div ref={ref} css={{ position: "relative" }}>
                {renderField({
                  hideContent:
                    leftControlsWidth === undefined ||
                    rightControlsWidth === undefined,
                })}

                {searchType && (
                  <MeasuredDiv
                    width
                    css={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                    }}
                    onChange={this.onLeftControlsMeasuredChange}
                  >
                    <div
                      css={{
                        display: "table",
                        tableLayout: "fixed",
                        height: "100%",
                        ">*": {
                          display: "table-cell",
                          verticalAlign: "middle",
                          paddingTop,
                          paddingBottom,
                          paddingLeft: naked ? 0 : 13 * theme.size,
                          paddingRight: theme.spacing / 2,
                          ">*": {
                            display: "block",
                          },
                        },
                      }}
                      onClick={this.focus}
                    >
                      <div>
                        <SearchIcon
                          height={theme.iconSize}
                          color="var(--note-color)"
                        />
                      </div>
                    </div>
                  </MeasuredDiv>
                )}
                <MeasuredDiv
                  width
                  css={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                  }}
                  onChange={this.onRightControlsMeasuredChange}
                >
                  <div
                    css={{
                      position: "relative",
                      height: "100%",
                      paddingTop,
                      paddingBottom,
                      boxSizing: "border-box",
                      display: "flex",
                      ">*": {
                        height: "100%",
                        ":not(:last-child)": {
                          marginRight: Math.floor(theme.spacing / 2),
                        },
                        ":last-child": {
                          paddingRight: naked ? 0 : "calc(10 * var(--size))",
                        },
                      },
                    }}
                  >
                    {rightNote && (
                      <div
                        css={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          color: "var(--note-color)",
                        }}
                      >
                        {rightNote}
                      </div>
                    )}
                    {cancelable && focused && (!showClearButton || !value) && (
                      <Button type="discrete" size={0.8}>
                        Cancel
                      </Button>
                    )}
                    {showClearButton && value && (
                      <Button
                        type="discrete"
                        iconComponent={CloseIcon}
                        onMouseDown={clear}
                      />
                    )}
                    {toggleHoverContent && (
                      <Button
                        type="discrete"
                        iconComponent={DownArrowIcon}
                        onClick={toggleHoverContent}
                      />
                    )}
                  </div>
                </MeasuredDiv>
              </div>
            ) : (
              renderField(ref)
            );
          };

          return hoverContent ? (
            <HoverBox
              hoverContent={hoverContent}
              hoverContentMaxWidth={hoverContentMaxWidth}
            >
              {renderImpl}
            </HoverBox>
          ) : (
            renderImpl()
          );
        }}
      />
    );
  }
}

export default withUserAgent(withTheme(TextField));
