import * as React from "react";

export default function Svg({
  width,
  height,
  children,
  ...otherProps
}: {
  width?: number;
  height?: number;
  viewBox: string;
} & React.ComponentPropsWithoutRef<"svg">): React.ReactElement {
  const { viewBox } = otherProps;
  const viewBoxComponents = viewBox.split(" ").map(Number.parseFloat);

  let actualWidth = width;
  let actualHeight = height;

  if (actualWidth === undefined && actualHeight === undefined) {
    actualWidth = viewBoxComponents[2];
    actualHeight = viewBoxComponents[3];
  } else if (actualWidth === undefined && actualHeight !== undefined)
    actualWidth = (viewBoxComponents[2] / viewBoxComponents[3]) * actualHeight;
  else if (actualHeight === undefined && actualWidth !== undefined)
    actualHeight = (viewBoxComponents[3] / viewBoxComponents[2]) * actualWidth;

  return (
    <svg
      {...otherProps}
      css={{
        width: actualWidth,
        height: actualHeight,
      }}
    >
      {children}
    </svg>
  );
}
