import { Base64 } from "js-base64"; // btoa doesn't support unicode
import { fetchQuery, graphql } from "react-relay";
import createRelayEnvironment from "./createRelayEnvironment";

export type Auth = {
  auth: string;
  userId: string;
  userRoles?: string[];
};

export default function authenticateWithServer({
  auth,
  username,
  password,
}: {
  auth?: Auth;
  username?: string;
  password?: string;
}): Promise<Auth> {
  const actualAuth =
    auth?.auth ||
    (username !== undefined &&
      password !== undefined &&
      Base64.encode(`${username}:${password}`));
  const relayEnvironment = createRelayEnvironment({
    getAuth: () => ({
      auth: actualAuth,
    }),
  });

  return fetchQuery(
    relayEnvironment,
    graphql`
      query authenticateWithServerQuery {
        me {
          id
        }
      }
    `,
    {}
  )
    .toPromise()
    .then(({ me }) => ({
      auth: actualAuth,
      userId: me.id,
      userRoles: me.roles,
    }));
}
