import UserFriendlyMessageError, {
  Options as ParentOptions,
} from "./UserFriendlyMessageError";

export type Options = ParentOptions;

export default class AuthenticationError extends UserFriendlyMessageError {
  constructor({
    userFriendlyMessage = "Authentication error",
    ...otherOptions
  }: Options = {}) {
    super({ ...otherOptions, userFriendlyMessage });
    this.name = this.constructor.name;
  }
}
