import * as React from "react";
import { Theme, withTheme } from "@emotion/react";
import { VariableSizeGrid as Grid } from "react-window";
import { noteCss } from "./css";
import deepEqual from "./deepEqual";
import MeasuredDiv from "./MeasuredDiv";
import getScrollbarWidth from "./getScrollbarWidth";
import LoadingIndicator from "./LoadingIndicator";

const InnerTableElementContext =
  React.createContext<React.ReactNode>(undefined);

const InnerTableElement = React.forwardRef(
  ({ children, ...otherProps }, ref) => (
    <div ref={ref} {...otherProps}>
      {children}
      <InnerTableElementContext.Consumer>
        {(value) => value}
      </InnerTableElementContext.Consumer>
    </div>
  )
);

function Cell({
  onPress,
  ...otherProps
}: {
  onPress?: () => void;
} & React.HTMLAttributes<HTMLDivElement>) {
  const startYPositionRef = React.useRef<number>();
  const cleanUpFunctionRef = React.useRef<() => void>();

  const setRef = React.useCallback(
    (ref?: HTMLDivElement) => {
      if (cleanUpFunctionRef.current) {
        cleanUpFunctionRef.current();
        cleanUpFunctionRef.current = undefined;
      }

      if (ref && onPress) {
        const startAction = () => {
          startYPositionRef.current = ref.getBoundingClientRect().y;
        };

        const endAction = (event: MouseEvent | TouchEvent) => {
          if (ref.getBoundingClientRect().y === startYPositionRef.current) {
            startYPositionRef.current = undefined;
            onPress();
            event.preventDefault();
          }
        };

        ref.addEventListener("touchstart", startAction);
        ref.addEventListener("mousedown", startAction);
        ref.addEventListener("touchend", endAction);
        ref.addEventListener("mouseup", endAction);

        cleanUpFunctionRef.current = () => {
          ref.removeEventListener("touchstart", startAction);
          ref.removeEventListener("mousedown", startAction);
          ref.removeEventListener("touchend", endAction);
          ref.removeEventListener("mouseup", endAction);
        };
      }
    },
    [onPress]
  );

  return <div ref={setRef} {...otherProps} />;
}

const borderHeight = 1;

interface Props<T> {
  theme: Theme;
  className?: string;
  items: T[];
  itemCount?: number;
  width: number;
  height?: number;
  maxHeight?: number;
  contentMinWidth?: number | true;
  contentPaddingRight?: number | true;
  contentPaddingLeft?: number | true;
  paddingTop: number;
  paddingRight: number;
  paddingBottom: number;
  paddingLeft: number;
  noItemsLabel: React.ReactNode;
  itemGetter: (props: { index: number; items: any[] }) => T;
  columns: string[];
  updateRowsFunctionSetter?: (updateRows: () => void) => void;
  selectedItemKeys?: any[];
  rowCellRenderer?: (props: {
    index: number;
    item: T;
    property: string;
    itemSelected: boolean;
  }) => React.ReactNode;
  rowCellComponent?: React.ElementType;
  onRowsRendered?: (props: {
    overscanStartIndex: number;
    overscanStopIndex: number;
    startIndex: number;
    stopIndex: number;
  }) => void;
  onRowClick?: (props: { item: T }) => void;
  defaultColumnMinWidth?: boolean | number;
  defaultColumnMinWidths?: { [column: string]: number };
  defaultColumnWidths?:
    | { [column: string]: number }
    | (({ netMinContentWidth }: { netMinContentWidth: number }) => {
        [column: string]: number;
      });
  headerCellRenderer?: (props: { property: string }) => React.ReactNode;
  headerCellComponent?: React.ElementType;
  itemKeyGenerator: (item: T) => any;
  footer?: React.ReactNode;
  onDeselect?: () => void;
  hideRowSeparator?: boolean;
  loadingIndicatorComponent?: React.ElementType;
}

type State = {
  headerHeight?: number;
  footerHeight?: number;
  rowHeight?: number;
  selectedItemKeys?: any[];
};

class Table<T> extends React.PureComponent<Props<T>, State> {
  static defaultProps = {
    itemGetter: ({ index, items }: { index: number; items: any[] }) =>
      items[index],
    noItemsLabel: "Nothing to show.",
    itemKeyGenerator: (item: { id: any }) => item.id,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    loadingIndicatorComponent: LoadingIndicator,
  };

  state: State = {};

  grid?: Grid | null;

  constructor(props: Props<T>) {
    super(props);
    if (props.updateRowsFunctionSetter)
      props.updateRowsFunctionSetter(this.updateRows);
  }

  componentDidUpdate(prevProps: Props<T>, prevState: State) {
    const {
      width,
      columns,
      defaultColumnMinWidth,
      defaultColumnMinWidths,
      selectedItemKeys,
      items,
      theme,
      paddingTop,
      paddingBottom,
    } = this.props;
    const { rowHeight } = this.state;

    if (
      width !== prevProps.width ||
      !deepEqual(columns, prevProps.columns) ||
      defaultColumnMinWidth !== prevProps.defaultColumnMinWidth ||
      !deepEqual(defaultColumnMinWidths, prevProps.defaultColumnMinWidths) ||
      !deepEqual(selectedItemKeys, prevProps.selectedItemKeys) ||
      !deepEqual(items, prevProps.items) ||
      !deepEqual(theme, prevProps.theme) ||
      paddingTop !== prevProps.paddingTop ||
      paddingBottom !== prevProps.paddingBottom ||
      rowHeight !== prevState.rowHeight
    )
      this.updateRows();
  }

  getCellSpacing = () => {
    return this.props.theme.spacing;
  };

  getColumnWidth = (index: number) =>
    this.getColumnWidths()[this.getColumns()[index]];

  getRowHeight = (index: number) => {
    const topPaddingItemHeight = this.getTopPaddingItemHeight();
    if (index === 0 && topPaddingItemHeight) return topPaddingItemHeight;

    const itemCount = this.getItemCount();

    const bottomPaddingItemHeight = this.getBottomPaddingItemHeight();
    if (index === itemCount - 1 && bottomPaddingItemHeight)
      return bottomPaddingItemHeight;

    const { rowHeight } = this.state;
    const { hideRowSeparator } = this.props;
    if (rowHeight === undefined || !itemCount) return 0;
    return (
      rowHeight +
      (index < itemCount - (bottomPaddingItemHeight ? 2 : 1) &&
      itemCount !== 1 &&
      !hideRowSeparator
        ? borderHeight
        : 0)
    );
  };

  getColumns() {
    const { columns } = this.props;
    return columns;
  }

  getContentHeight() {
    const { hideRowSeparator } = this.props;
    const { rowHeight } = this.state;
    if (rowHeight === undefined) return undefined;
    const realItemCount = this.getRealItemCount();
    return (
      (this.getTopPaddingItemHeight() || 0) +
      realItemCount * rowHeight +
      (realItemCount - 1) * (hideRowSeparator ? 0 : borderHeight) +
      (this.getBottomPaddingItemHeight() || 0)
    );
  }

  getScrollbarWidth(): number {
    const { height, maxHeight } = this.props;
    const contentHeight = this.getContentHeight();
    if (contentHeight === undefined) return 0;
    return (height !== undefined && height < contentHeight) ||
      (maxHeight !== undefined && maxHeight < contentHeight)
      ? getScrollbarWidth()
      : 0;
  }

  getMargins() {
    const { width, paddingLeft, paddingRight } = this.props;
    const extraMargin = Math.max(
      0,
      (width -
        this.getScrollbarWidth() -
        this.getContentWidth() -
        paddingLeft -
        paddingRight) /
        2
    );
    return {
      marginLeft: paddingLeft + extraMargin,
      marginRight: paddingRight + extraMargin,
    };
  }

  getColumnWidths() {
    const {
      width,
      paddingLeft,
      paddingRight,
      defaultColumnWidths: propsDefaultColumnWidths,
      defaultColumnMinWidth,
      defaultColumnMinWidths,
      contentMinWidth: propsContentMinWidth,
    } = this.props;

    const columns = this.getColumns();
    const columnCount = columns.length;
    const cellSpacing = this.getCellSpacing();
    const contentPaddingLeft = this.getContentPaddingLeft();
    const contentPaddingRight = this.getContentPaddingRight();

    const widths: { [column: string]: number } = {};
    let growableColumnCount = columnCount;
    let widthsSum = 0;

    let actualDefaultColumnMinWidth;
    if (defaultColumnMinWidth === true) actualDefaultColumnMinWidth = 150;
    else if (typeof defaultColumnMinWidth === "number")
      actualDefaultColumnMinWidth = defaultColumnMinWidth;

    const contentMinWidth =
      (propsContentMinWidth !== undefined
        ? propsContentMinWidth
        : width - paddingLeft - paddingRight) - this.getScrollbarWidth();

    const defaultColumnWidths =
      typeof propsDefaultColumnWidths === "function"
        ? propsDefaultColumnWidths({
            netMinContentWidth:
              contentMinWidth -
              contentPaddingLeft -
              contentPaddingRight -
              (columnCount - 1) * cellSpacing,
          })
        : propsDefaultColumnWidths || {};

    for (let i = 0; i < columnCount; i += 1) {
      const column = columns[i];

      let width = defaultColumnWidths ? defaultColumnWidths[column] : undefined;
      if (width !== undefined) growableColumnCount -= 1;
      else {
        width = defaultColumnMinWidths
          ? defaultColumnMinWidths[column]
          : undefined;
        if (width === undefined && actualDefaultColumnMinWidth !== undefined)
          width = actualDefaultColumnMinWidth;
      }

      if (width !== undefined) {
        widths[column] = width;
        widthsSum += width;
      }
    }

    const remainingColumnContentMinWidthPerColumn = Math.max(
      0,
      (contentMinWidth -
        contentPaddingLeft -
        contentPaddingRight -
        (columnCount - 1) * cellSpacing -
        widthsSum) /
        growableColumnCount
    );

    for (let i = 0; i < columnCount; i += 1) {
      const column = columns[i];

      let width = widths[column] || 0;
      if (!defaultColumnWidths || defaultColumnWidths[column] === undefined)
        width += remainingColumnContentMinWidthPerColumn;
      if (i < columnCount - 1) width += cellSpacing;
      if (i === 0) width += contentPaddingLeft;
      if (i === columnCount - 1) width += contentPaddingRight;
      widths[column] = width;
    }

    return widths;
  }

  getRealItemCount() {
    const { itemCount, items } = this.props;
    return itemCount !== undefined ? itemCount : items?.length;
  }

  getItemCount() {
    return (
      this.getRealItemCount() +
      (this.getTopPaddingItemHeight() ? 1 : 0) +
      (this.getBottomPaddingItemHeight() ? 1 : 0)
    );
  }

  getTopPaddingItemHeight() {
    const { theme, paddingTop } = this.props;
    if (this.isHeadered())
      return Math.floor(theme.spacing / 2) + (this.state.headerHeight || 0);
    if (paddingTop) return Math.floor(paddingTop - theme.spacing / 2);
    return undefined;
  }

  getBottomPaddingItemHeight() {
    const { paddingBottom, theme } = this.props;
    if (this.isShowingFooter())
      return Math.floor(theme.spacing / 2) + (this.state.footerHeight || 0);
    if (paddingBottom) return Math.floor(paddingBottom - theme.spacing / 2);
    return undefined;
  }

  setGrid = (grid: Grid | null) => {
    this.grid = grid;
  };

  getContentWidth() {
    return Object.values(this.getColumnWidths()).reduce(
      (accumulated, current) => accumulated + current,
      0
    );
  }

  getContentPaddingRight() {
    const { theme, contentPaddingRight } = this.props;
    return (
      (contentPaddingRight === true
        ? theme.paddingRight
        : contentPaddingRight) || 0
    );
  }

  getContentPaddingLeft() {
    const { theme, contentPaddingLeft } = this.props;
    return (
      (contentPaddingLeft === true ? theme.paddingLeft : contentPaddingLeft) ||
      0
    );
  }

  getItem(index: number) {
    const { itemGetter, items } = this.props;
    const hasTopPaddingItem = !!this.getTopPaddingItemHeight();
    if (index === 0 && hasTopPaddingItem) return undefined;
    return itemGetter({ index: index - (hasTopPaddingItem ? 1 : 0), items });
  }

  updateRows = () => {
    const { rowHeight } = this.state;
    if (rowHeight === undefined) this.forceUpdate();
    this.grid?.resetAfterIndices({ columnIndex: 0, rowIndex: 0 });
  };

  isItemSelected = (item: any) => {
    const { selectedItemKeys, itemKeyGenerator } = this.props;
    return (
      item !== undefined && selectedItemKeys?.includes(itemKeyGenerator(item))
    );
  };

  onHeaderMeasuredChange = ({ height }: { height: number }) => {
    this.setState({ headerHeight: height }, () =>
      this.grid?.resetAfterIndices({ columnIndex: 0, rowIndex: 0 })
    );
  };

  onFooterMeasuredChange = ({ height }: { height: number }) =>
    this.setState({ footerHeight: height });

  onRowMeasuredChange = ({ height }: { height: number }) => {
    if (height !== undefined) this.setState({ rowHeight: height });
    this.grid?.forceUpdate();
  };

  isHeadered() {
    const { headerCellComponent, headerCellRenderer } = this.props;
    return !!(headerCellComponent || headerCellRenderer);
  }

  isShowingFooter() {
    const { footer, itemCount, items } = this.props;
    return footer && (itemCount !== undefined || items);
  }

  renderCell = ({
    columnIndex,
    rowIndex,
    style,
    skipExtras,
  }: {
    columnIndex: number;
    rowIndex: number;
    style: any;
    skipExtras: boolean;
  }) => {
    const item = this.getItem(rowIndex);
    if (item === undefined) return null;

    const {
      rowCellRenderer,
      rowCellComponent: RowCellComponent,
      onRowClick,
      theme,
      hideRowSeparator,
    } = this.props;
    const itemCount = this.getItemCount();
    const itemSelected = this.isItemSelected(item);
    const columns = this.getColumns();
    const column = columns[columnIndex];

    const cellProps = {
      index: rowIndex,
      item,
      property: column,
      itemSelected,
    };

    const paddingTop = Math.floor(theme.spacing / 2);
    const paddingBottom = paddingTop;
    const contentPaddingLeft = this.getContentPaddingLeft();
    const contentPaddingRight = this.getContentPaddingRight();
    const firstColumn = columnIndex === 0;
    const lastColumn = columnIndex === columns.length - 1;
    const margins = this.getMargins();
    const leftExtra = firstColumn && margins.marginLeft;
    const rightExtra = lastColumn && margins.marginRight;

    return (
      <Cell
        style={style}
        css={{
          ...this.getMargins(),
          position: "relative",
          cursor: onRowClick ? "pointer" : "default",
          paddingTop,
          paddingBottom,
          paddingLeft: firstColumn ? contentPaddingLeft : 0,
          boxSizing: "border-box",
          whiteSpace: "nowrap",
          verticalAlign: "middle",
          paddingRight: lastColumn
            ? contentPaddingRight
            : this.getCellSpacing(),
          borderBottom:
            !hideRowSeparator &&
            !skipExtras &&
            rowIndex <
              itemCount - (this.getBottomPaddingItemHeight() ? 2 : 1) &&
            itemCount !== 1
              ? `${borderHeight}px solid var(--weakest-separator-color)`
              : 0,
        }}
        onPress={
          onRowClick
            ? () => {
                this.props.onRowClick({
                  item,
                });
              }
            : undefined
        }
      >
        {itemSelected && (
          <div
            css={{
              position: "absolute",
              top:
                -(hideRowSeparator ? 0 : borderHeight) +
                paddingTop -
                Math.floor(theme.spacing / 2),
              right: rightExtra ? -Math.min(theme.spacing / 2, rightExtra) : 0,
              bottom:
                -(hideRowSeparator ? 0 : borderHeight) +
                paddingBottom -
                Math.floor(theme.spacing / 2),
              left: leftExtra ? -Math.min(theme.spacing / 2, leftExtra) : 0,
              background: "var(--alternative-background-color)",
              borderTopLeftRadius: leftExtra
                ? theme.roundCornerBorderRadius
                : 0,
              borderBottomLeftRadius: leftExtra
                ? theme.roundCornerBorderRadius
                : 0,
              borderTopRightRadius: rightExtra
                ? theme.roundCornerBorderRadius
                : 0,
              borderBottomRightRadius: rightExtra
                ? theme.roundCornerBorderRadius
                : 0,
            }}
          />
        )}
        <div
          css={{
            position: "relative",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {item !== undefined &&
            ((RowCellComponent && <RowCellComponent {...cellProps} />) ||
              (rowCellRenderer
                ? rowCellRenderer(cellProps)
                : cellProps.item[cellProps.property]))}
        </div>
      </Cell>
    );
  };

  render() {
    const {
      theme,
      className,
      width,
      height,
      maxHeight,
      noItemsLabel,
      onRowsRendered,
      paddingTop,
      paddingBottom,
      footer,
      headerCellRenderer,
      headerCellComponent: HeaderCellComponent,
      loadingIndicatorComponent: LoadingIndicatorComponent,
    } = this.props;
    const { rowHeight, headerHeight, footerHeight } = this.state;

    const columns = this.getColumns();
    const columnCount = columns.length;
    const columnWidths = this.getColumnWidths();
    const margins = this.getMargins();
    const contentPaddingLeft = this.getContentPaddingLeft();
    const contentPaddingRight = this.getContentPaddingRight();
    const showHeader = this.isHeadered();
    const showFooter = this.isShowingFooter();
    const cellSpacing = this.getCellSpacing();
    const topPaddingItemHeight = this.getTopPaddingItemHeight();
    const realItemCount = this.getRealItemCount();
    const contentHeight = this.getContentHeight();

    return (
      <div className={className}>
        <div
          css={{
            position: "relative",
            width,
            height,
            maxHeight,
          }}
        >
          {
            /* Measure row height */
            !!realItemCount &&
              this.getItem(topPaddingItemHeight ? 1 : 0) !== undefined && (
                <MeasuredDiv
                  height
                  css={{
                    position: "absolute",
                    top: 0,
                    width,
                    visibility: "hidden",
                    display: "flex",
                  }}
                  onChange={this.onRowMeasuredChange}
                >
                  {columns.map((property, propertyIndex) => (
                    <div key={property}>
                      {this.renderCell({
                        rowIndex: topPaddingItemHeight ? 1 : 0,
                        columnIndex: propertyIndex,
                        style: undefined,
                        skipExtras: true,
                      })}
                    </div>
                  ))}
                </MeasuredDiv>
              )
          }

          {((realItemCount === undefined ||
            (realItemCount && rowHeight === undefined)) && (
            <div
              css={{
                height,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingIndicatorComponent />
            </div>
          )) || (
            <InnerTableElementContext.Provider
              value={
                <>
                  {showHeader && (
                    <MeasuredDiv
                      height
                      onChange={this.onHeaderMeasuredChange}
                      css={{
                        paddingLeft: margins.marginLeft,
                        paddingRight: margins.marginRight,
                        position: "sticky",
                        top: 0,
                        width: "100%",
                        background: "var(--background-color)",
                      }}
                    >
                      <div
                        css={{
                          display: "flex",
                          background: "var(--background-color)",
                          borderBottom: "1px solid var(--separator-color)",
                          whiteSpace: "nowrap",
                          ">*": {
                            paddingTop,
                            paddingBottom: theme.titleBorderSpacing,
                          },
                        }}
                      >
                        {columns.map((property, index) => (
                          <div key={property}>
                            <div
                              css={{
                                width: columnWidths[property],
                                boxSizing: "border-box",
                                paddingLeft:
                                  index === 0 ? contentPaddingLeft : undefined,
                                paddingRight:
                                  index < columns.length - 1
                                    ? cellSpacing
                                    : contentPaddingRight,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {(headerCellRenderer &&
                                headerCellRenderer({
                                  property,
                                })) ||
                                (HeaderCellComponent && (
                                  <HeaderCellComponent property={property} />
                                )) ||
                                null}
                            </div>
                          </div>
                        ))}
                      </div>
                    </MeasuredDiv>
                  )}
                  {realItemCount === 0 && (
                    <div
                      css={{
                        ...margins,
                        ...noteCss,
                        width: "100%",
                        boxSizing: "border-box",
                        paddingTop:
                          Math.floor(theme.spacing / 2) +
                          topPaddingItemHeight -
                          ((showHeader && headerHeight) || 0),
                        paddingBottom: Math.floor(theme.spacing / 2),
                        paddingRight: contentPaddingRight,
                        paddingLeft: contentPaddingLeft,
                      }}
                    >
                      {noItemsLabel}
                    </div>
                  )}
                  {showFooter && (
                    <>
                      {!!realItemCount && (
                        <div
                          css={{
                            width: 0,
                            height:
                              (contentHeight || 0) -
                              ((showHeader && headerHeight) || 0) -
                              ((showFooter && footerHeight) || 0),
                            background: "var(--background-color)",
                          }}
                        />
                      )}
                      <MeasuredDiv
                        height
                        css={{
                          ...margins,
                          position: "sticky",
                          bottom: 0,
                          width: "100%",
                        }}
                        onChange={this.onFooterMeasuredChange}
                      >
                        <div
                          css={{
                            paddingTop: Math.floor(theme.spacing / 2),
                            paddingRight: contentPaddingRight,
                            paddingBottom,
                            paddingLeft: contentPaddingLeft,
                            background: "var(--background-color)",
                          }}
                        >
                          {footer}
                        </div>
                      </MeasuredDiv>
                    </>
                  )}
                </>
              }
            >
              <Grid
                ref={this.setGrid}
                css={{ outline: 0 }}
                width={width}
                height={
                  height !== undefined
                    ? height
                    : Math.min(contentHeight, maxHeight)
                }
                rowCount={this.getItemCount()}
                rowHeight={this.getRowHeight}
                columnCount={columnCount}
                columnWidth={this.getColumnWidth}
                onItemsRendered={onRowsRendered}
                innerElementType={InnerTableElement}
              >
                {this.renderCell}
              </Grid>
            </InnerTableElementContext.Provider>
          )}
        </div>
      </div>
    );
  }
}

export default withTheme(Table);
