import * as React from "react";
import Svg from "../Svg.tsx";

export default function HeartIcon({
  color = "var(--text-color)",
  solid,
  ...otherProps
}: {
  color?: string;
  solid?: boolean;
} & Omit<
  React.ComponentPropsWithoutRef<typeof Svg>,
  "viewBox"
>): React.ReactElement {
  return (
    <Svg
      viewBox="0 0 513 449"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      {...otherProps}
    >
      <path
        d={
          solid
            ? "M462.312 30.633c-54.8-46.7-136.3-38.3-186.6 13.6l-19.7 20.3-19.7-20.3c-50.2-51.9-131.8-60.3-186.6-13.6-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
            : "M458.4 32.322c-57.8-48.6-147.1-41.3-202.4 15-55.3-56.3-144.6-63.7-202.4-15-75.2 63.3-64.2 166.5-10.6 221.2l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8l175.4-178.7c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5l-175.4 178.7c-2.4 2.4-4.4 2.4-6.8 0l-175.4-178.7c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"
        }
        fill={color}
      />
    </Svg>
  );
}
