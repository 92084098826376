/**
 * @generated SignedSource<<eb35f8af66e7f861d2988062b35fb10c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModelImagecards_model$data = {
  readonly id: string;
  readonly images: ReadonlyArray<{
    readonly id: string;
  }> | null | undefined;
  readonly publicName: string | null | undefined;
  readonly statuses: ReadonlyArray<string> | null | undefined;
  readonly " $fragmentType": "ModelImagecards_model";
};
export type ModelImagecards_model$key = {
  readonly " $data"?: ModelImagecards_model$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModelImagecards_model">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModelImagecards_model",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statuses",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModelImage",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Model",
  "abstractKey": null
};
})();

(node as any).hash = "2b1a15ffb1c6c70cb270e1512cb240f5";

export default node;
