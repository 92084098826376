import * as React from "react";
import { Subtract } from "utility-types";
import Form from "./Form.tsx";

const FormContext = React.createContext<typeof Form | undefined>(undefined);

export const useForm = () => React.useContext<typeof Form>(FormContext);

type InjectedProps = { form: Form };

export function withForm<P extends InjectedProps>(
  Component: React.ComponentType<P>
): React.FC<Subtract<P, InjectedProps>> {
  return (props: Subtract<P, InjectedProps>) => {
    const form = useForm();
    return <Component form={form} {...(props as P)} />;
  };
}

export default FormContext;
