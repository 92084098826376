import * as React from "react";
import Field from "./Field.tsx";
import TabBar from "../TabBar";

const changeValueGetter = (value) => value;

export default function TabBarField({
  options,
  labelRenderer,
  ...otherProps
}: React.ComponentPropsWithRef<typeof Field> &
  React.ComponentPropsWithoutRef<typeof TabBar>): React.ReactElement {
  return (
    <Field
      {...otherProps}
      changeValueGetter={changeValueGetter}
      renderInput={(inputProps) => (
        <TabBar
          options={options}
          labelRenderer={labelRenderer}
          {...inputProps}
        />
      )}
    />
  );
}
