/**
 * @generated SignedSource<<73c5f9cc2f02f345bd20cfe07cf60624>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModelBox_model$data = {
  readonly eyeColor: string | null | undefined;
  readonly hairColor: string | null | undefined;
  readonly height: number | null | undefined;
  readonly id: string;
  readonly images: ReadonlyArray<{
    readonly id: string;
  }> | null | undefined;
  readonly location: string | null | undefined;
  readonly maxSize: number | null | undefined;
  readonly minSize: number | null | undefined;
  readonly publicName: string | null | undefined;
  readonly shoeSize: number | null | undefined;
  readonly size: number | null | undefined;
  readonly " $fragmentType": "ModelBox_model";
};
export type ModelBox_model$key = {
  readonly " $data"?: ModelBox_model$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModelBox_model">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModelBox_model",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eyeColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shoeSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hairColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 2
        }
      ],
      "concreteType": "ModelImage",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": "images(limit:2)"
    }
  ],
  "type": "Model",
  "abstractKey": null
};
})();

(node as any).hash = "2ab260956d780d3f4f30649df8bd69fd";

export default node;
