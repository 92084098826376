import color from "color";
import { Theme } from "@emotion/react";
import defaultTheme from "./defaultTheme";
import colorToHex from "./colorToHex";

export default function createTheme(): Theme {
  const theme = defaultTheme.light;
  const spacing = 18;
  const padding = spacing;

  return {
    ...theme,
    size: 1,
    spacing,
    paddingTop: padding,
    paddingRight: padding,
    paddingBottom: padding,
    paddingLeft: padding,
    maxWidth: 1100,
    fontFamily: `-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif`,
    noteColor: colorToHex(
      color(theme.textColor).mix(color(theme.backgroundColor), 0.5)
    ),
    fontSize: 16,
    smallFontSize: 14,
    lineHeight: 1.5,
    roundCornerBorderRadius: 4,
    iconSize: 16,
    weakSeparatorColor: colorToHex(
      color(theme.separatorColor).mix(color(theme.backgroundColor), 0.4)
    ),
    weakestSeparatorColor: colorToHex(
      color(theme.separatorColor).mix(color(theme.backgroundColor), 0.7)
    ),
    titleBorderSpacing: Math.floor(spacing / 3),
  };
}
