import * as React from "react";
import config from "../config";

const LocaleContext = React.createContext(config.defaultLocale);

export const useLocale = () => React.useContext<string>(LocaleContext);

export function withLocale(Component) {
  return (props) => {
    const locale = useLocale();
    return <Component locale={locale} {...props} />;
  };
}

export default LocaleContext;
