import * as React from "react";
import Button from "../../common/Button.tsx";
import FacebookIcon from "../../common/icons/FacebookIcon";
import InstagramIcon from "../../common/icons/InstagramIcon";
import PinterestIcon from "../../common/icons/PinterestIcon";
import TwitterIcon from "../../common/icons/TwitterIcon";
import Link from "../../common/Link";

export default function Footer({
  facebookUrl,
  instagramUrl,
  ...otherProps
}: React.HTMLAttributes<HTMLDivElement> & {
  facebookUrl?: string;
  instagramUrl?: string;
}): React.ReactElement {
  return (
    <div
      css={{
        borderTop: "1px solid var(--separator-color)",
      }}
      {...otherProps}
    >
      <div
        css={{
          maxWidth: "calc(var(--max-width) + (4 * var(--spacing)))",
          marginLeft: "auto",
          marginRight: "auto",
          paddingBottom: "calc(2 * var(--spacing))",
          display: "flex",
          flexWrap: "wrap",
          ">*": {
            flex: 1,
            marginTop: "calc(2 * var(--spacing))",
            marginRight: "calc(2 * var(--spacing))",
            marginLeft: "calc(2 * var(--spacing))",
            ">*": {
              display: "block",
              whiteSpace: "nowrap",
              marginBottom: "var(--spacing)",
              ":last-child": { marginBottom: 0 },
            },
          },
        }}
      >
        <div>
          <Link type="discrete" to="/about-us">
            About us
          </Link>
          <Link type="discrete" to="/tider-for-fotografering">
            Tider för fotografering
          </Link>
          <Link type="discrete" to="/contact-us">
            Contact us
          </Link>
          <Link type="discrete" to="/become-a-model">
            Become a model
          </Link>
          <Link type="discrete" to="/updates">
            Updates
          </Link>
        </div>
        <div>
          <Link type="discrete" to="/personuppgifter">
            Personuppgifter
          </Link>
          <Link type="discrete" to="/info-kids">
            Info Kids
          </Link>
          <Link type="discrete" to="/terms-and-conditions">
            Terms and Conditions
          </Link>
        </div>
        <div>
          <div
            css={{
              "> *:not(:last-child)": {
                marginRight: "var(--spacing)",
              },
            }}
          >
            {facebookUrl && (
              <Button
                type="discrete"
                href={facebookUrl}
                iconComponent={FacebookIcon}
                target="_blank"
              />
            )}
            <Button
              type="discrete"
              href="http://stockholmsgruppen.leomira.com:7140/personuppgifter/#"
              iconComponent={PinterestIcon}
              target="_blank"
            />
            <Button
              type="discrete"
              href="https://twitter.com/SthlmgModels"
              iconComponent={TwitterIcon}
              target="_blank"
            />
            {instagramUrl && (
              <Button
                type="discrete"
                href={instagramUrl}
                iconComponent={InstagramIcon}
                target="_blank"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
