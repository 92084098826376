import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { createLocation } from "history";
import composeRefs from "@seznam/compose-react-refs";

export default React.forwardRef(
  (
    {
      children,
      type,
      to,
      href,
      target,
      onClick,
      onMouseDown,
      ...otherProps
    }: {
      children: React.ReactNode;
      type?: string;
      to?: string;
      href?: string;
      target?: string;
      onMouseDown?: boolean;
      onClick?: () => void;
    },
    ref
  ) => {
    const innerRef = React.useRef<HTMLAnchorElement>(null);
    const history = useHistory();
    const location = useLocation();
    const toLocation = to && createLocation(to, undefined, undefined, location);

    // Since react seems to trigger onMouseDown on an element that appears under the mouse even if that element wasn't there when the press started
    React.useEffect(() => {
      const actualRef = innerRef.current;
      if (actualRef && (to || onClick)) {
        const doAction = async (event: MouseEvent | TouchEvent) => {
          event.preventDefault();
          if (onClick) onClick();
          if (toLocation) history.push(toLocation);
        };

        let clickHandler;
        if (onMouseDown) {
          actualRef.addEventListener("touchstart", doAction, {
            passive: false,
          });
          actualRef.addEventListener("mousedown", doAction);
          clickHandler = (event: React.SyntheticEvent) =>
            event.preventDefault();
        } else clickHandler = doAction;
        actualRef.addEventListener("click", clickHandler);

        return () => {
          actualRef.removeEventListener("touchstart", doAction);
          actualRef.removeEventListener("mousedown", doAction);
          actualRef.removeEventListener("click", clickHandler);
        };
      }
    }, [to, onClick, onMouseDown, innerRef.current]);

    return (
      <a
        ref={composeRefs(ref, innerRef)}
        target={target}
        rel={target === "_blank" ? "noreferrer noopener" : undefined}
        css={{
          color:
            type === "discrete" ? "var(--note-color)" : "var(--main-color)",
          textDecoration: "none",
          cursor: "pointer",
        }}
        href={(toLocation && history.createHref(toLocation)) || href}
        {...otherProps}
      >
        {children}
      </a>
    );
  }
);
