import * as React from "react";
import { Subtract } from "utility-types";

const UserAgentContext = React.createContext<string>();

export const useUserAgent = () => React.useContext<string>(UserAgentContext);

type InjectedProps = { userAgent: string };

export function withUserAgent<P extends InjectedProps>(
  Component: React.ComponentType<P>
): React.FC<Subtract<P, InjectedProps>> {
  return React.forwardRef((props: Subtract<P, InjectedProps>, ref) => {
    const userAgent = useUserAgent();
    return <Component ref={ref} userAgent={userAgent} {...(props as P)} />;
  });
}

export default UserAgentContext;
