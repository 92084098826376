export default function shallowEqual(
  objA: any,
  objB: any,
  options?: { ignoreKeys?: string[] }
): boolean {
  if (objA === objB) return true;

  if (typeof objA !== "object" || !objA || typeof objB !== "object" || !objB)
    return false;

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  const bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB);

  for (let idx = 0; idx < keysA.length; idx += 1) {
    const key = keysA[idx];
    if (options && options.ignoreKeys && options.ignoreKeys.includes(key))
      continue;
    if (!bHasOwnProperty(key) || objA[key] !== objB[key]) return false;
  }

  return true;
}
