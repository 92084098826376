import * as React from "react";
import Svg from "../Svg.tsx";

export default function MenuIcon({
  color = "var(--text-color)",
  ...otherProps
}: {
  color?: string;
} & Omit<
  React.ComponentPropsWithoutRef<typeof Svg>,
  "viewBox"
>): React.ReactElement {
  return (
    <Svg viewBox="0 0 310 259.344" {...otherProps}>
      <path
        d="M29.684 229.663H280.33m-250.646-100H280.33m-250.646-100H280.33"
        stroke={color}
        strokeWidth="49.336"
        strokeLinecap="round"
      />
    </Svg>
  );
}
