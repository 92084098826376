import * as React from "react";

export type ModelListContextValue = {
  id?: string;
  modelIds?: string[];
  addModel: (id: string) => void;
  removeModel: (id: string) => void;
  load: (id: string) => Promise<void>;
  createNew: () => Promise<void>;
};

const ModelListContext = React.createContext<ModelListContextValue>();

export default ModelListContext;

export const useModelList = () =>
  React.useContext<ModelListContextValue | undefined>(ModelListContext);
