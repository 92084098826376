import * as React from "react";
import Button from "./Button.tsx";

export default function TabBar({
  options,
  value,
  onChange,
  labelRenderer = (option: any) => option,
  onMouseDown,
  ...otherProps
}: {
  options: any[];
  value?: any;
  onChange?: (value: any) => void;
  labelRenderer?: (option: any) => React.ReactNode;
  onMouseDown?: true;
}): React.ReactElement {
  return (
    <div {...otherProps}>
      <div
        css={{
          marginLeft: "calc(-1 * var(--spacing))",
          lineHeight: 1,
          ">*": {
            marginLeft: "var(--spacing)",
          },
        }}
      >
        {options.map((option) => (
          <Button
            key={option || ""}
            type="tab"
            active={option === value}
            onClick={() => {
              if (onChange) onChange(option);
            }}
            onMouseDown={onMouseDown}
          >
            {labelRenderer ? labelRenderer(option) : option}
          </Button>
        ))}
      </div>
    </div>
  );
}
