import * as React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import authenticateWithServer from "../common/authenticateWithServer";
import Button from "../common/Button.tsx";
import { paddedCss } from "../common/css";
import Form from "../common/forms/Form.tsx";
import TextField from "../common/forms/TextField.tsx";
import { useSecurity } from "../common/SecurityContext";

export default function LogIn(): React.ReactElement {
  const security = useSecurity();
  const history = useHistory();

  return (
    <>
      <Helmet title="Log in" />
      <div
        css={{
          ...paddedCss,
          width: "100%",
          maxWidth: 300,
          margin: "auto",
        }}
      >
        <Form
          submit={async ({ values }) => {
            security.setAuth(await authenticateWithServer(values));
            history.push("/manager");
          }}
        >
          {({ submit }) => (
            <div
              css={{
                "> *:not(:last-child)": {
                  marginBottom: "var(--spacing)",
                },
              }}
            >
              <TextField label="Username" name="username" required />
              <TextField
                label="Password"
                name="password"
                type="password"
                required
              />
              <Button onClick={submit} css={{ display: "block" }}>
                Log in
              </Button>
            </div>
          )}
        </Form>
      </div>
    </>
  );
}
