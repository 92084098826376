import AuthorizationError from "./AuthorizationError";
import AuthenticationError from "./AuthenticationError";
import ConnectionFailedError from "./ConnectionFailedError";
import ExtendableError from "./ExtendableError";

export default async function errorMappingFetch(
  url: RequestInfo,
  request?: RequestInit
): Promise<ReturnType<typeof fetch>> {
  let response;

  try {
    response = await fetch(url, request);
  } catch (error) {
    throw new ConnectionFailedError({ cause: error });
  }

  if (response.ok) return response;

  let userFriendlyMessage;
  try {
    userFriendlyMessage = await response.text();
  } catch (error) {}

  if (response.status === 401)
    throw new AuthenticationError({
      userFriendlyMessage: userFriendlyMessage || "Invalid username/password",
    });
  if (response.status === 403)
    throw new AuthorizationError({
      userFriendlyMessage:
        userFriendlyMessage || "You are not authorized to do this",
    });
  throw new ExtendableError({ message: userFriendlyMessage });
}
