import * as React from "react";
import { graphql } from "relay-runtime";
import { getModelDetails_model } from "./__generated__/getModelDetails_model.graphql";

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment getModelDetails_model on Model {
    minSize
    maxSize
    size
    eyeColor
    shoeSize
    height
    hairColor
    location
  }
`;

export default function getModelDetails(
  model: getModelDetails_model,
): { name: string; value: any }[] {
  const actualMinSize =
    model.minSize === null || model.minSize === undefined
      ? model.size
      : model.minSize;
  const actualMaxSize =
    model.maxSize === null || model.maxSize === undefined
      ? model.size
      : model.maxSize;

  return [
    model.height && {
      name: "Height",
      value: `${model.height} cm`,
    },
    actualMinSize !== null &&
      actualMinSize !== undefined &&
      actualMaxSize !== null &&
      actualMaxSize !== undefined && {
        name: "Size",
        value:
          actualMinSize === actualMaxSize ? (
            actualMinSize
          ) : (
            <>
              {actualMinSize}-{actualMaxSize}
            </>
          ),
      },
    model.shoeSize && {
      name: "Shoes",
      value: model.shoeSize,
    },
    model.hairColor && {
      name: "Hair",
      value: model.hairColor,
    },
    model.eyeColor && {
      name: "Eyes",
      value: model.eyeColor,
    },
    model.location && {
      name: "Location",
      value: model.location,
    },
  ].filter((d) => d);
}
