import * as React from "react";
import { Subtract } from "utility-types";

const BrowserIsNotSupportedContext = React.createContext<boolean>(false);

export const useBrowserIsNotSupported = () =>
  React.useContext(BrowserIsNotSupportedContext);

type InjectedProps = { browserIsNotSupported: boolean };

export function withBrowserIsNotSupported<P extends InjectedProps>(
  Component: React.ComponentType<P>
): React.FC<Subtract<P, InjectedProps>> {
  return (props: Subtract<P, InjectedProps>) => {
    const browserIsNotSupported = useBrowserIsNotSupported();
    return (
      <Component
        browserIsNotSupported={browserIsNotSupported}
        {...(props as P)}
      />
    );
  };
}

export default BrowserIsNotSupportedContext;
