export default function getSectionTitleFromStatuses(
  statuses: string[],
): string {
  return (
    (statuses.includes("Babies") && "BABIES") ||
    (statuses.includes("Child") && "KIDS") ||
    (statuses.includes("Commercial") && "COMMERCIAL") ||
    "MODELS"
  );
}
