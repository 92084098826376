import * as React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { h2Css, noteCss, paddedCss, strongCss } from "../../../common/css";
import Logo from "../../../common/Logo";
import getModelDetails from "../../Models/common/getModelDetails";
import getSectionTitleFromStatuses from "../getSectionTitleFromStatuses";

export default function ModelSetcard({
  model: modelProp,
}: {
  model: any;
}): React.ReactNode {
  const model = useFragment(
    graphql`
      fragment ModelSetcard_model on Model {
        ...getModelDetails_model @relay(mask: false)
        publicName
        statuses
        setcardImages: images(limit: 2) {
          url
        }
      }
    `,
    modelProp
  );

  const topDetails = ["Eyes", "Shoes", "Size", "Height", "Hair"];
  const details = getModelDetails(model);
  const detailRows = [
    details.filter((detail) => topDetails.includes(detail.name)),
    details.filter((detail) => !topDetails.includes(detail.name)),
  ].filter((row) => row);
  const images = model.setcardImages;

  return (
    <div
      id="setcard"
      css={{
        ...paddedCss,
        boxSizing: "border-box",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        ">*": {
          display: "flex",
          width: "100%",
          ">*": {
            width: "50%",
          },
          ":not(:last-child)": { marginBottom: "var(--spacing)" },
        },
      }}
    >
      <div>
        <div css={{ display: "flex" }}>
          <div
            css={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "grid",
              gridTemplateColumns: "1fr",
              ">*": {
                gridRowStart: "1",
                gridColumnStart: "1",
              },
            }}
          >
            <Logo height={40} />
            <div
              css={{
                ...noteCss,
                fontWeight: "500",
                marginTop: 24,
                marginLeft: 139,
              }}
            >
              {getSectionTitleFromStatuses(model.statuses)}
            </div>
          </div>
        </div>
        <div />
      </div>

      <div
        css={{
          flex: 1,
          ">*": {
            height: "100%",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          },
        }}
      >
        <div css={{ backgroundImage: `url(${images[0].url})` }} />
        <div css={{ backgroundImage: `url(${images[1].url})` }} />
      </div>

      <div css={{ textTransform: "uppercase" }}>
        <div css={{ marginTop: "auto" }}>
          <div
            css={{
              ...h2Css,
              textAlign: "center",
              flexShrink: 0,
              lineHeight: 1,
            }}
          >
            {model.publicName}
          </div>
          <div
            css={{
              ">*": {
                fontSize: 12,
                display: "flex",
                justifyContent: "center",
                ">*:not(:last-child)": {
                  whiteSpace: "nowrap",
                  marginRight: "var(--spacing)",
                },
              },
            }}
          >
            {detailRows.map((row) => (
              <div key={JSON.stringify(row)}>
                {row.map((detail) => (
                  <div>
                    <span css={strongCss}>{detail.name}:</span> {detail.value}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>

        <div
          css={{
            fontSize: 12,
            textAlign: "center",
            fontSize: "var(--small-font-size)",
            marginTop: "auto",
          }}
        >
          Cityclub, Birger jarlsgatan 20, 114 34 Stockholm, Sweden
          <br />
          Tel: +46 8 5100 1000, stockholmsgruppen.com
        </div>
      </div>
    </div>
  );
}
