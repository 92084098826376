import * as React from "react";
import {
  commitMutation,
  Environment,
  GraphQLTaggedNode,
  useRelayEnvironment,
} from "react-relay";
import omit from "object.omit";
import { ValueSource, Value } from "./Field.tsx";

import errorLogger from "../ErrorLogger";
import {
  NotificationsContextValue,
  AddNotificationArguments,
  useNotifications,
} from "../NotificationsContext";
import FormContext from "./FormContext";

type ChildrenFunction = (props: {
  submit: () => void;
  submitting?: boolean;
  submitted?: boolean;
  values: { [key: string]: Value };
  readOnly?: boolean;
  modifiedValues?: { [key: string]: boolean };
  validate?: () => boolean;
}) => any;

type MutationVariables = { [key: string]: any };
type MutationUplodables = { [key: string]: any };

type Props = {
  children: React.ReactElement | ChildrenFunction;
  mutation?: GraphQLTaggedNode;
  mutationVariables?:
    | MutationVariables
    | ((options: { [key: string]: Value }) => MutationVariables);
  mutationUploadables?:
    | MutationUplodables
    | ((options: { [key: string]: Value }) => MutationUplodables);
  mutationConfigs?: {}[];
  readOnly?: boolean;
  onSubmit?: () => void;
  onSubmitted?: ({ values: {}, responseData: {} }) => void | Promise<void>;
  notification?: AddNotificationArguments;
  onChange?: (options: {
    values: {};
    validationErrorMessages: { [key: string]: string[] };
  }) => void;
  relayEnvironment: Environment;
  submit?: (options: { values: {}; cancel: () => void }) => void | Promise<any>;
  resetOnSubmitted?: boolean;
};

type State = {
  values: { [key: string]: Value };
  modifiedValues?: { [key: string]: boolean };
  submitting?: boolean;
  submitted?: boolean;
  errorNotifications?: { error: any }[];
};

class FormImpl extends React.Component<
  Props & {
    notifications: NotificationsContextValue;
  },
  State
> {
  static defaultProps = {
    readOnly: false,
    resetOnSubmitted: true,
  };

  state: State = {
    values: {},
  };

  unmounted?: boolean;

  fields: Field[] = [];

  fieldValidationErrorMessages: Map<Field, string[] | null> = new Map();

  showValidationErrorMessages?: Map<Field, string[]>;

  componentWillUnmount() {
    this.unmounted = true;
    const { errorNotifications } = this.state;
    if (errorNotifications)
      this.props.notifications.dismissNotifications(errorNotifications);
  }

  onFieldChange({
    field,
    name,
    value,
    validationErrorMessages,
    valueSource,
    updateValue = true,
    unmounting,
  }: {
    field: Field;
    name?: string | string[];
    value?: Value;
    validationErrorMessages?: string[];
    valueSource?: string;
    unmounting?: boolean;
    updateValue: boolean;
  }) {
    if (unmounting) this.fieldValidationErrorMessages.delete(field);
    else this.fieldValidationErrorMessages.set(field, validationErrorMessages);

    if (updateValue && name !== undefined) {
      this.setState(
        (state) => {
          const newState = { values: { ...state.values } };
          if (Array.isArray(name))
            name.forEach((n, index) => {
              if (unmounting) delete newState.values[n];
              else newState.values[n] = value ? value[index] : value;
            });
          else if (unmounting) delete newState.values[name];
          else newState.values[name] = value;

          if (unmounting) {
            if (state.modifiedValues) {
              newState.modifiedValues = omit(state.modifiedValues, name);
              if (Object.keys(newState.modifiedValues).length === 0)
                newState.modifiedValues = undefined;
            }
          } else if (
            valueSource !== ValueSource.DEFAULT_PROP &&
            valueSource !== ValueSource.PROP
          ) {
            newState.modifiedValues = { ...state.modifiedValues };
            if (Array.isArray(name))
              name.forEach((n) => {
                newState.modifiedValues[n] = true;
              });
            else newState.modifiedValues[name] = true;
          }

          return newState;
        },
        () => {
          if (
            this.props.onChange &&
            valueSource !== ValueSource.DEFAULT_PROP &&
            valueSource !== ValueSource.PROP
          ) {
            this.props.onChange({
              values: this.state.values,
              validationErrorMessages: this.fieldValidationErrorMessages,
            });
          }
        }
      );
    }
  }

  getShowValidationErrorMessages(field: Field) {
    return this.showValidationErrorMessages?.get(field);
  }

  getFields() {
    return this.fields;
  }

  validate = () => {
    this.showValidationErrorMessages = new Map(
      this.fieldValidationErrorMessages
    );
    this.fields.forEach((field) => field.forceUpdate());

    // Scroll to first field with validation errors
    for (let { length } = this.fields, i = 0; i < length; i += 1) {
      const field = this.fields[i];
      if (this.fieldValidationErrorMessages.get(field)) {
        field.scrollIntoView();
        return false;
      }
    }
    return true;
  };

  submit = async () => {
    const {
      relayEnvironment,
      mutation,
      mutationVariables,
      mutationUploadables,
      mutationConfigs,
      onSubmit,
      onSubmitted,
      notification,
      submit,
      resetOnSubmitted,
      notifications: { addNotification },
    } = this.props;
    const { values } = this.state;

    if (onSubmit) onSubmit();

    if (!this.validate()) return;

    let promise;
    let cancel = false;

    if (submit) {
      promise = new Promise((resolve, reject) => {
        try {
          resolve(
            submit({
              values,
              cancel: () => {
                cancel = true;
              },
            })
          );
        } catch (error) {
          reject(error);
        }
      });
    } else if (mutation) {
      const variables =
        typeof mutationVariables === "function"
          ? mutationVariables(values)
          : mutationVariables || { input: values };

      const uploadables =
        typeof mutationUploadables === "function"
          ? mutationUploadables(values)
          : mutationUploadables;

      promise = new Promise((resolve, reject) => {
        commitMutation(relayEnvironment, {
          mutation,
          variables,
          uploadables,
          configs: mutationConfigs,
          onCompleted: (response, errors) => {
            if (errors) reject(errors);
            else resolve(response);
          },
          onError: reject,
        });
      });
    } else throw new Error("Neither submit nor mutation is set.");

    if (this.state.errorNotifications)
      this.props.notifications.dismissNotifications(
        this.state.errorNotifications
      );
    this.setState({ submitting: true, errorNotifications: undefined });

    try {
      const responseData = await promise;

      if (cancel) return undefined;
      if (notification) addNotification(notification);
      if (onSubmitted) await onSubmitted({ values, responseData });

      if (!this.unmounted) {
        this.fields.forEach((field) => field.forceUpdate());

        this.setState({
          submitting: false,
          submitted: true,
          modifiedValues: resetOnSubmitted
            ? undefined
            : this.state.modifiedValues,
        });

        if (resetOnSubmitted) {
          this.fields.forEach((field) => field.reset());
        }
      }

      return responseData;
    } catch (errorOrErrors) {
      if (!this.unmounted) {
        const errors = (
          Array.isArray(errorOrErrors) ? errorOrErrors : [errorOrErrors]
        ).map((error: any) => errorLogger.log(error));

        const errorNotifications: { error: any }[] = [];

        errors.forEach((error: any) =>
          errorNotifications.push(addNotification({ error }))
        );

        this.setState({
          submitting: false,
          errorNotifications,
        });
      }
    }
    return undefined;
  };

  isReadOnly = () => this.props.readOnly;

  fieldWillUnmount(field: Field) {
    const { name, updateFormValue } = field.props;

    this.onFieldChange({
      field,
      name,
      updateValue: !!updateFormValue,
      unmounting: true,
    });

    const index = this.fields.indexOf(field);
    if (index !== -1) this.fields.splice(index, 1);
  }

  fieldDidMount(field: Field) {
    this.fields.push(field);
  }

  render() {
    const { children, readOnly } = this.props;
    const { submitting, submitted, values, modifiedValues } = this.state;

    return (
      <FormContext.Provider key={readOnly ? "1" : "0"} value={this}>
        {typeof children === "function"
          ? children({
              submit: this.submit,
              submitting,
              submitted,
              values,
              readOnly,
              modifiedValues,
              validate: this.validate,
            })
          : children}
      </FormContext.Provider>
    );
  }
}

export default function Form(
  props: Omit<
    Omit<React.ComponentPropsWithoutRef<typeof FormImpl>, "relayEnvironment">,
    "notifications"
  >
): React.ReactElement {
  const relayEnvironment = useRelayEnvironment();
  const notifications = useNotifications();
  return (
    <FormImpl
      relayEnvironment={relayEnvironment}
      notifications={notifications}
      {...props}
    />
  );
}
