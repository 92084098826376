import * as React from "react";
import { useTheme } from "@emotion/react";
import { h3Css } from "./css";
import { SafeAreaInsets } from "./safeAreaInsets";

export default function PaddedHoverContent({
  title,
  children,
  fullscreen,
  safeAreaInsets,
  className,
}: {
  title?: string;
  fullscreen?: boolean;
  children: React.ReactNode;
  safeAreaInsets: SafeAreaInsets;
  className?: string;
}): React.ReactElement {
  const theme = useTheme();

  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        display: "flex",
        overflow: "auto",
      }}
    >
      <div
        css={{
          paddingTop: theme.paddingTop + safeAreaInsets.top,
          paddingRight: theme.paddingRight + safeAreaInsets.right,
          paddingBottom: theme.paddingBottom + safeAreaInsets.bottom,
          paddingLeft: theme.paddingLeft + safeAreaInsets.left,
          boxSizing: "border-box",
          margin: fullscreen ? "auto" : undefined,
        }}
        className={className}
      >
        {title && fullscreen && (
          <div
            css={{
              ...h3Css,
              marginBottom: theme.spacing,
              textAlign: "center",
            }}
          >
            {title}
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
