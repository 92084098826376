import * as React from "react";
import Dropzone from "react-dropzone";
import { useTheme } from "@emotion/react";
import Field from "./Field.tsx";
import { roundedBorderedCss } from "../css";
import CloseIcon from "../icons/CloseIcon";
import Image from "../Image";

export default function FileField({
  multiple = false,
  children,
  ...otherProps
}: { showClearButton?: boolean } & React.ComponentPropsWithoutRef<
  typeof Field
>): React.ReactElement {
  const { showClearButton, readOnly } = otherProps;
  const theme = useTheme();

  return (
    <Field
      {...otherProps}
      renderInput={({ value, onChange, validationErrorMessages }) => (
        <Dropzone
          onDrop={(files) => onChange(files.length === 1 ? files[0] : files)}
          multiple={multiple}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              css={{
                display: "flex",
                alignItems: "center",
                height: "calc(12px + var(--line-height) * var(--font-size))",
                paddingLeft: 8,
                paddingRight: 8,
                background: "var(--background-color)",
                ...roundedBorderedCss({
                  color:
                    (validationErrorMessages && "var(--error-color)") ||
                    undefined,
                }),
                borderWidth: 1,
                boxSizing: "border-box",
                cursor: readOnly ? "default" : "pointer",
              }}
            >
              <div
                css={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
                {...getRootProps()}
              >
                {(value &&
                  (value.contentType || value.type)?.startsWith("image") && (
                    <Image
                      css={{
                        ...roundedBorderedCss({
                          radius: 2,
                          color: "var(--weak-separator-color)",
                        }),
                      }}
                      src={value}
                      height={theme.fontSize}
                    />
                  )) ||
                  (Array.isArray(value) &&
                    value.length > 1 &&
                    `${value.length} files`) ||
                  value?.name}
                <input {...getInputProps()} />
              </div>
              {!readOnly && showClearButton && value && (
                <div
                  css={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    paddingRight: 10,
                    ">*": { display: "block" },
                  }}
                  onClick={() => onChange(null)}
                >
                  {showClearButton && value && (
                    <CloseIcon
                      height={theme.iconSize}
                      color="var(--note-color)"
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </Dropzone>
      )}
    />
  );
}
