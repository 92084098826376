import * as React from "react";
import TextField from "./TextField.tsx";
import { emailValidators } from "../validators";

const changeValueGetter = (value: string) => value?.replace(/\s/g, "");

export default function EmailAddressField(
  props: React.ComponentPropsWithoutRef<typeof TextField>
): React.ReactElement {
  // type= should be text and not email because email does not trigger onChange correctly in browsers (gives the trimmed value instead)
  return (
    <TextField
      validators={emailValidators}
      changeValueGetter={changeValueGetter}
      type="text"
      autoComplete="email"
      {...props}
    />
  );
}
