import * as React from "react";
import loadable from "@loadable/component";
import CenteredLoadingIndicator from "../common/CenteredLoadingIndicator";
import reloadPageOnPromiseError from "../common/reloadPageOnPromiseError";

const ManagerLoadable = loadable(
  () => reloadPageOnPromiseError(import("./Manager")),
  {
    fallback: <CenteredLoadingIndicator />,
  }
);

export default function LoadableManager(props: any[]): React.ReactElement {
  return <ManagerLoadable {...props} />;
}
