import * as React from "react";
import { Subtract } from "utility-types";
import { Auth } from "./authenticateWithServer";

export type SecurityContextValue = {
  auth?: Auth;
  setAuth: (auth: Auth) => void;
  logOut: () => void;
};

const SecurityContext = React.createContext<SecurityContextValue>({
  setAuth: () => {},
  logOut: () => {},
});

export const useSecurity = () =>
  React.useContext<SecurityContextValue>(SecurityContext);

type InjectedProps = { security: SecurityContextValue };

export function withSecurity<P extends InjectedProps>(
  Component: React.ComponentType<P>
): React.FC<Subtract<P, InjectedProps>> {
  return (props: Subtract<P, InjectedProps>) => {
    const security = useSecurity();
    return <Component security={security} {...(props as P)} />;
  };
}

export default SecurityContext;
