import errorMappingFetch from "./errorMappingFetch";
import config from "../config";

export default function fetchGraphql(options) {
  const isFormData = global.FormData && options.body instanceof FormData;

  const request = {
    method: "POST",
    ...options,
    headers: {
      Accept: "application/json",
      ...options.headers,
    },
    body: isFormData ? options.body : JSON.stringify(options.body),
  };

  if (!isFormData) request.headers["Content-Type"] = "application/json";

  return errorMappingFetch(config.graphql.endpointUrl, request).then(
    async (response) => {
      const json = await response.json();
      // If there are any errors, fail the whole request. This is not ideal but there is not yet any great way to handle partial errors in relay (https://github.com/facebook/relay/issues/1913) and this is better than having requests swallow errors
      return json.errors
        ? {
            ...json,
            data: null,
            errors: json.errors.map((error: any) => ({
              ...error,
              userFriendlyMessage: error.message,
            })),
          }
        : json;
    }
  );
}
