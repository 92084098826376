import * as React from "react";
import omit from "object.omit";
import Field from "./Field.tsx";
import CloseIcon from "../icons/CloseIcon";
import Button from "../Button.tsx";
import PlusIcon from "../icons/PlusIcon";
import FileSelectButton from "../FileSelectButton.tsx";

export default function FilesField(
  props: { showClearButton?: boolean } & React.ComponentPropsWithoutRef<
    typeof Field
  >
): React.ReactElement {
  const passProps = omit(props, ["children"]);

  return (
    <Field
      {...passProps}
      renderInput={({ value, onChange }) => (
        <div
          css={{
            ">*:not(:last-child)": { marginBottom: "var(--spacing)" },
          }}
        >
          {value &&
            value.map((file) => (
              <div key={file.name} css={{ display: "flex" }}>
                <div css={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  {file.name}
                </div>
                <Button
                  iconComponent={CloseIcon}
                  type="discrete"
                  css={{
                    display: "block",
                    marginLeft: "calc(var(--spacing) / 2)",
                  }}
                  onClick={() => onChange(value.filter((f) => f !== file))}
                />
              </div>
            ))}
          <FileSelectButton
            iconComponent={PlusIcon}
            type="naked"
            multiple
            onSelect={(files) => onChange([...(value || []), ...files])}
          >
            Add files
          </FileSelectButton>
        </div>
      )}
    />
  );
}
