import * as React from "react";
import { useTheme } from "@emotion/react";
import { graphql, useFragment } from "react-relay/hooks";
import { useRouteMatch } from "react-router-dom";
import innerText from "react-innertext";
import scrollIntoView from "scroll-into-view";
import { Page_page$key } from "./__generated__/Page_page.graphql";
import MeasuredDiv from "./MeasuredDiv";
import Markdown from "./Markdown";
import Link from "./Link";
import Button from "./Button.tsx";
import { h2Css, h3Css } from "./css";

export default function Page({
  page: propsPage,
  ...otherProps
}: {
  page: Page_page$key;
}): React.ReactElement {
  const theme = useTheme();
  const match = useRouteMatch();
  const headerRefs = React.useRef<{ [name: string]: HTMLElement }>({});

  const page = useFragment(
    graphql`
      fragment Page_page on Page {
        id
        title
        content
        pages {
          title
          name
          sortIndex
        }
        files {
          id
          name
          width
          height
        }
      }
    `,
    propsPage,
  );

  const headers = page.content
    ? [...page.content?.matchAll(/(?:## )(.*)/g)].map((match) => match[1])
    : undefined;

  const heading = ({ defaultComponent, children, ...otherProps }) =>
    defaultComponent({
      children: (
        <span ref={(ref) => (headerRefs.current[innerText(children)] = ref)}>
          {children}
        </span>
      ),
      ...otherProps,
    });

  return (
    <MeasuredDiv width css={{ display: "flex" }} {...otherProps}>
      {({ width }) => {
        const showHeaders = !!headers?.length && width >= 900;

        return (
          width && (
            <>
              {showHeaders && (
                <div
                  css={{
                    "@media print": {
                      display: "none",
                    },
                    width: 200,
                    height: "100%",
                    marginRight: "calc(3 * var(--spacing))",
                    position: "sticky",
                    top: "calc(2 * var(--padding-top))",
                    ">*:not(:last-child)": {
                      marginBottom: "var(--spacing)",
                    },
                    paddingBottom: "calc(2 * var(--padding-bottom))",
                    boxSizing: "border-box",
                  }}
                >
                  {headers?.map((header) => (
                    <Link
                      type="discrete"
                      css={{ display: "block" }}
                      onClick={() =>
                        scrollIntoView(headerRefs.current[header], {
                          align: { top: 0.02 },
                        })
                      }
                    >
                      {header}
                    </Link>
                  ))}
                </div>
              )}
              <div css={{ flex: 1, overflow: "hidden" }}>
                <h1
                  css={{
                    ...h2Css,
                    textAlign: "center",
                  }}
                >
                  {page.title}
                </h1>
                <Markdown
                  components={{
                    h1: heading,
                    h2: heading,
                    h3: heading,
                    h4: heading,
                    h5: heading,
                    h6: heading,
                    img: ({ defaultComponent, ...otherProps }) => {
                      const { src } = otherProps;
                      if (src.startsWith("files://")) {
                        const name = src.substring(8);
                        const file = page.files.find(
                          (file) => file.name === name,
                        );
                        return file
                          ? defaultComponent({
                              ...otherProps,
                              src: file,
                              width: showHeaders
                                ? width - 200 - theme.spacing * 3
                                : width,
                            })
                          : null;
                      }
                      return defaultComponent(otherProps);
                    },
                  }}
                >
                  {page.content}
                </Markdown>
                {!!page.pages?.length && (
                  <div>
                    <div
                      css={{
                        ...h3Css,
                        marginTop: "calc(2 * var(--spacing))",
                      }}
                    >
                      Pages
                    </div>
                    {page.pages
                      .slice()
                      .sort((page1, page2) => page1.sortIndex - page2.sortIndex)
                      .map((p) => (
                        <div key={p.name} css={{ marginBottom: theme.spacing }}>
                          <Button
                            type="naked"
                            to={`${match.url}/${encodeURIComponent(p.name)}`}
                          >
                            {p.title}
                          </Button>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </>
          )
        );
      }}
    </MeasuredDiv>
  );
}
