let lastId = 0;

export function generateUniqueId(): string {
  const newId = lastId + 1;
  lastId = newId;
  return `${newId}`;
}

export function resetUniqueId(): void {
  lastId = 0;
}
