/**
 * @generated SignedSource<<7834a6e12186a1d7ad7eb91bb5d1d011>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModelQuery$variables = {
  id: string;
};
export type ModelQuery$data = {
  readonly model: {
    readonly publicName: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ModelHome_model" | "ModelImagecards_model" | "ModelSetcard_model">;
  } | null | undefined;
};
export type ModelQuery = {
  response: ModelQuery$data;
  variables: ModelQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ModelQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Model",
        "kind": "LinkedField",
        "name": "model",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModelHome_model"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModelSetcard_model"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModelImagecards_model"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ModelQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Model",
        "kind": "LinkedField",
        "name": "model",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minSize",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxSize",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "eyeColor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shoeSize",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hairColor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          },
          (v4/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "info",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "instagramName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ModelImage",
            "kind": "LinkedField",
            "name": "images",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contentType",
                "storageKey": null
              },
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "polaroids",
            "args": [
              {
                "kind": "Literal",
                "name": "category",
                "value": "polaroids"
              },
              {
                "kind": "Literal",
                "name": "limit",
                "value": 1
              }
            ],
            "concreteType": "ModelImage",
            "kind": "LinkedField",
            "name": "images",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": "images(category:\"polaroids\",limit:1)"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "statuses",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "ready",
                "value": true
              }
            ],
            "concreteType": "ModelVideo",
            "kind": "LinkedField",
            "name": "videos",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "playlistUrl",
                "storageKey": null
              },
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": "videos(ready:true)"
          },
          {
            "alias": "setcardImages",
            "args": [
              {
                "kind": "Literal",
                "name": "limit",
                "value": 2
              }
            ],
            "concreteType": "ModelImage",
            "kind": "LinkedField",
            "name": "images",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": "images(limit:2)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4351f96ac4ce5cf673284952190b1976",
    "id": null,
    "metadata": {},
    "name": "ModelQuery",
    "operationKind": "query",
    "text": "query ModelQuery(\n  $id: ID!\n) {\n  model(id: $id) {\n    ...ModelHome_model\n    ...ModelSetcard_model\n    ...ModelImagecards_model\n    publicName\n    id\n  }\n}\n\nfragment ModelHome_model on Model {\n  minSize\n  maxSize\n  size\n  eyeColor\n  shoeSize\n  height\n  hairColor\n  location\n  id\n  publicName\n  info\n  instagramName\n  images {\n    id\n    contentType\n    width\n    height\n  }\n  polaroids: images(category: \"polaroids\", limit: 1) {\n    id\n    url\n  }\n  statuses\n  videos(ready: true) {\n    id\n    name\n    playlistUrl\n    width\n    height\n  }\n}\n\nfragment ModelImagecards_model on Model {\n  id\n  publicName\n  statuses\n  images {\n    id\n  }\n}\n\nfragment ModelSetcard_model on Model {\n  minSize\n  maxSize\n  size\n  eyeColor\n  shoeSize\n  height\n  hairColor\n  location\n  publicName\n  statuses\n  setcardImages: images(limit: 2) {\n    url\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "08a8acee9049beaececd796c489e35fa";

export default node;
