import Cookies from "js-cookie";

const cookieName = "reloadPageOnPromiseErrorLastPath";

export default function reloadPageOnPromiseError<T>(
  promise: Promise<T>
): Promise<T> {
  if (window?.location.reload && navigator?.onLine) {
    const path = window.location.pathname;
    return promise.catch(
      (error: any) =>
        new Promise(() => {
          if (Cookies.get(cookieName) === path) throw error;
          Cookies.set(cookieName, path, { path: "" });
          window?.location.reload();
        })
    );
  }
  return promise;
}
