import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import urljoin from "url-join";
import Home from "./ModelsHome.tsx";
import Model from "../common/Model/index.tsx";
import Button from "../../common/Button.tsx";

export default function Models({
  anyOfStatus,
  statusOptions,
  hideAgeFilters,
  showLocationFilter,
  comparator,
  pageName,
}: {
  anyOfStatus?: string[];
  statusOptions?: React.ComponentPropsWithoutRef<typeof Button>[];
  hideAgeFilters?: boolean;
  showLocationFilter?: boolean;
  comparator: React.ComponentProps<typeof Home>["comparator"];
  pageName: React.ComponentProps<typeof Home>["pageName"];
}): React.ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={match.url}>
        <Home
          anyOfStatus={anyOfStatus}
          statusOptions={statusOptions}
          hideAgeFilters={hideAgeFilters}
          showLocationFilter={showLocationFilter}
          comparator={comparator}
          pageName={pageName}
        />
      </Route>
      <Route path={urljoin(match.url, ":modelId?")}>
        <Model />
      </Route>
    </Switch>
  );
}
