import * as React from "react";
import { Subtract } from "utility-types";
import { SafeAreaInsets } from "./safeAreaInsets";

const SafeAreaInsetsContext = React.createContext<SafeAreaInsets>({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

export const useSafeAreaInsets = () =>
  React.useContext<SafeAreaInsets>(SafeAreaInsetsContext);

type InjectedProps = { safeAreaInsets: SafeAreaInsets };

export function withSafeAreaInsets<P extends InjectedProps>(
  Component: React.ComponentType<P>
): React.FC<Subtract<P, InjectedProps>> {
  return (props: Subtract<P, InjectedProps>) => {
    const safeAreaInsets = useSafeAreaInsets();
    return <Component safeAreaInsets={safeAreaInsets} {...(props as P)} />;
  };
}

export default SafeAreaInsetsContext;
