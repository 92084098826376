import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, QueryRenderer, useRelayEnvironment } from "react-relay";
import { Route, Switch, useRouteMatch } from "react-router";
import urljoin from "url-join";
import CenteredLoadingIndicator from "../../../common/CenteredLoadingIndicator";
import ErrorHandler from "../../../common/ErrorHandler";
import NotFoundError from "../../../common/NotFoundError";
import ModelHome from "./ModelHome";
import ModelImagecards from "./ModelImagecards";
import ModelSetcard from "./ModelSetcard";

export default function Model(): React.ReactElement {
  const {
    url,
    params: { modelId },
  } = useRouteMatch();
  const relayEnvironment = useRelayEnvironment();

  return (
    <QueryRenderer
      environment={relayEnvironment}
      query={graphql`
        query ModelQuery($id: ID!) {
          model(id: $id) {
            ...ModelHome_model
            ...ModelSetcard_model
            ...ModelImagecards_model
            publicName
          }
        }
      `}
      variables={{ id: modelId }}
      render={({ props, error, retry }) => {
        if (error || (props && !props.model))
          return (
            <ErrorHandler error={error || new NotFoundError()} retry={retry} />
          );

        if (!props) return <CenteredLoadingIndicator />;

        const { model } = props;

        return (
          <>
            <Helmet title={model.publicName} />

            <Switch>
              <Route path={urljoin(url, "setcard")}>
                <ModelSetcard model={model} />
              </Route>
              <Route path={urljoin(url, "imagecards")}>
                <ModelImagecards model={model} />
              </Route>
              <Route>
                <ModelHome model={model} />
              </Route>
            </Switch>
          </>
        );
      }}
    />
  );
}
