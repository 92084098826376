import * as React from "react";

export default function nl2br(str: string): React.ReactNode {
  return typeof str !== "string"
    ? str
    : str.split("\n").map((item, key) => (
        <React.Fragment key={key}>
          {item}
          <br />
        </React.Fragment>
      ));
}
