import * as React from "react";
import Svg from "../Svg.tsx";

export default function SearchIcon({
  color = "var(--text-color)",
  ...otherProps
}: {
  color?: string;
} & Omit<
  React.ComponentPropsWithoutRef<typeof Svg>,
  "viewBox"
>): React.ReactElement {
  return (
    <Svg viewBox="0 0 300 300" {...otherProps}>
      <path
        fill={color}
        d="M37.939 39.086C-5.334 82.358-5.44 153.245 37.833 196.518c36.663 36.663 93.202 42.25 135.921 16.766l76.344 74.235c10.507 10.198 27.085 9.821 37.117-.844 10.033-10.665 9.77-27.446-.738-37.644l-75.183-72.864c26.361-42.847 21.008-100.045-16.028-137.081-43.273-43.273-114.055-43.273-157.327 0zm31.739 31.739c26.102-26.102 67.746-26.102 93.848 0 26.102 26.102 26.102 67.746 0 93.848-26.102 26.102-67.746 26.102-93.848 0-26.102-26.102-26.102-67.746 0-93.848z"
      />
    </Svg>
  );
}
