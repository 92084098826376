export default function getUserFriendlyErrorMessage(
  error: any
): string | undefined {
  const { userFriendlyMessage } = error;
  if (userFriendlyMessage) return userFriendlyMessage;

  if (error.name === "QuotaExceededError") return "Not enough disk space.";

  return error.source?.errors
    ? [...new Set(error.source.errors.map((e: any) => e.userFriendlyMessage))]
        .filter((message?: any) => message)
        .join("\n")
    : undefined;
}
