import { noCase } from "no-case";
import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "relay-runtime";
import Button from "../common/Button.tsx";
import { doublePaddedCss, h2Css, paddedCss, strongCss } from "../common/css";
import EmailAddressField from "../common/forms/EmailAddressField.tsx";
import FileField from "../common/forms/FileField.tsx";
import Form from "../common/forms/Form.tsx";
import NumberField from "../common/forms/NumberField.tsx";
import SelectField from "../common/forms/SelectField.tsx";
import TextField from "../common/forms/TextField.tsx";
import Gender from "../common/Gender";
import Link from "../common/Link";
import Footer from "./common/Footer";
import CheckboxField from "../common/forms/CheckboxField.tsx";
import UserFriendlyMessageError from "../common/UserFriendlyMessageError";

const modelBirthYearOptions: number[] = [];
const currentYear = new Date().getFullYear();
for (let i = 0; i < 100; i += 1) modelBirthYearOptions.push(currentYear - i);

const modelBirthMonthOptions: number[] = [];
for (let i = 0; i < 12; i += 1) modelBirthMonthOptions.push(i);

const modelBirthDayOptions: number[] = [];
for (let i = 1; i <= 31; i += 1) modelBirthDayOptions.push(i);

export default function BecomeAModel(): React.ReactElement {
  return (
    <>
      <Helmet title="Become a model" />
      <Form
        mutation={graphql`
          mutation BecomeAModelMutation($input: CreateModelApplicationInput!) {
            createModelApplication(input: $input) {
              modelApplication {
                id
              }
            }
          }
        `}
        mutationVariables={({
          image1,
          image2,
          image3,
          birthYear,
          birthMonth,
          birthDay,
          emailAddress,
          emailAddress2,
          ...values
        }) => {
          if (emailAddress !== emailAddress2)
            throw new UserFriendlyMessageError({
              message: "E-mail addresses do not match.",
            });
          return {
            input: {
              ...values,
              images: "image",
              birthDate: new Date(
                Date.UTC(birthYear, birthMonth, birthDay, 12),
              ),
              emailAddress,
            },
          };
        }}
        mutationUploadables={({ image1, image2, image3 }) => ({
          image: [image1, image2, image3].filter((i) => i),
        })}
        notification="Model application sent"
      >
        {({ submit, submitted }) => (
          <div
            css={{
              ...doublePaddedCss,
              maxWidth: "var(--max-width)",
              margin: "auto",
            }}
          >
            {submitted ? (
              "Thank you! We will get back to you as soon as possible."
            ) : (
              <div
                css={{
                  marginBottom: "calc(-2 * var(--spacing))",
                  ">*": {
                    marginBottom: "calc(2 * var(--spacing))",
                  },
                  "@media (min-width: 800px)": {
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    ">*": {
                      flex: 1,
                      minWidth: "calc(50% - 2 * var(--spacing))",
                      ":not(:last-child)": {
                        marginRight: "calc(4 * var(--spacing))",
                      },
                    },
                  },
                }}
              >
                <div>
                  <div css={h2Css}>Become a model</div>
                  <div css={strongCss}>
                    How do you apply to Stockholmsgruppen?
                  </div>
                  <br />
                  You are welcome to file your application directly here at our
                  website. Please read the information below.
                  <br />
                  <br />
                  You don’t need professional pictures, but we need them to be
                  clear and photographed recently. The images must be in a JPG
                  or PNG format. Best way to ensure that is to take pictures
                  with your mobile and then register your application using the
                  same mobile. Do not change pictures with Photoshop or other
                  programs.
                  <br />
                  <br />
                  There are some different divisions at Stockholmsgruppen.
                  <br />
                  <br />
                  <div css={strongCss}>
                    New Face, Development and Model Division:
                  </div>
                  <br />
                  For those who are, or wish to become, professional
                  international models.
                  <br />
                  <br />
                  Female criteria:
                  <br />
                  <br />
                  You need to be between 170-182 cm and age 13-21 (if you have
                  no previous experience).
                  <br />
                  <br />
                  Male criteria:
                  <br />
                  <br />
                  You need to be between 180-190 cm and age 16-25.
                  <br />
                  <br />
                  <div css={strongCss}>Commercial Division:</div>
                  <br />
                  For those who mostly do commercial assignments rather than
                  fashion and editorial. For anyone who enjoy being in front of
                  the camera. You can be of any age, size and character.
                  <br />
                  <br />
                  <div css={strongCss}>Kids Division:</div>
                  <br />
                  <Link to="/info-kids">Click here for more info</Link>
                  <br />
                  <br />
                  If you send your application by regular post, and if you want
                  us to return your pictures, please include a stamped envelope
                  with your name and address and send to:
                  <br />
                  <br />
                  Stockholmsgruppen
                  <br />
                  Mailbox 544
                  <br />
                  114 11 Stockholm
                </div>
                <div
                  css={{
                    ...paddedCss,
                    background: "var(--alternative-background-color)",
                    borderRadius: "var(--round-corner-border-radius)",
                    boxSizing: "border-box",
                    ">*:not(:last-child)": {
                      marginBottom: "var(--spacing)",
                    },
                  }}
                >
                  <SelectField
                    label="Model gender"
                    name="gender"
                    options={Object.values(Gender)}
                    labelGenerator={(option) => {
                      const label = noCase(option);
                      return label
                        ? label.charAt(0).toUpperCase() + label.substr(1)
                        : label;
                    }}
                    required
                  />

                  <div
                    css={{
                      marginLeft: "calc(-1 * var(--spacing))",
                      whiteSpace: "nowrap",
                      ">*": {
                        display: "inline-block",
                        width: "50%",
                        paddingLeft: "var(--spacing)",
                        boxSizing: "border-box",
                        verticalAlign: "top",
                      },
                    }}
                  >
                    <div>
                      <TextField
                        label="Model first name"
                        name="firstName"
                        nonWhitespaceRequired
                      />
                    </div>
                    <div>
                      <TextField
                        label="Model last name"
                        name="lastName"
                        nonWhitespaceRequired
                      />
                    </div>
                  </div>

                  <EmailAddressField
                    label="E-mail"
                    name="emailAddress"
                    required
                  />
                  <EmailAddressField
                    label="Repeat e-mail"
                    name="emailAddress2"
                    required
                  />
                  <TextField
                    label="Phone number"
                    name="phoneNumber"
                    nonWhitespaceRequired
                  />

                  <div
                    css={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "calc(-1 * var(--spacing))",
                      ">*": { flex: 1, paddingLeft: "var(--spacing)" },
                    }}
                  >
                    <div>
                      <SelectField
                        name="birthDay"
                        label="Model birth day"
                        required
                        options={modelBirthDayOptions}
                      />
                    </div>
                    <div>
                      <SelectField
                        name="birthMonth"
                        label="Model birth month"
                        required
                        options={modelBirthMonthOptions}
                        labelGenerator={(value) =>
                          new Date(2021, value, 10).toLocaleDateString(
                            "default",
                            { month: "long" },
                          )
                        }
                      />
                    </div>
                    <div>
                      <SelectField
                        name="birthYear"
                        label="Model birth year"
                        required
                        options={modelBirthYearOptions}
                      />
                    </div>
                  </div>

                  <TextField
                    label="Address"
                    name="address"
                    nonWhitespaceRequired
                  />

                  <div
                    css={{
                      marginLeft: "calc(-1 * var(--spacing))",
                      whiteSpace: "nowrap",
                      ">*": {
                        display: "inline-block",
                        width: "50%",
                        paddingLeft: "var(--spacing)",
                        boxSizing: "border-box",
                        verticalAlign: "top",
                      },
                    }}
                  >
                    <div>
                      <TextField
                        label="City"
                        name="city"
                        nonWhitespaceRequired
                      />
                    </div>
                    <div>
                      <TextField
                        label="Zip code"
                        name="zipCode"
                        nonWhitespaceRequired
                      />
                    </div>
                  </div>

                  <TextField
                    label="Country"
                    name="country"
                    nonWhitespaceRequired
                  />

                  <div
                    css={{
                      marginLeft: "calc(-1 * var(--spacing))",
                      whiteSpace: "nowrap",
                      ">*": {
                        display: "inline-block",
                        width: "50%",
                        paddingLeft: "var(--spacing)",
                        boxSizing: "border-box",
                        verticalAlign: "top",
                      },
                    }}
                  >
                    <div>
                      <NumberField label="Model height in cm" name="height" />
                    </div>
                    <div>
                      <NumberField label="Model clothing size" name="size" />
                    </div>
                  </div>

                  <FileField
                    label="Model head shot"
                    name="image1"
                    accept="image/jpeg,image/png"
                    note="Register 1 JPG or 1 PNG image, no links or multiple photos accepted"
                  />
                  <FileField
                    label="Model profile shot"
                    name="image2"
                    accept="image/jpeg,image/png"
                    note="Register 1 JPG or 1 PNG image, no links or multiple photos accepted"
                  />
                  <FileField
                    label="Model full body shot"
                    name="image3"
                    accept="image/jpeg,image/png"
                    note="Register 1 JPG or 1 PNG image, no links or multiple photos accepted"
                  />

                  <TextField label="Link to more images" name="imagesLink" />

                  <TextField
                    label="Message"
                    name="message"
                    multiline
                    autoSize
                    minRows={5}
                  />

                  <CheckboxField
                    label="Jag har läst och godkänt villkoren / I have read and agreed to the terms"
                    note={
                      <Link href="/become-a-model-terms" target="_blank">
                        Läs villkoren / Read the terms
                      </Link>
                    }
                    required
                  />

                  <Button onClick={submit} css={{ display: "block" }}>
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </Form>

      <Footer css={{ marginTop: "calc(2 * var(--spacing))" }} />
    </>
  );
}
