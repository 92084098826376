import * as React from "react";
import Svg from "../Svg.tsx";

export default function PlusIcon({
  color = "var(--text-color)",
  ...otherProps
}: {
  color?: string;
} & Omit<
  React.ComponentPropsWithoutRef<typeof Svg>,
  "viewBox"
>): React.ReactElement {
  return (
    <Svg viewBox="0 0 28 28" {...otherProps}>
      <path
        fill={color}
        d="M26 12H16V2c0-1.1-.9-2-2-2s-2 .9-2 2v10H2c-1.1 0-2 .9-2 2s.9 2 2 2h10v10c0 1.1.9 2 2 2s2-.9 2-2V16h10c1.1 0 2-.9 2-2s-.9-2-2-2z"
      />
    </Svg>
  );
}
