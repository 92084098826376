enum Location {
  Any = "Any location",
  Sweden = "Sweden",
  Scandinavia = "Scandinavia",
  Europe = "Europe",
  NorthAmerica = "North America",
  SouthAmerica = "South America",
  Africa = "Africa",
  Australia = "Australia",
  Asia = "Asia",
  MiddleEast = "Middle East",
}

const inclusions: { [location: string]: Location[] } = {
  [Location.Scandinavia]: [Location.Sweden],
  [Location.Europe]: [Location.Sweden, Location.Scandinavia],
};

export function locationMatches(
  searchLocation: Location,
  modelLocation: Location
) {
  return (
    searchLocation === Location.Any ||
    searchLocation === modelLocation ||
    inclusions[searchLocation]?.includes(modelLocation)
  );
}

export default Location;
