import * as React from "react";
import { Route } from "react-router-dom";
import Notification from "./Notification";

export default function NotFoundNotification(props: any): React.ReactElement {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = 404;

        return (
          <Notification type="error" {...props}>
            Can&apos;t find what you&apos;re looking for.
          </Notification>
        );
      }}
    />
  );
}
