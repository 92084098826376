import qs from "query-string";
import { History, Location } from "history";

/**
 * Replace history search params, skipping params with undefined as values.
 *
 * @param {*} history
 * @param {*} location
 * @param {*} params
 */
export default function replaceHistorySearchParameters(
  history: History,
  location: Location,
  params: { [key: string]: string }
): void {
  const finalParams = {
    ...qs.parse(location.search),
    ...params,
  };

  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined) delete finalParams[key];
  });

  history.replace({
    pathname: location.pathname,
    search: qs.stringify(finalParams),
  });
}
