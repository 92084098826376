import * as React from "react";
import { QueryRenderer } from "react-relay";
import { useRelayEnvironment } from "react-relay/hooks";
import ErrorHandler from "./ErrorHandler";
import { paddedCss } from "./css";
import CenteredLoadingIndicator from "./CenteredLoadingIndicator";

export default function SmartQueryRenderer({
  render,
  keepOldDataBetweenLoads,
  ...otherProps
}: {
  render: ({
    props,
    retry,
  }: {
    props: any;
    retry: (() => void) | null;
  }) => React.ReactNode;
  keepOldDataBetweenLoads?: boolean;
} & Omit<
  JSX.LibraryManagedAttributes<
    typeof QueryRenderer,
    QueryRenderer<any>["props"]
  >,
  "environment"
>): React.ReactElement {
  const queryPropsRef = React.useRef<any>();
  const relayEnvironment = useRelayEnvironment();

  return (
    <QueryRenderer
      {...otherProps}
      environment={relayEnvironment}
      render={({ error, props, retry }) => {
        if (props || !keepOldDataBetweenLoads) queryPropsRef.current = props;

        if (!error && queryPropsRef.current)
          return render({ props: queryPropsRef.current, retry });

        return error ? (
          <ErrorHandler
            error={error}
            retry={retry}
            css={{
              ...paddedCss,
              width: "100%",
              height: "100%",
            }}
          />
        ) : (
          <CenteredLoadingIndicator />
        );
      }}
    />
  );
}
