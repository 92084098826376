export type Options = {
  message?: string;
  cause?: Error;
};

export default class ExtendableError {
  name: string;

  stack?: string;

  constructor(options: Options) {
    this.name = this.constructor.name;
    Object.assign(this, options);

    if (typeof Error.captureStackTrace === "function")
      Error.captureStackTrace(this, this.constructor);
    else this.stack = new Error(options.message).stack;
  }
}
