import * as React from "react";
import Svg from "../Svg.tsx";

export default function CheckIcon({
  color = "var(--text-color)",
  ...otherProps
}: {
  color?: string;
} & Omit<
  React.ComponentPropsWithoutRef<typeof Svg>,
  "viewBox"
>): React.ReactElement {
  return (
    <Svg {...otherProps} viewBox="0 0 52.2 61.6">
      <path
        d="M48.5.8C45.1-1 41 .3 39.2 3.7L19.7 40.5l-7.6-12.7c-1-1.6-4.4-1.4-7.7.6-3.3 1.9-5.1 4.8-4.2 6.4L13.5 57c.6 1.6 1.7 2.9 3.3 3.8 3.4 1.8 7.5.5 9.3-2.9L51.4 10c1.7-3.3.4-7.5-2.9-9.2z"
        fill={color}
      />
    </Svg>
  );
}
