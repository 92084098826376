import * as React from "react";
import ReactMarkdown from "react-markdown";
import { useTheme } from "@emotion/react";
import Link from "./Link";
import Image from "./Image";
import {
  olCss,
  ulCss,
  strongCss,
  pCss,
  h1Css,
  h2Css,
  h3Css,
  h4Css,
} from "./css";

export default function Markdown({
  children,
  components,
  ...otherProps
}: {
  children: string;
  components?: {
    [name: string]: (props: {
      defaultComponent: () => React.ReactElement;
    }) => React.ReactElement;
  };
}): React.ReactElement {
  const theme = useTheme();

  const actualComponents = React.useMemo(() => {
    const heading = ({ children, level }) => {
      let css;
      if (level === 0) css = h1Css;
      else if (level === 1) css = h2Css;
      else if (level === 2) css = h3Css;
      else if (level === 3) css = h4Css;
      return (
        <div
          css={{
            ...css,
            marginTop: theme.spacing * 2,
          }}
        >
          {children}
        </div>
      );
    };

    const returnComponents = {
      h1: heading,
      h2: heading,
      h3: heading,
      h4: heading,
      h5: heading,
      h6: heading,
      li: ({ children, type }) => (
        <ol css={type === "Ordered" ? olCss : ulCss}>{children}</ol>
      ),
      strong: ({ children }) => <strong css={strongCss}>{children}</strong>,
      a: ({ children, href }) => {
        const props = {};
        if (
          href.startsWith("//") ||
          href.startsWith("http://") ||
          href.startsWith("https://") ||
          href.startsWith("mailto:")
        )
          props.href = href;
        else props.to = href;
        return (
          <Link {...props} target="_blank">
            {children}
          </Link>
        );
      },
      p: ({ children }) => <p css={pCss}>{children}</p>,
      img: ({ width, height, ...otherProps }: any) => (
        <Image
          key={otherProps.src}
          css={{
            border: "1px solid var(--separator-color)",
          }}
          width={width !== undefined ? width - 2 : undefined}
          height={height !== undefined ? height - 2 : undefined}
          {...otherProps}
        />
      ),
    };

    if (components)
      Object.entries(components).forEach(([name, component]) => {
        const defaultComponent = returnComponents[name];
        returnComponents[name] = (props: any) =>
          component({ defaultComponent, ...props });
      });

    return returnComponents;
  }, [components]);

  return (
    <div {...otherProps}>
      <ReactMarkdown css={pCss} components={actualComponents}>
        {children}
      </ReactMarkdown>
    </div>
  );
}
