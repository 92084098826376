export default {
  host: "stockholmsgruppen.com",
  defaultLocale: "en",
  supportEmailAddress: "info@stockholmsgruppen.com",
  logInPath: "/log-in",
  sentry: {
    // Only use https://sentry.io because that is what CSP allows
    dsn: "https://db31256ad4b542ae8ae471a5c8725133@sentry.io/5775071",
    cspReportUri:
      "https://o690229.ingest.sentry.io/api/5775071/security/?sentry_key=db31256ad4b542ae8ae471a5c8725133",
  },
  assets: {
    // baseUrl: "/",
    imgixBaseUrl: "https://stockholmsgruppen.imgix.net/",
  },
  files: {
    baseUrl: "https://files.stockholmsgruppen.net/",
    // baseUrl: "https://stockholmsgruppentestfiles.s3.amazonaws.com/",
    imgixBaseUrl: "https://stockholmsgruppenfiles.imgix.net/",
    // imgixBaseUrl: "https://stockholmsgruppentestfiles.imgix.net/",
  },
  graphql: {
    // endpointUrl: "http://localhost:8080/graphql",
    endpointUrl: "https://api.stockholmsgruppen.net/graphql",
  },
  facebookUrls: {
    general: "https://www.facebook.com/profile.php?id=100063501973226",
    kids: "https://www.facebook.com/stockholmsgruppenkids",
    commercial:
      "https://www.facebook.com/Stockholmsgruppen-Commercial-People-354308837986880",
  },
  instagramUrls: {
    general: "https://www.instagram.com/stockholmsgruppen",
    kids: "https://www.instagram.com/stockholmsgruppenkids",
    commercial: "https://www.instagram.com/stockholmsgruppencommercial",
  },
  restApi: {
    baseUrl: "https://api.stockholmsgruppen.net/rest/v1",
  },
  healthCheckPath: "/healthCheck",
};
