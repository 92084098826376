import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, QueryRenderer, useRelayEnvironment } from "react-relay";
import { useLocation } from "react-router";
import * as queryString from "query-string";
import { useHistory } from "react-router-dom";
import Button from "../../common/Button.tsx";
import { h2Css } from "../../common/css.ts";
import ErrorHandler from "../../common/ErrorHandler.tsx";
import Form from "../../common/forms/Form.tsx";
import NumberField from "../../common/forms/NumberField.tsx";
import SelectField from "../../common/forms/SelectField.tsx";
import TabBarField from "../../common/forms/TabBarField.tsx";
import TextField from "../../common/forms/TextField.tsx";
import modelHairColors from "../../common/modelHairColors.ts";
import PaddedHoverContent from "../../common/PaddedHoverContent.tsx";
import replaceHistorySearchParameters from "../../common/replaceHistorySearchParameters.ts";
import modelEyeColors from "../../common/modelEyeColors.ts";
import LoadingIndicator from "../../common/LoadingIndicator.tsx";
import ModelGrid from "../common/ModelGrid.tsx";
import Footer from "../common/Footer.tsx";
import getFacebookUrlFromStatuses from "../common/getFacebookUrlFromStatuses.ts";
import getInstagramUrlFromStatuses from "../common/getInstagramUrlFromStatuses.ts";
import DownArrowIcon from "../../common/icons/DownArrowIcon.tsx";
import Location from "../common/Model/Location.ts";
import nl2br from "../../common/nl2br.tsx";

const maleFieldOptions = [undefined, "true", "false"];
const maleFieldLabelRenderer = (option: any) => {
  if (!option) return "All";
  return option === "true" ? "Male" : "Female";
};

export default function ModelsHome({
  anyOfStatus,
  statusOptions,
  hideAgeFilters,
  showLocationFilter,
  comparator,
  pageName,
}: {
  anyOfStatus?: string[];
  status?: string;
  statusOptions?: React.ComponentPropsWithoutRef<typeof Button>[];
  hideAgeFilters?: boolean;
  showLocationFilter?: boolean;
  defaultLocationFilterValue?: string;
  comparator: React.ComponentProps<typeof ModelGrid>["comparator"];
  pageName: string;
}): React.ReactElement {
  const relayEnvironment = useRelayEnvironment();
  const location = useLocation();
  const history = useHistory();

  const defaultSearchParameters = {
    location: showLocationFilter ? Location.Any : undefined,
  };

  const searchParameters = {
    ...defaultSearchParameters,
    ...queryString.parse(location.search),
  };

  return (
    <QueryRenderer
      environment={relayEnvironment}
      query={graphql`
        query ModelsHomeQuery($anyOfStatus: [String!], $pageName: String!) {
          models(visible: true, anyOfStatus: $anyOfStatus) {
            ...ModelGrid_models
          }
          page(namePath: [$pageName]) {
            title
            content
          }
        }
      `}
      variables={{ anyOfStatus, pageName }}
      render={({ props, error, retry }) => {
        if (error) return <ErrorHandler error={error} retry={retry} />;

        if (!props)
          return (
            <LoadingIndicator
              css={{
                marginTop: "calc(2 * var(--spacing))",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          );

        const title = props.page?.title;

        return (
          <>
            <Helmet title={title} />
            <ModelGrid
              css={{ width: "100%", height: "100%" }}
              models={props.models}
              searchParameters={searchParameters}
              header={({ style }) => {
                const filtersButtonFiltersSet =
                  Object.entries(searchParameters).filter(
                    ([key, value]) =>
                      key !== "searchString" &&
                      key !== "male" &&
                      key !== "location" &&
                      value,
                  ).length > 0;

                return (
                  <div style={style}>
                    <div
                      css={{
                        paddingTop: "calc(2 * var(--padding-top))",
                        paddingRight: "calc(2 * var(--padding-right))",
                        paddingBottom: "calc(3 * var(--spacing))",
                        paddingLeft: "calc(2 * var(--padding-left))",
                        maxWidth: "var(--max-width)",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      {(title || props.page?.content) && (
                        <div css={{ marginBottom: "calc(2 * var(--spacing))" }}>
                          {title && (
                            <div
                              css={{
                                ...h2Css,
                                textAlign: "center",
                              }}
                            >
                              {title}
                            </div>
                          )}
                          {nl2br(props.page?.content)}
                        </div>
                      )}

                      <div
                        css={{
                          width: "100%",
                          maxWidth: 400,
                          marginLeft: "auto",
                          marginRight: "auto",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          ">*:not(:last-child)": {
                            marginBottom: "calc(2 * var(--spacing))",
                          },
                        }}
                      >
                        <div
                          css={{
                            ">*:not(:last-child)": {
                              marginBottom: "var(--spacing)",
                            },
                          }}
                        >
                          <div
                            css={{
                              display: "flex",
                              ">*:not(:last-child)": {
                                marginRight: "var(--spacing)",
                              },
                            }}
                          >
                            {statusOptions && (
                              <Button
                                type="naked"
                                iconComponent={DownArrowIcon}
                                iconPosition="right"
                                hoverOptions={statusOptions.map(
                                  ({ to, ...option }) => ({
                                    ...option,
                                    to: `${to}${location.search}`,
                                    activeWhenMatch: true,
                                  }),
                                )}
                              >
                                {statusOptions.find(
                                  (option) => option.to === location.pathname,
                                )?.children || null}
                              </Button>
                            )}
                            {showLocationFilter && (
                              <Button
                                type="naked"
                                iconComponent={DownArrowIcon}
                                iconPosition="right"
                                hoverOptions={Object.values(Location).map(
                                  (l) => ({
                                    children: l,
                                    onClick: () =>
                                      replaceHistorySearchParameters(
                                        history,
                                        location,
                                        {
                                          location: l,
                                        },
                                      ),
                                    active: searchParameters.location === l,
                                  }),
                                )}
                              >
                                {searchParameters.location}
                              </Button>
                            )}
                          </div>

                          <TabBarField
                            css={{ textAlign: "center" }}
                            name="male"
                            options={maleFieldOptions}
                            labelRenderer={maleFieldLabelRenderer}
                            onChange={({ value }) =>
                              replaceHistorySearchParameters(
                                history,
                                location,
                                {
                                  male: value,
                                },
                              )
                            }
                            value={searchParameters.male}
                          />
                        </div>

                        <div
                          css={{
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <TextField
                            type="search"
                            placeholder="Search"
                            css={{ flex: 1, marginRight: "var(--spacing)" }}
                            value={searchParameters?.searchString}
                            onChange={({ value }) =>
                              replaceHistorySearchParameters(
                                history,
                                location,
                                {
                                  searchString: value,
                                },
                              )
                            }
                          />
                          <Button
                            type="discrete"
                            iconComponent={DownArrowIcon}
                            iconPosition="right"
                            hoverContentMaxWidth={300}
                            active={filtersButtonFiltersSet}
                            hoverContent={(hoverContentProps) => (
                              <PaddedHoverContent
                                {...hoverContentProps}
                                title="Filters"
                                css={{
                                  width: "100%",
                                  "> *:not(:last-child)": {
                                    marginBottom: "var(--spacing)",
                                  },
                                }}
                              >
                                <Form
                                  onChange={({ values }) => {
                                    replaceHistorySearchParameters(
                                      history,
                                      location,
                                      values,
                                    );
                                  }}
                                >
                                  {!hideAgeFilters && (
                                    <div
                                      css={{
                                        display: "flex",
                                        ">*": {
                                          width:
                                            "calc((100% - var(--spacing)) / 2)",
                                          marginRight: "var(--spacing)",
                                          ":last-child": { marginRight: 0 },
                                        },
                                      }}
                                    >
                                      <NumberField
                                        label="From age"
                                        name="fromAge"
                                        value={searchParameters?.fromAge}
                                      />
                                      <NumberField
                                        label="To age"
                                        name="toAge"
                                        value={searchParameters?.toAge}
                                      />
                                    </div>
                                  )}
                                  <div
                                    css={{
                                      display: "flex",
                                      ">*": {
                                        width:
                                          "calc((100% - var(--spacing)) / 2)",
                                        marginRight: "var(--spacing)",
                                        ":last-child": { marginRight: 0 },
                                      },
                                    }}
                                  >
                                    <NumberField
                                      label="From size"
                                      name="fromSize"
                                      value={searchParameters?.fromSize}
                                    />
                                    <NumberField
                                      label="To size"
                                      name="toSize"
                                      value={searchParameters?.toSize}
                                    />
                                  </div>
                                  <div
                                    css={{
                                      display: "flex",
                                      ">*": {
                                        width:
                                          "calc((100% - var(--spacing)) / 2)",
                                        marginRight: "var(--spacing)",
                                        ":last-child": { marginRight: 0 },
                                      },
                                    }}
                                  >
                                    <NumberField
                                      label="From height (cm)"
                                      name="fromHeight"
                                      value={searchParameters?.fromHeight}
                                    />
                                    <NumberField
                                      label="To height (cm)"
                                      name="toHeight"
                                      value={searchParameters?.toHeight}
                                    />
                                  </div>
                                  <SelectField
                                    multi
                                    label="Hair color"
                                    options={modelHairColors}
                                    name="hairColors"
                                    value={
                                      searchParameters?.hairColors &&
                                      ((Array.isArray(
                                        searchParameters.hairColors,
                                      ) &&
                                        searchParameters.hairColors) || [
                                        searchParameters.hairColors,
                                      ])
                                    }
                                    showClearButton
                                  />
                                  <SelectField
                                    multi
                                    label="Eye color"
                                    options={modelEyeColors}
                                    name="eyeColors"
                                    value={
                                      searchParameters?.eyeColors &&
                                      ((Array.isArray(
                                        searchParameters.eyeColors,
                                      ) &&
                                        searchParameters.eyeColors) || [
                                        searchParameters.eyeColors,
                                      ])
                                    }
                                    showClearButton
                                  />
                                  <div
                                    css={{
                                      display: "flex",
                                      ">*": {
                                        width:
                                          "calc((100% - var(--spacing)) / 2)",
                                        marginRight: "var(--spacing)",
                                        ":last-child": { marginRight: 0 },
                                      },
                                    }}
                                  >
                                    <NumberField
                                      label="From shoe size"
                                      name="fromShoeSize"
                                      value={searchParameters?.fromShoeSize}
                                    />
                                    <NumberField
                                      label="To shoe size"
                                      name="toShoeSize"
                                      value={searchParameters?.toShoeSize}
                                    />
                                  </div>
                                  <Button
                                    type="naked"
                                    disabled={!filtersButtonFiltersSet}
                                    onClick={() =>
                                      replaceHistorySearchParameters(
                                        history,
                                        location,
                                        Object.keys(searchParameters)
                                          .filter(
                                            (key) =>
                                              key !== "searchString" &&
                                              key !== "location",
                                          )
                                          .reduce(
                                            (previousValue, currentValue) => ({
                                              ...previousValue,
                                              [currentValue]: null,
                                            }),
                                            {},
                                          ),
                                      )
                                    }
                                  >
                                    Clear
                                  </Button>
                                </Form>
                              </PaddedHoverContent>
                            )}
                          >
                            Filters
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
              footer={({ style }) => (
                <Footer
                  style={style}
                  facebookUrl={getFacebookUrlFromStatuses(anyOfStatus)}
                  instagramUrl={getInstagramUrlFromStatuses(anyOfStatus)}
                />
              )}
              comparator={comparator}
            />
          </>
        );
      }}
    />
  );
}
