import * as React from "react";
import { Helmet } from "react-helmet";
import createCache from "@emotion/cache";
import { Switch, Route, useHistory } from "react-router-dom";
import { css, Global, CacheProvider, ThemeProvider } from "@emotion/react";
import { RelayEnvironmentProvider } from "react-relay";
import CookieManagerContext, {
  CookieManager,
} from "./common/CookieManagerContext";
import BrowserIsNotSupportedContext from "./common/BrowserIsNotSupportedContext";
import CookieName from "./common/CookieName";
import Main from "./Main";
import { mainCss, paddedCss, themeVarsCss } from "./common/css";
import createTheme from "./common/createTheme";
import MeasuredDiv from "./common/MeasuredDiv";
import AppMeasurementsContext from "./common/AppMeasurementsContext";
import SafeAreaInsetsContext from "./common/SafeAreaInsetsContext";
import NotificationsContext, {
  Notification as NotificationType,
} from "./common/NotificationsContext";
import Notification from "./common/Notification";
import { generateUniqueId } from "./common/unique-id";
import createRelayEnvironment from "./common/createRelayEnvironment";
import { Auth } from "./common/authenticateWithServer";
import errorLogger from "./common/ErrorLogger";
import SecurityContext, {
  SecurityContextValue,
} from "./common/SecurityContext";
import Manager from "./Manager";
import UserAgentContext from "./common/UserAgentContext";
import config from "./config";
import LocaleContext from "./common/LocaleContext";

export default function App({
  cookieManager,
  userAgent,
}: {
  cookieManager: CookieManager;
  userAgent?: string;
}): React.ReactElement {
  const history = useHistory();

  const [securityContext, setSecurityContext] =
    React.useState<SecurityContextValue>(() => {
      let auth;
      try {
        auth = cookieManager.getCookie(CookieName.Auth);
        if (auth) {
          auth = JSON.parse(auth);
          errorLogger.setUserId(auth?.userId);
        }
      } catch (error) {
        errorLogger.warn(error);
      }

      return {
        auth,
        setAuth: (newAuth: Auth) => {
          errorLogger.setUserId(newAuth.userId);
          try {
            cookieManager.setCookie(CookieName.Auth, JSON.stringify(newAuth));
          } catch (error) {
            errorLogger.warn(error);
          }
          setSecurityContext({
            ...securityContext,
            auth: newAuth,
          });
        },
        logOut: () => {
          history.push(config.logInPath);
          errorLogger.setUserId();
          cookieManager.removeCookie(CookieName.Auth);
          setSecurityContext({
            ...securityContext,
            auth: undefined,
          });
        },
      };
    });
  const relayEnvironment = React.useMemo(
    () =>
      createRelayEnvironment({
        getAuth: () => securityContext.auth,
      }),
    [securityContext]
  );
  const nonce = cookieManager.getCookie(CookieName.Nonce) || "";
  const emotionCache = React.useMemo(
    () => createCache({ key: "a", nonce }),
    [nonce]
  );
  const theme = React.useMemo(() => createTheme(), []);
  const [notifications, setNotifications] = React.useState<NotificationType[]>(
    []
  );
  const notificationsContext = React.useMemo(
    () => ({
      notifications,
      addNotification: (someNotification: any) => {
        const type =
          someNotification.type ||
          (someNotification.error && "error") ||
          "info";
        const notification = {
          autoHide: type === "info",
          ...(typeof someNotification === "string"
            ? { message: someNotification }
            : someNotification),
          id: `${generateUniqueId()}`,
          type,
        };
        setNotifications([...notifications, notification]);
        return notification;
      },
      dismissNotifications: (notificationsToDismiss: any[]) =>
        setNotifications(
          notificationsContext.notifications.filter(
            (notification) => !notificationsToDismiss.includes(notification)
          )
        ),
    }),
    [notifications, setNotifications]
  );

  const locale = cookieManager.getCookie(CookieName.Locale);

  return (
    <UserAgentContext.Provider value={userAgent}>
      <CacheProvider value={emotionCache}>
        <Global
          styles={css`
            /* http://meyerweb.com/eric/tools/css/reset/ v2.0 | 20110126 */
            html,
            body,
            div,
            span,
            applet,
            object,
            iframe,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            blockquote,
            pre,
            a,
            abbr,
            acronym,
            address,
            big,
            cite,
            code,
            del,
            dfn,
            em,
            img,
            ins,
            kbd,
            q,
            s,
            samp,
            small,
            strike,
            strong,
            sub,
            sup,
            tt,
            var,
            b,
            u,
            i,
            center,
            dl,
            dt,
            dd,
            ol,
            ul,
            li,
            fieldset,
            form,
            label,
            legend,
            table,
            caption,
            tbody,
            tfoot,
            thead,
            tr,
            th,
            td,
            article,
            aside,
            canvas,
            details,
            embed,
            figure,
            figcaption,
            footer,
            header,
            hgroup,
            menu,
            nav,
            output,
            ruby,
            section,
            summary,
            time,
            mark,
            audio,
            video {
              margin: 0;
              padding: 0;
              border: 0;
              font-size: 100%;
              font: inherit;
              vertical-align: baseline;
            }
            /* HTML5 display-role reset for older browsers */
            article,
            aside,
            details,
            figcaption,
            figure,
            footer,
            header,
            hgroup,
            menu,
            nav,
            section {
              display: block;
            }
            body {
              line-height: 1;
            }
            ol,
            ul {
              list-style: none;
            }
            blockquote,
            q {
              quotes: none;
            }
            blockquote:before,
            blockquote:after,
            q:before,
            q:after {
              content: "";
              content: none;
            }
            table {
              border-collapse: collapse;
              border-spacing: 0;
            }
            /* Own additions */
            button {
              margin: 0;
            }
            input[type="text"]::-ms-clear {
              display: none;
            }
            button::-moz-focus-inner {
              padding: 0;
              border: 0;
            }
            :focus {
              outline: 0;
            }
            * {
              -webkit-tap-highlight-color: transparent;
              -webkit-touch-callout: none;
            }
            style {
              display: none !important; /* So that we can use the ">*" selector without worrying about emotion-css-injected <style> elements */
            }
            print-color-adjust: exact;
          `}
        />
        <Global
          styles={{
            "html, #content": {
              ...themeVarsCss(theme),
              ...mainCss,
            },
          }}
        />
        <Helmet>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta
            name="viewport"
            content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, viewport-fit=cover"
          />
          <meta name="referrer" content="origin" />
          <meta name="msapplication-tap-highlight" content="no" />
          <link
            rel="icon"
            sizes="any"
            type="image/svg+xml"
            href="/favicon.svg"
          />
        </Helmet>
        <LocaleContext.Provider value={locale}>
          <CookieManagerContext.Provider value={cookieManager}>
            <BrowserIsNotSupportedContext.Provider
              value={
                cookieManager.getCookie(CookieName.BrowserIsNotSupported) ===
                "true"
              }
            >
              <NotificationsContext.Provider value={notificationsContext}>
                <SecurityContext.Provider value={securityContext}>
                  <RelayEnvironmentProvider environment={relayEnvironment}>
                    <ThemeProvider theme={theme}>
                      <MeasuredDiv
                        width
                        height
                        safeAreaInsets
                        css={{
                          height: "100%",
                        }}
                      >
                        {(appMeasurements) => (
                          <AppMeasurementsContext.Provider
                            value={appMeasurements}
                          >
                            <SafeAreaInsetsContext.Provider
                              value={appMeasurements.safeAreaInsets}
                            >
                              <Switch>
                                <Route path="/manager" component={Manager} />
                                <Route path="/" component={Main} />
                              </Switch>

                              <div id="hoverBoxContents" />

                              {notifications?.length > 0 && (
                                <div
                                  css={{
                                    ...paddedCss,
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    maxWidth: 600,
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    width: "100%",
                                    maxHeight: "100%",
                                    overflow: "auto",
                                    boxSizing: "border-box",
                                    zIndex: 10,
                                    ">*": {
                                      marginTop: "var(--spacing)",
                                      textAlign: "center",
                                    },
                                  }}
                                >
                                  {notifications
                                    .reduce((accumulator, notification) => {
                                      let addedToGroup = false;
                                      for (
                                        let accumulatorLength =
                                            accumulator.length,
                                          i = 0;
                                        i < accumulatorLength;
                                        i += 1
                                      ) {
                                        const notificationInAccumulator =
                                          Array.isArray(accumulator[i])
                                            ? accumulator[i][0]
                                            : accumulator[i];

                                        if (
                                          (notification.message ===
                                            notificationInAccumulator.message ||
                                            (notification.error?.message &&
                                              notification.error?.message ===
                                                notificationInAccumulator.error
                                                  ?.message)) &&
                                          notification.autoHide ===
                                            notificationInAccumulator.autoHide
                                        ) {
                                          if (Array.isArray(accumulator[i]))
                                            accumulator[i].push(notification);
                                          else
                                            accumulator[i] = [
                                              accumulator[i],
                                              notification,
                                            ];
                                          addedToGroup = true;
                                          break;
                                        }
                                      }
                                      if (!addedToGroup)
                                        accumulator.push(notification);
                                      return accumulator;
                                    }, [])
                                    .map((notificationOrNotifications) => {
                                      const array = Array.isArray(
                                        notificationOrNotifications
                                      );
                                      return (
                                        <Notification
                                          key={
                                            (array
                                              ? notificationOrNotifications[
                                                  notificationOrNotifications.length -
                                                    1
                                                ]
                                              : notificationOrNotifications
                                            ).id
                                          }
                                          notification={
                                            array
                                              ? undefined
                                              : notificationOrNotifications
                                          }
                                          notifications={
                                            array
                                              ? notificationOrNotifications
                                              : undefined
                                          }
                                        />
                                      );
                                    })}
                                </div>
                              )}
                            </SafeAreaInsetsContext.Provider>
                          </AppMeasurementsContext.Provider>
                        )}
                      </MeasuredDiv>
                    </ThemeProvider>
                  </RelayEnvironmentProvider>
                </SecurityContext.Provider>
              </NotificationsContext.Provider>
            </BrowserIsNotSupportedContext.Provider>
          </CookieManagerContext.Provider>
        </LocaleContext.Provider>
      </CacheProvider>
    </UserAgentContext.Provider>
  );
}
