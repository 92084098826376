import UserFriendlyMessageError, {
  Options as ParentOptions,
} from "./UserFriendlyMessageError";

export type Options = ParentOptions;

export default class ConnectionFailedError extends UserFriendlyMessageError {
  constructor({
    userFriendlyMessage = "Connection failed. Please check your internet connection.",
    ...otherOptions
  }: Options = {}) {
    super({ ...otherOptions, userFriendlyMessage });
    this.name = this.constructor.name;
  }
}
