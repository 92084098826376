import * as React from "react";
import Button from "./Button.tsx";

export default function FileSelectButton({
  onSelect,
  accept,
  multiple,
  ...otherProps
}: {
  onSelect: (files: FileList) => void;
  children: React.ReactNode;
  accept?: string;
  multiple?: boolean;
} & React.ComponentPropsWithoutRef<typeof Button>) {
  const inputRef = React.useRef<HTMLInputElement>();

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        css={{ display: "none" }}
        onChange={(event) => {
          if (onSelect) onSelect(event.target.files);
          event.target.value = "";
        }}
        accept={accept}
        multiple={multiple}
      />
      <Button onClick={() => inputRef.current?.click()} {...otherProps} />
    </>
  );
}
