import { PureComponent } from "react";
import ResizeObserver from "resize-observer-polyfill";
import {
  getSafeAreaInsets,
  SafeAreaInsets,
  Disposable,
  addSafeAreaInsetsChangeListener,
} from "./safeAreaInsets";

const measurableProps = ["width", "height", "top", "right", "bottom", "left"];

export default class Measured extends PureComponent<
  {
    children: (props: {
      ref: (ref: HTMLElement | null) => void;
      width?: number;
      height?: number;
      top?: number;
      right?: number;
      bottom?: number;
      left?: number;
    }) => React.ReactNode;
    width?: boolean;
    height?: boolean;
    top?: boolean;
    right?: boolean;
    bottom?: boolean;
    left?: boolean;
    safeAreaInsets?: boolean;
    onChange?: (props: {
      width?: number;
      height?: number;
      top?: number;
      right?: number;
      bottom?: number;
      left?: number;
      safeAreaInsets?: SafeAreaInsets;
    }) => void;
  },
  {
    width?: number;
    height?: number;
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
    safeAreaInsets?: SafeAreaInsets;
  }
> {
  state = {};

  resizeObserver: ResizeObserver | undefined;

  safeAreaInsetsChangeListener: Disposable | undefined;

  element?: HTMLElement | null;

  update = (): void => {
    if (!this.element) return;
    const { onChange } = this.props;
    const rect = this.element.getBoundingClientRect();
    const stateChange = {};

    measurableProps.forEach((measureProp) => {
      if (this.props[measureProp]) {
        stateChange[measureProp] = rect[measureProp];
      }
    });

    if (stateChange.right !== undefined)
      stateChange.right = window.innerWidth - stateChange.right;
    if (stateChange.bottom !== undefined)
      stateChange.bottom = window.innerHeight - stateChange.bottom;

    if (this.props.safeAreaInsets) {
      const safeAreaInsets = getSafeAreaInsets();

      stateChange.safeAreaInsets = {
        top: Math.max(0, safeAreaInsets.top - rect.top),
        right: Math.max(
          0,
          safeAreaInsets.right - (window.innerWidth - rect.right)
        ),
        bottom: Math.max(
          0,
          safeAreaInsets.bottom - (window.innerHeight - rect.bottom)
        ),
        left: Math.max(0, safeAreaInsets.left - rect.left),
      };
    }

    this.setState(
      stateChange,
      !onChange ? undefined : () => onChange(this.state)
    );
  };

  setRef = (ref: HTMLElement | null): void => {
    this.element = ref;
    if (ref) {
      this.resizeObserver = new ResizeObserver(this.update);
      this.resizeObserver.observe(ref);
      window.addEventListener("resize", this.update);

      if (this.props.safeAreaInsets)
        this.safeAreaInsetsChangeListener = addSafeAreaInsetsChangeListener(
          this.update
        );

      this.update();
    } else if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = undefined;
    }
  };

  render() {
    return this.props.children({
      ref: this.setRef,
      ...this.state,
    });
  }
}
