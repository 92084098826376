import config from "../../config";

export default function getFacebookUrlFromStatuses(statuses: string[]): string {
  if (
    statuses.includes("Babies") ||
    statuses.includes("Child") ||
    statuses.includes("Teens")
  )
    return config.facebookUrls.kids;
  if (statuses.includes("Commercial")) return config.facebookUrls.commercial;
  return config.facebookUrls.general;
}
