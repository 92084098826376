import UserFriendlyMessageError, {
  Options as ParentOptions,
} from "./UserFriendlyMessageError";

export type Options = {
  key?: any;
} & ParentOptions;

export default class NotFoundError extends UserFriendlyMessageError {
  constructor({
    message = "Can't find what you're looking for.",
    ...otherOptions
  }: Options = {}) {
    super({ ...otherOptions, message });
    this.name = this.constructor.name;
  }
}
