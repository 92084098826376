import * as React from "react";
import Svg from "../Svg.tsx";

export default function DownArrowIcon({
  color = "var(--text-color)",
  ...otherProps
}: {
  color?: string;
} & Omit<
  React.ComponentPropsWithoutRef<typeof Svg>,
  "viewBox"
>): React.ReactElement {
  return (
    <Svg viewBox="0 0 700 700" {...otherProps}>
      <path
        fill={color}
        d="M350 414.2L138.9 201.9a40.139 40.139 0 0 0-57.1 0c-15.8 15.9-15.8 41.6 0 57.4l237.6 238.9c8.4 8.5 19.6 12.3 30.6 11.7 11 .6 22.2-3.2 30.6-11.7l237.6-238.9c15.8-15.9 15.8-41.6 0-57.4s-41.3-15.9-57.1 0L350 414.2z"
      />
    </Svg>
  );
}
