import * as React from "react";

const createdModelListIdsStorageName = "createdModelListIds";

const get = () => {
  const valueString = window.localStorage.getItem(
    createdModelListIdsStorageName
  );
  return valueString ? JSON.parse(valueString) : [];
};

const CreatedModelListIdsContext = React.createContext({
  get,
  add: (id: string) => {
    const ids = get();
    window.localStorage.setItem(
      createdModelListIdsStorageName,
      JSON.stringify([
        ...ids.slice(Math.max(0, ids.length - 1000), ids.length),
        id,
      ])
    );
  },
});

export default CreatedModelListIdsContext;

export const useCreatedModelListIdsContext = () =>
  React.useContext(CreatedModelListIdsContext);
