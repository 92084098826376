import * as React from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, useLocation } from "react-router-dom";
import { Global } from "@emotion/react";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";
import Button from "../common/Button.tsx";
import MenuIcon from "../common/icons/MenuIcon.tsx";
import HeartIcon from "../common/icons/HeartIcon.tsx";
import Logo from "../common/Logo.tsx";
import Home from "./Home.tsx";
import LogIn from "./LogIn.tsx";
import Models from "./Models/index.tsx";
import ModelListContext from "./common/ModelListContext.tsx";
import commitMutationPromise from "../common/commitMutationPromise.ts";
import { useNotifications } from "../common/NotificationsContext.tsx";
import errorLogger from "../common/ErrorLogger.ts";
import ModelList from "./ModelList.tsx";
import Page from "./Page.tsx";
import { useCookieManager } from "../common/CookieManagerContext.tsx";
import config from "../config";
import { useCreatedModelListIdsContext } from "./common/CreatedModelListIdsContext.tsx";
import BecomeAModel from "./BecomeAModel.tsx";
import Updates from "./Updates.tsx";

function mapMenuOption({ options, ...otherProps }: any) {
  return {
    ...otherProps,
    activeWhenMatch: !otherProps.activeWhenExactMatch,
    options: options?.map(mapMenuOption),
  };
}

const homeOptions = [
  {
    children: "Models",
    to: "/all-models",
  },
  {
    children: "Commercial",
    to: "/commercial",
  },
  {
    children: "Kids",
    to: "/kids",
  },
];

const modelsOptions = [
  {
    children: "All models",
    to: "/all-models",
  },
  {
    children: "Image",
    to: "/image",
  },
  {
    children: "Main",
    to: "/models",
  },
  {
    children: "Development",
    to: "/development",
  },
  {
    children: "New faces",
    to: "/new-faces",
  },
];

const commercialOptions = [
  {
    children: "All commercial",
    to: "/all-commercial",
  },
  {
    children: "Athletes",
    to: "/athletes",
  },
  {
    children: "Curves",
    to: "/curves",
  },
];

const kidsOptions = [
  {
    children: "Babies",
    to: "/babies",
  },
  {
    children: "Kids",
    to: "/children",
  },
  {
    children: "Teens",
    to: "/teens",
  },
];

const menuOptions = [
  {
    children: "Models",
    activeWhenExactMatch: true,
    options: modelsOptions,
  },
  {
    children: "Commercial",
    to: "/commercial",
    activeWhenExactMatch: true,
    options: commercialOptions,
  },
  {
    children: "Kids",
    to: "/kids",
    activeWhenExactMatch: true,
    options: kidsOptions,
  },
  {
    children: "About us",
    to: "/about-us",
  },
  {
    children: "Contact us",
    to: "/contact-us",
  },
  {
    children: "Become a model",
    to: "/become-a-model",
  },
].map(mapMenuOption);

const headerHeight = 55;
const modelListIdCookieName = "modelListId";

const lastContentUpdateDateComparator = (
  model1: { lastContentUpdateDate: string },
  model2: { lastContentUpdateDate: string },
) =>
  new Date(model2.lastContentUpdateDate).getTime() -
  new Date(model1.lastContentUpdateDate).getTime();

export default function Main(): React.ReactElement {
  const notifications = useNotifications();
  const relayEnvironment = useRelayEnvironment();
  const modelListState = React.useState<{
    id?: string;
    modelIds?: string[];
  }>({});
  const [modelList, setModelList] = modelListState;
  const cookieManager = useCookieManager();
  const location = useLocation();
  React.useEffect(() => {
    if (!location.hash) window.scrollTo(0, 0);
  }, [location]);
  const createdModelListIdsContext = useCreatedModelListIdsContext();

  const modelListContext = React.useMemo(() => {
    const createNew = async () => {
      const modelListId = (
        await commitMutationPromise(relayEnvironment, {
          mutation: graphql`
            mutation MainCreateModelListMutation {
              createModelList {
                modelList {
                  id
                }
              }
            }
          `,
        })
      ).createModelList.modelList.id;
      setModelList({
        id: modelListId,
      });
      cookieManager.setCookie(modelListIdCookieName, modelListId);
      createdModelListIdsContext.add(modelListId);
      return modelListId;
    };

    return {
      id: modelList.id,
      modelIds: modelList.modelIds,
      addModel: (id: string) => {
        (async () => {
          try {
            const modelListId = modelList?.id || (await createNew());

            setModelList({
              ...modelList,
              id: modelListId,
              modelIds: [
                ...new Set(
                  modelList.modelIds ? [...modelList.modelIds, id] : [id],
                ),
              ],
            });

            commitMutationPromise(relayEnvironment, {
              mutation: graphql`
                mutation MainAddModelListModelMutation(
                  $input: AddModelListModelInput!
                ) {
                  addModelListModel(input: $input) {
                    modelList {
                      id
                    }
                  }
                }
              `,
              variables: { input: { modelListId, modelId: id } },
            });
          } catch (error) {
            errorLogger.log(error);
            notifications.addNotification({ error });
          }
        })();
      },
      removeModel: (id: string) => {
        if (modelList.modelIds)
          (async () => {
            try {
              setModelList({
                ...modelList,
                modelIds: modelList.modelIds.filter((someId) => someId !== id),
              });

              commitMutationPromise(relayEnvironment, {
                mutation: graphql`
                  mutation MainRemoveModelListModelMutation(
                    $input: RemoveModelListModelInput!
                  ) {
                    removeModelListModel(input: $input) {
                      modelList {
                        id
                      }
                    }
                  }
                `,
                variables: {
                  input: { modelListId: modelList.id, modelId: id },
                },
              });
            } catch (error) {
              errorLogger.log(error);
              notifications.addNotification({ error });
            }
          })();
      },
      load: async (id: string) => {
        try {
          setModelList({
            id,
            modelIds: (
              await fetchQuery(
                relayEnvironment,
                graphql`
                  query MainLoadModelListQuery($id: ID!) {
                    modelList(id: $id) {
                      models {
                        id
                      }
                    }
                  }
                `,
                { id },
              ).toPromise()
            ).modelList.models.map((model) => model.id),
          });
          cookieManager.setCookie(modelListIdCookieName, id);
        } catch (error) {
          errorLogger.log(error);
          notifications.addNotification({ error });
        }
      },
      createNew,
    };
  }, [modelList]);

  React.useEffect(() => {
    const modelListId = cookieManager.getCookie(modelListIdCookieName);
    if (modelListId && !modelListState[0]) modelListContext.load(modelListId);
  }, []);

  return (
    <>
      <Helmet titleTemplate="%s - Stockholmsgruppen" />

      <Global
        styles={{
          "html, body, #content": {
            height: "100%",
          },
        }}
      />

      <div
        css={{
          height: "100%",
          paddingTop: headerHeight,
          boxSizing: "border-box",
          "@media print": {
            paddingTop: 0,
          },
        }}
      >
        <ModelListContext.Provider value={modelListContext}>
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <Home
                  topButtons={homeOptions}
                  instagramId="249019224"
                  facebookUrl={config.facebookUrls.general}
                  instagramUrl={config.instagramUrls.general}
                  pageName=""
                />
              )}
            />

            <Route path="/log-in" component={LogIn} />
            <Route path="/become-a-model" component={BecomeAModel} />
            <Route path="/updates" component={Updates} />
            <Route path="/model-lists/:modelListId?" component={ModelList} />
            <Route path={config.healthCheckPath} component={Home} />

            <Route
              path="/all-models"
              render={() => (
                <Models
                  anyOfStatus={["Model", "Image", "New face", "Development"]}
                  statusOptions={modelsOptions}
                  hideAgeFilters
                  showLocationFilter
                  comparator={lastContentUpdateDateComparator}
                  pageName="all-models"
                />
              )}
            />
            <Route
              path="/models"
              render={() => (
                <Models
                  anyOfStatus={["Model"]}
                  statusOptions={modelsOptions}
                  hideAgeFilters
                  showLocationFilter
                  comparator={lastContentUpdateDateComparator}
                  pageName="models"
                />
              )}
            />
            <Route
              path="/image"
              render={() => (
                <Models
                  anyOfStatus={["Image"]}
                  statusOptions={modelsOptions}
                  hideAgeFilters
                  showLocationFilter
                  comparator={lastContentUpdateDateComparator}
                  pageName="image"
                />
              )}
            />
            <Route
              path="/development"
              render={() => (
                <Models
                  anyOfStatus={["Development"]}
                  statusOptions={modelsOptions}
                  hideAgeFilters
                  showLocationFilter
                  comparator={lastContentUpdateDateComparator}
                  pageName="development"
                />
              )}
            />
            <Route
              path="/new-faces"
              render={() => (
                <Models
                  anyOfStatus={["New face"]}
                  statusOptions={modelsOptions}
                  hideAgeFilters
                  showLocationFilter
                  comparator={lastContentUpdateDateComparator}
                  pageName="new-faces"
                />
              )}
            />

            <Route
              exact
              path="/commercial"
              render={() => (
                <Home
                  topButtons={commercialOptions}
                  instagramId="4865557459"
                  facebookUrl={config.facebookUrls.commercial}
                  instagramUrl={config.instagramUrls.commercial}
                  pageName="commercial"
                />
              )}
            />
            <Route
              path="/all-commercial"
              render={() => (
                <Models
                  anyOfStatus={["Commercial"]}
                  pageName="all-commercial"
                />
              )}
            />
            <Route
              path="/athletes"
              render={() => (
                <Models anyOfStatus={["Athletes"]} pageName="athletes" />
              )}
            />
            <Route
              path="/curves"
              render={() => (
                <Models anyOfStatus={["Curves"]} pageName="curves" />
              )}
            />

            <Route
              exact
              path="/kids"
              render={() => (
                <Home
                  titleColor="black"
                  topButtons={kidsOptions}
                  instagramId="1539517557"
                  facebookUrl={config.facebookUrls.kids}
                  instagramUrl={config.instagramUrls.kids}
                  pageName="kids"
                />
              )}
            />
            <Route
              path="/babies"
              render={() => (
                <Models anyOfStatus={["Babies"]} pageName="babies" />
              )}
            />
            <Route
              path="/children"
              render={() => (
                <Models anyOfStatus={["Child"]} pageName="children" />
              )}
            />
            <Route
              path="/teens"
              render={() => <Models anyOfStatus={["Teens"]} pageName="teens" />}
            />
            <Route path="/:pageName" component={Page} />
          </Switch>
        </ModelListContext.Provider>
      </div>

      <div
        css={{
          "@media print": { display: "none" },
          position: "fixed",
          top: 0,
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: headerHeight,
          background: "var(--background-color)",
          boxShadow: "0 0 4px 0 lightgrey",
        }}
      >
        <div
          css={{
            display: "flex",
            margin: "auto",
            width: "100%",
            maxWidth: "var(--max-width)",
            paddingLeft: "var(--padding-left)",
            paddingRight: "var(--padding-right)",
          }}
        >
          <div css={{ width: 100 }}>
            <Button
              type="naked"
              iconComponent={MenuIcon}
              hoverOptions={menuOptions}
              hoverContentMaxWidth={200}
            />
          </div>
          <Button
            type="naked"
            to="/"
            icon={<Logo height={30} />}
            css={{ marginLeft: "auto", marginRight: "auto" }}
          />
          <div css={{ width: 100, display: "flex" }}>
            <Button
              css={{ marginLeft: "auto" }}
              type="naked"
              iconComponent={HeartIcon}
              to={`/model-lists/${
                (modelList?.id && encodeURIComponent(modelList.id)) || ""
              }`}
            />
            <div
              css={{
                alignSelf: "flex-end",
                fontSize: 12,
              }}
            >
              {modelList.modelIds?.length || 0}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
