import * as React from "react";
import { Subtract } from "utility-types";
import { SafeAreaInsets } from "./safeAreaInsets";

export type AppMeasurementsContextValue = {
  width: number;
  height: number;
  safeAreaInsets: SafeAreaInsets;
};

const AppMeasurementsContext = React.createContext<
  AppMeasurementsContextValue | undefined
>();

export default AppMeasurementsContext;

export const useAppMeasurements = () =>
  React.useContext<AppMeasurementsContextValue | undefined>(
    AppMeasurementsContext
  );

type InjectedProps = {
  appMeasurements: AppMeasurementsContextValue;
};

export function withAppMeasurements<P extends InjectedProps>(
  Component: React.ComponentType<P>
): React.FC<Subtract<P, InjectedProps>> {
  return (props: Subtract<P, InjectedProps>) => {
    const appMeasurements = useAppMeasurements();
    return <Component appMeasurements={appMeasurements} {...(props as P)} />;
  };
}
