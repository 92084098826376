import * as React from "react";
import { Subtract } from "utility-types";

export type NotificationType = "info" | "error" | "warning";

export type Notification = {
  type?: NotificationType;
  message?: React.ReactElement;
  error?: any;
  autoHide?: boolean;
};

export type AddNotificationArguments = React.ReactNode | Notification;

export type NotificationsContextValue = {
  notifications: Notification[];
  addNotification: (notification: AddNotificationArguments) => Notification;
  dismissNotifications: (notifications: Notification[]) => void;
};

const NotificationsContext = React.createContext<NotificationsContextValue>();

export const useNotifications = () =>
  React.useContext<NotificationsContextValue>(NotificationsContext);

type InjectedProps = { browserIsNotSupported: boolean };

export function withNotifications<P extends InjectedProps>(
  Component: React.ComponentType<P>
): React.FC<Subtract<P, InjectedProps>> {
  return (props: Subtract<P, InjectedProps>) => {
    const notifications = useNotifications();
    return <Component notifications={notifications} {...(props as P)} />;
  };
}

export default NotificationsContext;
