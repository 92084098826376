import { useTheme } from "@emotion/react";
import * as React from "react";
import Field from "./Field.tsx";
import CheckIcon from "../icons/CheckIcon";

const requiredValidator = ({ value }: { value: any }) => !value && "Required";

export default function CheckboxField({
  label,
  placeholder,
  defaultValue,
  ...otherProps
}: {
  placeholder?: boolean;
} & React.ComponentPropsWithoutRef<typeof Field>): React.ReactElement {
  const theme = useTheme();

  return (
    <Field
      requiredValidator={requiredValidator}
      defaultValue={!!defaultValue}
      renderInput={({ value, onChange, readOnly }) => (
        <div
          css={{
            display: "flex",
            cursor: readOnly ? undefined : "pointer",
          }}
          onClick={readOnly ? undefined : () => onChange(!value)}
        >
          <div
            css={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              boxSizing: "border-box",
              marginTop: 3,
              width: "calc(var(--font-size) + 2px)",
              height: "calc(var(--font-size) + 2px)",
              borderStyle: "solid",
              borderColor:
                value && !readOnly
                  ? "var(--main-color)"
                  : "var(--separator-color)",
              borderWidth: 2,
              borderRadius: "var(--round-corner-border-radius)",
              background:
                (value && !readOnly && "var(--main-color)") ||
                ((placeholder || value) && "var(--separator-color)") ||
                undefined,
            }}
          >
            <CheckIcon
              css={{
                display: "block",
                margin: 1,
                visibility: value || placeholder ? "visible" : "hidden",
              }}
              height={theme.fontSize - 4}
              color="var(--background-color)"
            />
          </div>

          {label ? (
            <div
              css={{
                flex: 1,
                overflowY: "hidden",
                userSelect: "none",
                paddingLeft: Math.floor(theme.spacing / 2),
              }}
            >
              {label}
            </div>
          ) : null}
        </div>
      )}
      {...otherProps}
    />
  );
}
