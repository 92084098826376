import * as React from "react";
import ReactPlayer from "react-player/file";
import Button from "./Button.tsx";
import { paddedCss } from "./css.ts";

export default function VideoPlayer({
  url,
  width,
  height,
  autoPlay,
}: {
  url: string;
  width: number;
  height: number;
  autoPlay: boolean;
}) {
  const [playing, setPlaying] = React.useState(autoPlay);
  const togglePlay = React.useCallback(
    () => setPlaying((p) => !p),
    [setPlaying],
  );

  return (
    <div css={{ width, height, position: "relative" }}>
      <ReactPlayer
        url={url}
        width={width}
        height={height}
        css={{ position: "absolute", top: 0, left: 0 }}
        playing={playing}
        onEnded={() => setPlaying(false)}
      />
      <div
        onClick={togglePlay}
        css={{
          ...paddedCss,
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {!playing && (
          <Button size={1.5} onClick={togglePlay}>
            Play
          </Button>
        )}
      </div>
    </div>
  );
}
