import * as React from "react";
import LoadingIndicator from "./LoadingIndicator";

export default function CenteredLoadingIndicator(
  props: React.ComponentPropsWithoutRef<typeof LoadingIndicator>
): React.ReactElement {
  return (
    <div
      css={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingIndicator {...props} />
    </div>
  );
}
