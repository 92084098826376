import { Theme } from "@emotion/react";
import { paramCase } from "param-case";

export const themeVarsCss = (theme: Theme) =>
  Object.entries(theme).reduce((accumulator, currentValue) => {
    const [name, value] = currentValue;
    accumulator[`--${paramCase(name)}`] =
      typeof value === "number" && name !== "lineHeight" ? `${value}px` : value;
    return accumulator;
  }, {});

export const mainCss = {
  background: "var(--background-color)",
  fontFamily: "var(--font-family)",
  fontWeight: "normal",
  fontSize: "var(--font-size)",
  color: "var(--text-color)",
  lineHeight: "var(--line-height)",
};

const hCss = {
  color: "var(--header-color)",
  marginBottom: "var(--spacing)",
  fontWeight: "500",
};

export const h1Css = {
  ...hCss,
  fontSize: 44,
};

export const h2Css = {
  ...hCss,
  fontSize: 30,
};

export const h3Css = {
  ...hCss,
  fontSize: 24,
};

export const h4Css = {
  ...hCss,
  fontSize: 20,
};

const effectiveLineHeight = "calc(var(--font-size) * var(--line-height))";

export const pCss = {
  marginTop: effectiveLineHeight,
};

export const strongCss = {
  fontWeight: "500",
};

export const olCss = {
  marginTop: effectiveLineHeight,
  listStyleType: "decimal",
  paddingLeft: "calc(2 * var(--spacing))",
};

export const ulCss = {
  marginTop: effectiveLineHeight,
  listStyleType: "disc",
  paddingLeft: "calc(2 * var(--spacing))",
};

export const pageTitleCss = {
  ...hCss,
  fontSize: 28,
  marginTop: 0,
};

export const groupTitleCss = {
  color: "var(--header-color)",
  fontWeight: "500",
  fontSize: 17,
};

export const noteCss = {
  fontSize: "var(--small-font-size)",
  color: "var(--note-color)",
};

export const errorNoteCss = {
  color: "var(--error-color)",
  fontSize: "var(--small-font-size)",
};

export const aCss = {
  color: "var(--main-color)",
  textDecoration: "underline",
};

export const paddedCss = {
  paddingTop: "var(--padding-top)",
  paddingRight: "var(--padding-right)",
  paddingBottom: "var(--padding-bottom)",
  paddingLeft: "var(--padding-left)",
};

export const doublePaddedCss = {
  paddingTop: "calc(2 * var(--padding-top))",
  paddingRight: "calc(2 * var(--padding-right))",
  paddingBottom: "calc(2 * var(--padding-bottom))",
  paddingLeft: "calc(2 * var(--padding-left))",
};

export const roundedBorderedCss = ({
  width = 1,
  radius = "var(--round-corner-border-radius)",
  color = "var(--separator-color)",
}: {
  width?: number | string;
  radius?: number | string;
  color?: string;
} = {}) => ({
  borderStyle: "solid",
  borderWidth: width,
  borderRadius: radius,
  borderColor: color,
});
