import ExtendableError, { Options as ParentOptions } from "./ExtendableError";

export type Options = {
  userFriendlyMessage?: string;
} & ParentOptions;

export default class UserFriendlyMessageError extends ExtendableError {
  userFriendlyMessage?: string;

  constructor({
    message,
    userFriendlyMessage = message,
    ...otherOptions
  }: Options = {}) {
    super({ ...otherOptions, message });

    this.userFriendlyMessage = userFriendlyMessage;
  }
}
