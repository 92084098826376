import { useTheme } from "@emotion/react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import urljoin from "url-join";
import { VariableSizeList as List } from "react-window";
import { graphql, useFragment } from "react-relay";
import MeasuredDiv from "../../common/MeasuredDiv";
import ModelBox from "./ModelBox";
import WordPrefixMatcher from "../../common/WordPrefixMatcher";
import { locationMatches } from "./Model/Location";

function isSet(value: any) {
  return value !== null && value !== undefined;
}

function withinRange(
  searchFrom?: number | null,
  searchTo?: number | null,
  absolute?: number | null,
  min?: number | null,
  max?: number | null,
): boolean {
  const actualMin = min === null || min === undefined ? absolute : min;
  const actualMax = max === null || max === undefined ? absolute : max;

  return (
    (!isSet(searchFrom) || (isSet(actualMin) && searchFrom <= actualMax)) &&
    (!isSet(searchTo) || (isSet(actualMax) && searchTo >= actualMin))
  );
}

function getAge(dateString: string) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age -= 1;
  return age;
}

const heightPerWidth = 2560 / 1920;

const ModelRow = React.memo(
  ({ index, data: { rows, imageWidth, imageHeight }, style }) => {
    const history = useHistory();

    return (
      <div css={style}>
        <div
          css={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "var(--max-width)",
            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: "calc(2 * var(--spacing))",
            ">*": {
              cursor: "pointer",
            },
            ">*:not(:last-child)": {
              marginRight: "calc(2 * var(--spacing))",
            },
          }}
        >
          {rows[index - 1].map((model) => (
            <ModelBox
              key={model.id}
              model={model}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
              onClick={() => history.push(urljoin("/models", model.id))}
            />
          ))}
        </div>
      </div>
    );
  },
);

function Row(props) {
  const {
    index,
    data: { header, footer, rows },
  } = props;
  if (index === 0) return header(props) || null;
  if (index > rows.length) return footer(props) || null;
  return <ModelRow {...props} />;
}

export default function ModelGrid({
  models: modelsProp,
  header,
  footer,
  searchParameters = {},
  comparator,
  ...otherProps
}: {
  models: any[];
  header?: (props: { style?: any }) => React.ReactNode;
  footer?: (props: { style?: any }) => React.ReactNode;
  searchParameters: { [key: string]: any };
  comparator?: (
    model1: { publicName: string; lastContentUpdateDate: string },
    model2: { publicName: string; lastContentUpdateDate: string },
  ) => number;
}): React.ReactElement {
  const theme = useTheme();
  const [size, setSize] = React.useState<{ width: number; height: number }>();
  const [headerSize, setHeaderSize] = React.useState<{ height: number }>();
  const [footerSize, setFooterSize] = React.useState<{ height: number }>();
  const [modelRowSize, setModelRowSize] = React.useState<{ height: number }>();

  const models = useFragment(
    graphql`
      fragment ModelGrid_models on Model @relay(plural: true) {
        ...ModelBox_model
        id
        publicName
        birthDate
        male
        eyeColor
        shoeSize
        size
        minSize
        maxSize
        height
        hairColor
        location
        lastContentUpdateDate
      }
    `,
    modelsProp,
  );

  const itemData = React.useMemo(() => {
    if (!size) return undefined;

    const searchStringMatcher =
      searchParameters.searchString &&
      new WordPrefixMatcher(searchParameters.searchString);

    const filteredModels = models.filter(
      (model) =>
        (!searchParameters.male ||
          (searchParameters.male === "true") === model.male) &&
        (!searchStringMatcher ||
          searchStringMatcher.matches(model.publicName)) &&
        withinRange(
          searchParameters.fromSize,
          searchParameters.toSize,
          model.size,
          model.minSize,
          model.maxSize,
        ) &&
        withinRange(
          searchParameters.fromHeight,
          searchParameters.toHeight,
          model.height,
        ) &&
        withinRange(
          searchParameters.fromShoeSize,
          searchParameters.toShoeSize,
          model.shoeSize,
        ) &&
        withinRange(
          searchParameters.fromAge,
          searchParameters.toAge,
          model.birthDate && getAge(model.birthDate),
        ) &&
        (!searchParameters.hairColors ||
          searchParameters.hairColors.includes(model.hairColor)) &&
        (!searchParameters.eyeColors ||
          searchParameters.eyeColors.includes(model.eyeColor)) &&
        (!searchParameters.location ||
          locationMatches(searchParameters.location, model.location)),
    );

    if (comparator) filteredModels.sort(comparator);

    const availableWidth = Math.min(
      theme.maxWidth,
      size.width - theme.paddingLeft * 2 - theme.paddingRight * 2,
    );
    const idealImageWidth = Math.floor(
      (theme.maxWidth - theme.spacing * (3 * 2)) / 4,
    );
    const imagesPerRow = Math.floor(
      (availableWidth + theme.spacing * 2) / idealImageWidth,
    );
    const imageWidth = Math.floor(
      (availableWidth - theme.spacing * ((imagesPerRow - 1) * 2)) /
        imagesPerRow,
    );
    const imageHeight = Math.floor(heightPerWidth * imageWidth);

    const rows = [];
    let row;
    for (let { length } = filteredModels, i = 0; i < length; i += 1) {
      if (!row || row.length === imagesPerRow) {
        row = [];
        rows.push(row);
      }
      row.push(filteredModels[i]);
    }
    return {
      rows,
      imageWidth,
      imageHeight,
      header,
      footer,
    };
  }, [models, searchParameters, size, header, footer, comparator]);

  const itemSize = React.useCallback(
    (index) => {
      if (index === 0) return headerSize?.height || 0;
      if (index > itemData?.rows.length) return footerSize?.height || 0;
      return modelRowSize?.height;
    },
    [headerSize, modelRowSize, itemData],
  );

  return (
    <MeasuredDiv
      width
      height
      css={{ position: "relative" }}
      {...otherProps}
      onChange={setSize}
    >
      {size && (
        <>
          {header && (
            <MeasuredDiv
              height
              css={{
                position: "absolute",
                top: 0,
                width: size?.width,
                visibility: "hidden",
              }}
              onChange={setHeaderSize}
            >
              {header}
            </MeasuredDiv>
          )}

          {footer && (
            <MeasuredDiv
              height
              css={{
                position: "absolute",
                top: 0,
                width: size?.width,
                visibility: "hidden",
              }}
              onChange={setFooterSize}
            >
              {footer}
            </MeasuredDiv>
          )}

          {itemData.rows.length > 0 && (
            <MeasuredDiv
              height
              css={{
                position: "absolute",
                top: 0,
                width: size?.width,
                visibility: "hidden",
              }}
              onChange={setModelRowSize}
            >
              <ModelRow data={itemData} index={1} />
            </MeasuredDiv>
          )}

          {(headerSize || !header) &&
            (footerSize || !footer) &&
            (modelRowSize || !itemData?.rows.length) && (
              <List
                css={{ overflow: "hidden" }}
                width={size?.width}
                height={size?.height}
                itemCount={itemData.rows.length + (footer ? 2 : 1)}
                itemData={itemData}
                itemSize={itemSize}
                estimatedItemSize={modelRowSize?.height}
                overscanCount={5}
              >
                {Row}
              </List>
            )}
        </>
      )}
    </MeasuredDiv>
  );
}
