import * as React from "react";
import scrollIntoView from "scroll-into-view";
import { withForm } from "./FormContext";
import { noteCss, errorNoteCss } from "../css";
import { generateUniqueId } from "../unique-id";
import { validateRequired } from "../validators";
import shallowEqual from "../shallowEqual";
import deepEqual from "../deepEqual";
import isElementInViewport from "../isElementInViewport";
import { withFocusableParent } from "../FocusableParentContext";
import { FocusableParent, Focusable } from "../Focuser";

export const ValueSource = {
  DEFAULT_PROP: "DEFAULT_PROP",
  PROP: "PROP",
};

export type Value = any;
export type Validator = (options: {
  value: Value;
}) => string | false | undefined;

type OnChangeHandler = (options: {
  name?: string;
  value: Value;
  validationErrorMessages?: string[];
}) => void;

export type ChangeValueGetter = (
  obj: any,
  props: { oldValue?: Value }
) => Value;

type Props = {
  className?: string;
  name?: string | string[];
  label?:
    | React.ReactNode
    | ((options: {
        value: Value;
        onChange: (event: any) => void;
      }) => React.ReactNode);
  defaultValue?: Value;
  value?: Value;
  onChange?: OnChangeHandler;
  validators?: Validator[];
  required?: boolean;
  requiredValidator: Validator;
  renderInput: (
    options: {
      id?: number;
      onChange: (event: any) => void;
      onFocus: (event: any) => void;
      onBlur: (event: any) => void;
      value: Value;
      readOnly?: boolean;
    },
    values: {
      focused?: boolean;
      validationErrorMessages?: string[];
      focusNextField?: () => void;
    }
  ) => React.ReactNode;
  changeValueGetter: ChangeValueGetter;
  renderValue: (value: Value) => any;
  onFocus?: () => void;
  onBlur?: () => void;
  note?:
    | React.ReactNode
    | (({
        value,
        onChange,
      }: {
        value: Value;
        onChange: (event: any) => void;
      }) => React.ReactNode);
  focus?: () => void;
  focusRef?: React.Ref<() => void> | ((focus: () => void) => void);
  autoFocus?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  updateFormValue: boolean;
  valueComparator: (value1: Value, value2: Value) => boolean;
  focusableParent?: FocusableParent;
  focusableAutoFocus?: boolean;
  controls?: React.ReactNode;
};

type InternalProps = {
  form?: Form;
  field?: Field;
} & Props;

type State = {
  id: number;
  value?: Value;
  focused?: boolean;
  validationErrorMessages?: string[];
};

const propsShallowEqualOptions = {
  ignoreKeys: [
    "onChange",
    "changeValueGetter",
    "onFocus",
    "onBlur",
    "focus",
    "valueComparator",
  ],
};

const FieldContext = React.createContext<Field>(undefined!);

export const useField = () => React.useContext(FieldContext);

export function withField(Component: React.ComponentType<any>) {
  return (props: any) => {
    const field = useField();
    return <Component field={field} {...props} />;
  };
}

class Field extends React.Component<InternalProps, State> implements Focusable {
  static defaultProps = {
    requiredValidator: validateRequired,
    updateFormValue: true,
    valueComparator: deepEqual,
    changeValueGetter: (obj: any) => obj,
  };

  valueSource?: string;

  ref?: HTMLDivElement;

  childrenFields: Field[] = [];

  constructor(props: InternalProps) {
    super(props);

    const state = {
      id: generateUniqueId(),
    };

    const { value, defaultValue, focus, focusRef } = props;

    this.state =
      value !== undefined
        ? this.setValue({
            value,
            valueSource: ValueSource.PROP,
            returnStateInsteadOfSettingIt: { props, state },
            updateForm: false,
          })
        : this.setValue({
            value: defaultValue,
            valueSource: ValueSource.DEFAULT_PROP,
            returnStateInsteadOfSettingIt: { props, state },
            updateForm: false,
          });

    if (focusRef && focus) {
      if (typeof focusRef === "function") focusRef(focus);
      else focusRef.current = focus;
    }
  }

  componentDidMount() {
    const { name, form, updateFormValue, field, focusableParent } = this.props;
    const { value, validationErrorMessages } = this.state;

    form?.fieldDidMount(this);
    form?.onFieldChange({
      field: this,
      name,
      value,
      validationErrorMessages,
      valueSource: this.valueSource,
      updateValue: updateFormValue,
    });

    field?.childrenFieldDidMount(this);
    focusableParent?.addFocusable(this);
  }

  shouldComponentUpdate(nextProps: InternalProps, nextState: State) {
    const { value, defaultValue, ...otherProps } = this.props;
    const {
      value: nextValue,
      defaultValue: nextDefaultValue,
      ...otherNextProps
    } = nextProps;

    const { valueComparator } = otherNextProps;

    return (
      !shallowEqual(this.state, nextState) ||
      !shallowEqual(otherProps, otherNextProps, propsShallowEqualOptions) ||
      !valueComparator(value, nextValue) ||
      !valueComparator(defaultValue, nextDefaultValue)
    );
  }

  componentDidUpdate(prevProps: InternalProps) {
    const {
      name,
      value,
      defaultValue,
      required,
      validators,
      valueComparator,
      form,
      updateFormValue,
      focusRef,
      focus,
      readOnly,
    } = this.props;

    if (
      (name === undefined && prevProps.name !== undefined) ||
      (!updateFormValue && prevProps.updateFormValue)
    )
      form?.onFieldChange({ field: this, name: prevProps.name });

    let set = false;
    let newValue;
    let valueSource;

    if (value !== undefined) {
      if (!valueComparator(value, prevProps.value)) {
        set = true;
        newValue = value;
        valueSource = ValueSource.PROP;
      }
    } else if (defaultValue !== undefined) {
      if (
        !valueComparator(defaultValue, prevProps.defaultValue) &&
        this.valueSource === ValueSource.DEFAULT_PROP
      ) {
        set = true;
        newValue = defaultValue;
        valueSource = ValueSource.DEFAULT_PROP;
      }
    }

    if (
      newValue === undefined &&
      (required !== prevProps.required ||
        validators !== prevProps.validators ||
        updateFormValue !== prevProps.updateFormValue ||
        readOnly !== prevProps.readOnly)
    ) {
      set = true;
      newValue = this.state.value;
      valueSource = this.valueSource;
    }

    if (set) this.setValue({ value: newValue, valueSource });

    if (focusRef && prevProps.focus !== focus) {
      if (typeof focusRef === "function") focusRef(focus);
      else focusRef.current = focus;
    }
  }

  componentWillUnmount() {
    this.props.form?.fieldWillUnmount(this);
    this.props.field?.childrenFieldWillUnmount(this);
    this.props.focusableParent?.removeFocusable(this);
  }

  onInputChange = (event: any) => {
    const { readOnly, disabled, changeValueGetter } = this.props;

    if (readOnly || disabled) return false;
    if (event?.persist) event.persist();

    this.setValue({
      value: ({ value: oldValue }) => changeValueGetter(event, { oldValue }),
    });

    return true;
  };

  onFocus = () => {
    this.setState({ focused: true });
    if (this.props.onFocus) this.props.onFocus();
  };

  onBlur = () => {
    this.setState({ focused: false });
    if (this.props.onBlur) this.props.onBlur();
  };

  setRef = (ref?: HTMLDivElement) => {
    this.ref = ref;

    const { form } = this.props;
    if (form && form.setFieldRef) form.setFieldRef(this, ref);

    if (ref && ref.contains(document.activeElement)) this.onFocus(); // Browser might focus on server-rendered DOM element before react is loaded, which will cause onFocus not to be triggered
  };

  setValue({
    value,
    valueSource,
    returnStateInsteadOfSettingIt,
    updateForm = true,
  }: {
    value: Value | ((state: State) => Value);
    valueSource?: string;
    returnStateInsteadOfSettingIt?: { props: {}; state: {} };
    updateForm?: boolean;
  }) {
    const changeState = (state: State, props: InternalProps) => {
      this.valueSource = valueSource;

      const { required, requiredValidator, validators, readOnly } = props;

      const actualValue = typeof value === "function" ? value(state) : value;

      const validationErrorMessages: string[] = [];
      if (!readOnly) {
        const validate = (validator: Validator) => {
          const errorMessage = validator({ value: actualValue });
          if (errorMessage && !validationErrorMessages.includes(errorMessage))
            validationErrorMessages.push(errorMessage);
        };
        if (required) validate(requiredValidator);
        if (validationErrorMessages.length === 0 && validators)
          validators.forEach(validate);
      }

      const newState = {
        ...state,
        value: actualValue,
        validationErrorMessages:
          validationErrorMessages.length === 0
            ? undefined
            : validationErrorMessages,
      };

      return newState;
    };

    const afterStateChange = (state: State, props: InternalProps) => {
      const { onChange, name, form, updateFormValue } = props;
      const { value, validationErrorMessages } = state;

      if (updateForm)
        form?.onFieldChange({
          field: this,
          name,
          value,
          validationErrorMessages,
          valueSource,
          updateValue: updateFormValue,
        });

      if (
        onChange &&
        valueSource !== ValueSource.PROP &&
        valueSource !== ValueSource.DEFAULT_PROP
      )
        onChange({ name, value, validationErrorMessages });
    };

    if (returnStateInsteadOfSettingIt) {
      const { props, state } = returnStateInsteadOfSettingIt;
      const returnState = changeState(state, props);
      afterStateChange(returnState, props);
      return returnState;
    }

    this.setState(changeState, () => afterStateChange(this.state, this.props));
    return undefined;
  }

  reset = () => {
    const { value, defaultValue } = this.props;
    if (value !== undefined)
      this.setValue({ value, valueSource: ValueSource.PROP });
    else
      this.setValue({
        value: defaultValue,
        valueSource: ValueSource.DEFAULT_PROP,
      });
    this.childrenFields.forEach((field) => field.reset());
  };

  focusNextField = () => {
    if (this.props.form) {
      const fields = this.props.form.getFields();
      const index = fields.indexOf(this);
      if (index > -1 && index < fields.length - 1) {
        const field = fields[index + 1];
        if (field.focus) field.focus();
      }
    }
  };

  scrollIntoView = () => {
    if (this.ref && !isElementInViewport(this.ref)) scrollIntoView(this.ref);
  };

  autoFocus() {
    this.focus();
  }

  wantsAutoFocus() {
    return !!this.props.focusableAutoFocus;
  }

  focus() {
    if (this.props.focus) this.props.focus();
  }

  childrenFieldWillUnmount(field: Field) {
    const index = this.childrenFields.indexOf(field);
    if (index !== -1) this.childrenFields.splice(index, 1);
  }

  childrenFieldDidMount(field: Field) {
    this.childrenFields.push(field);
  }

  render() {
    const { className, label, renderInput, note, form, controls, renderValue } =
      this.props;
    const { id, value, validationErrorMessages } = this.state;

    const showValidationErrorMessages =
      form &&
      form.getShowValidationErrorMessages &&
      form.getShowValidationErrorMessages(this);

    const visibleValidationErrorMessages =
      showValidationErrorMessages &&
      validationErrorMessages &&
      validationErrorMessages.filter(
        (errorMessage) =>
          showValidationErrorMessages === true ||
          (Array.isArray(showValidationErrorMessages) &&
            showValidationErrorMessages.includes(errorMessage))
      );

    const readOnly =
      this.props.readOnly || (form?.isReadOnly && form.isReadOnly());
    const focused = this.state.focused && !readOnly;

    const input = renderInput(
      {
        id,
        onChange: this.onInputChange,
        onFocus: this.onFocus,
        onBlur: this.onBlur,
        value: renderValue ? renderValue(value) : value,
        readOnly,
      },
      {
        focused,
        validationErrorMessages: visibleValidationErrorMessages || undefined,
        focusNextField: this.focusNextField,
      }
    );

    return (
      <FieldContext.Provider value={this}>
        <div className={className} ref={this.setRef}>
          {label && (
            <label
              css={{
                display: "block",
                fontSize: "var(--small-font-size)",
                cursor: "default",
                userSelect: "none",
                marginBottom: "calc(3 * var(--size))",
              }}
              htmlFor={id}
            >
              {typeof label === "function"
                ? label({ value, onChange: this.onInputChange })
                : label}
            </label>
          )}

          {controls ? (
            <div css={{ display: "flex" }}>
              <div css={{ flex: 1, marginRight: "var(--spacing)" }}>
                {input}
              </div>
              {controls}
            </div>
          ) : (
            input
          )}

          {visibleValidationErrorMessages && (
            <div>
              {visibleValidationErrorMessages.map((errorMessage: string) => (
                <div
                  key={errorMessage}
                  css={{
                    ...errorNoteCss,
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {errorMessage}
                </div>
              ))}
            </div>
          )}

          {note && (
            <div
              css={(theme) => ({
                ...noteCss,
                marginTop: Math.floor(theme.spacing / 3),
              })}
            >
              {typeof note === "function"
                ? note({ value, onChange: this.onInputChange })
                : note}
            </div>
          )}
        </div>
      </FieldContext.Provider>
    );
  }
}

export default withFocusableParent(withField(withForm(Field)));
