import * as React from "react";
import { graphql } from "react-relay";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Theme } from "@emotion/react";
import SmartQueryRenderer from "../common/SmartQueryRenderer";
import NotFoundNotification from "../common/NotFoundNotification";
import { doublePaddedCss } from "../common/css";
import CommonPage from "../common/Page";
import Footer from "./common/Footer";

const mainCss = (theme: Theme) => ({
  ...doublePaddedCss,
  flex: 1,
  width: "100%",
  maxWidth: theme.maxWidth + theme.paddingLeft * 2 + theme.paddingRight * 2,
  margin: "auto",
  boxSizing: "border-box",
});

export default function Page(): React.ReactElement {
  const location = useLocation();

  return !location.pathname ? (
    <NotFoundNotification css={mainCss} />
  ) : (
    <>
      <SmartQueryRenderer
        query={graphql`
          query PageMainQuery($pageNamePath: [String!]!) {
            page(namePath: $pageNamePath) {
              ...Page_page
            }
          }
        `}
        variables={{ pageNamePath: location.pathname.substring(1).split("/") }}
        render={({ props: { page } }) =>
          !page ? (
            <NotFoundNotification css={mainCss} />
          ) : (
            <div css={mainCss}>
              <Helmet title={page.title} />
              <CommonPage page={page} />
            </div>
          )
        }
      />
      <Footer css={{ marginTop: "calc(2 * var(--spacing))" }} />
    </>
  );
}
