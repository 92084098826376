let scrollbarWidth: number | undefined;

export default function getScrollbarWidth(): number {
  if (scrollbarWidth !== undefined) return scrollbarWidth;
  if (!window) return 0;
  const { document } = window;

  const container = document.createElement("div");
  container.style.width = "50px";
  container.style.height = "50px";
  container.style.overflow = "scroll";
  container.style.position = "static";
  container.style.left = "-1000px";
  const child = document.createElement("div");
  child.style.width = "100px";
  child.style.height = "100px";
  container.appendChild(child);
  document.body.appendChild(container);

  scrollbarWidth = container.offsetWidth - container.clientWidth;
  container.parentNode?.removeChild(container);

  return scrollbarWidth;
}
