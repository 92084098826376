import * as React from "react";
import Link from "./Link";
import errorLogger from "./ErrorLogger";
import { noteCss } from "./css";
import { useBrowserIsNotSupported } from "./BrowserIsNotSupportedContext";
import config from "../config";
import getUserFriendlyErrorMessage from "./getUserFriendlyErrorMessage";
import nl2br from "./nl2br";

export default function ErrorMessage({
  error,
  ...otherProps
}: {
  error: any;
}): React.ReactElement {
  const userFriendlyMessage = error
    ? getUserFriendlyErrorMessage(error)
    : undefined;
  const browserIsNotSupported = useBrowserIsNotSupported();

  let content;

  if (userFriendlyMessage) {
    content = nl2br(userFriendlyMessage);
    const { cause } = error;
    if (cause?.userFriendlyMessage)
      content = (
        <>
          {content}
          <br />
          {nl2br(cause.userFriendlyMessage)}
        </>
      );
  } else {
    const reference = errorLogger.getReference(error);

    content = (
      <>
        <div>
          Something went wrong.
          <br />
          {browserIsNotSupported ? (
            <>
              You&apos;re using an unsupported browser, which may be the cause
              of this error. Please{" "}
              <Link href="https://browser-update.org/update-browser.html">
                update your browser
              </Link>
              .
            </>
          ) : (
            "This could be a bug and probably something you can't do much about."
          )}
        </div>
        {!reference ? (
          `E-mail ${config.supportEmailAddress} if this is a problem.`
        ) : (
          <>
            We have been notified, but feel free to e-mail{" "}
            <Link href={`mailto:${config.supportEmailAddress}`}>
              {config.supportEmailAddress}
            </Link>{" "}
            with the reference below:
            <div
              css={{
                ...noteCss,
                marginTop: "var(--spacing)",
              }}
            >
              {reference}
            </div>
          </>
        )}
      </>
    );
  }

  return <div {...otherProps}>{content}</div>;
}
