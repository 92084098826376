import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "react-relay";
import { useHistory, useRouteMatch } from "react-router-dom";
import ModelGrid from "./common/ModelGrid";
import Footer from "./common/Footer";
import { useModelList } from "./common/ModelListContext";
import SmartQueryRenderer from "../common/SmartQueryRenderer";
import { h2Css } from "../common/css";
import Button from "../common/Button.tsx";
import errorLogger from "../common/ErrorLogger";
import { useCreatedModelListIdsContext } from "./common/CreatedModelListIdsContext";

export default function ModelList(): React.ReactElement {
  const {
    params: { modelListId },
  } = useRouteMatch();
  const history = useHistory();
  const modelList = useModelList();
  const createdModelListIdsContext = useCreatedModelListIdsContext();

  React.useEffect(() => {
    if (modelListId) modelList?.load(modelListId);
  }, [modelListId]);

  const header = React.useCallback(({ style }) => {
    return (
      <div css={style}>
        <div
          css={{
            paddingTop: "calc(2 * var(--padding-top))",
            paddingRight: "calc(2 * var(--padding-right))",
            paddingBottom: "calc(3 * var(--spacing))",
            paddingLeft: "calc(2 * var(--padding-left))",
            maxWidth: "var(--max-width)",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div css={{ textAlign: "center" }}>
            <div css={h2Css}>Favorites</div>
            {createdModelListIdsContext.get().includes(modelListId) && (
              <>
                Here is a list of your favorites, copy the link or download
                setcards and contact our bookers.
              </>
            )}
          </div>

          {modelListId && (
            <div
              css={{
                marginTop: "calc(2 * var(--spacing))",
                display: "flex",
                justifyContent: "center",
                ">*:not(:last-child)": {
                  marginRight: "var(--spacing)",
                },
              }}
            >
              <Button
                type="secondary"
                onClick={() => {
                  modelList.createNew();
                  history.push("/");
                }}
              >
                Create new list
              </Button>
              <Button
                type="secondary"
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(window.location.href);
                  } catch (error) {
                    errorLogger.log(error);
                  }
                }}
              >
                Copy URL
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }, []);

  const footer = React.useCallback(({ style }) => <Footer style={style} />, []);

  return (
    <>
      <Helmet title="Favorites" />

      <SmartQueryRenderer
        keepOldDataBetweenLoads
        query={graphql`
          query ModelListQuery($ids: [ID!]!) {
            models(anyOfIds: $ids) {
              ...ModelGrid_models
            }
          }
        `}
        variables={{ ids: modelList?.modelIds || [] }}
        render={({ props }) => {
          return (
            <ModelGrid
              css={{ width: "100%", height: "100%" }}
              models={props.models}
              header={header}
              footer={footer}
            />
          );
        }}
      />
    </>
  );
}
