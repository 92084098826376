import * as React from "react";
import Svg from "./Svg";

function LoadingIndicator({
  showDelay,
  color,
  size = 40,
  ...otherProps
}: {
  showDelay?: number;
  color?: string;
  size?: number;
}): React.ReactElement {
  const [show, setShow] = React.useState(showDelay === 0);

  React.useEffect(() => {
    if (!show) {
      const timeoutRef = setTimeout(() => setShow(true), showDelay);
      return () => clearTimeout(timeoutRef);
    }
  }, []);

  return (
    <div
      css={{
        width: size,
        maxWidth: "100%",
        height: size,
        maxHeight: "100%",
      }}
      {...otherProps}
    >
      <Svg
        css={{
          width: "100%",
          maxWidth: size,
          height: "100%",
          maxHeight: size,
          visibility: show ? "inherit" : "hidden",
        }}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke={color || "var(--separator-color)"}
          strokeWidth="10"
          r="40"
          strokeDasharray="164.93361431346415 56.97787143782138"
          transform="rotate(17.769 50 50)"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
      </Svg>
    </div>
  );
}

LoadingIndicator.defaultProps = {
  showDelay: 1000,
};

export default LoadingIndicator;
