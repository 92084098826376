/**
 * @generated SignedSource<<af00ad7f74e2346dceb93a3b52fe9bb2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type getModelDetails_model$data = {
  readonly eyeColor: string | null | undefined;
  readonly hairColor: string | null | undefined;
  readonly height: number | null | undefined;
  readonly location: string | null | undefined;
  readonly maxSize: number | null | undefined;
  readonly minSize: number | null | undefined;
  readonly shoeSize: number | null | undefined;
  readonly size: number | null | undefined;
  readonly " $fragmentType": "getModelDetails_model";
};
export type getModelDetails_model$key = {
  readonly " $data"?: getModelDetails_model$data;
  readonly " $fragmentSpreads": FragmentRefs<"getModelDetails_model">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "getModelDetails_model",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eyeColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shoeSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hairColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    }
  ],
  "type": "Model",
  "abstractKey": null
};

(node as any).hash = "306e470d9f6a286d45002f66d96c4243";

export default node;
