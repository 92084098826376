import { Environment, Network, RecordSource, Store } from "relay-runtime";
import fetchGraphql from "./fetchGraphql";
import errorLogger from "./ErrorLogger";
import { Auth } from "./authenticateWithServer";

export default function createRelayEnvironment({
  getAuth,
}: {
  getAuth?: () => Auth | undefined;
} = {}): Environment {
  function fetchQuery(operation, variables, cacheConfig, uploadables) {
    const request = {};
    request.headers = {};

    const stateAuth = getAuth && getAuth();
    if (stateAuth) request.headers.authorization = `Basic ${stateAuth.auth}`;

    if (uploadables) {
      if (!global.FormData)
        throw new Error("Uploading files without `FormData` not supported.");

      const formData = new FormData();
      formData.append("query", operation.text);
      formData.append("variables", JSON.stringify(variables));

      Object.keys(uploadables).forEach((name) => {
        if (Array.isArray(uploadables[name])) {
          uploadables[name].forEach((file) => {
            if (file) formData.append(name, file);
          });
        } else if (uploadables[name]) formData.append(name, uploadables[name]);
      });

      request.body = formData;
    } else {
      request.body = {
        query: operation.text,
        variables,
      };
    }

    const promise = fetchGraphql(request);

    // We need to detect errors here because otherwise QueryRenderer does not render partial errors.
    if (operation.operationKind === "query")
      return promise.then((json) => {
        if (json.errors) errorLogger.log(json.errors);
        return json;
      });

    return promise;
  }

  return new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
  });
}
