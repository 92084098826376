import * as React from "react";
import { matchPath, useLocation } from "react-router-dom";
import color from "color";
import omit from "object.omit";
import composeRefs from "@seznam/compose-react-refs";
import { useTheme } from "@emotion/react";
import LoadingIndicator from "./LoadingIndicator";
import Link from "./Link";
import colorToHex from "./colorToHex";
import errorLogger from "./ErrorLogger";
import isPromise from "./isPromise";
import HoverBox, { HoverContent } from "./HoverBox";
import { useNotifications } from "./NotificationsContext";
import useMountedRef from "./useMountedRef";

type Type =
  | "primary"
  | "alternative"
  | "secondary"
  | "danger"
  | "naked"
  | "tab"
  | "discrete";

type Props = {
  children?:
    | React.ReactNode
    | ((options: { color: string }) => React.ReactNode);
  size?: number | "small";
  target?: string;
  type?: Type;
  disabled?: boolean;
  loading?: boolean;
  to?: string;
  href?: string;
  active?: boolean;
  activeWhenMatch?: boolean;
  activeWhenExactMatch?: boolean;
  icon?: React.ReactElement;
  iconComponent?: React.ComponentType<any>;
  iconPosition?: "left" | "right";
  onClick?: () => Promise<any> | void;
  onMouseDown?: (() => Promise<any> | void) | boolean;
  hoverContent?: HoverContent;
  hoverContentMaxWidth?: number;
  hoverOptions?: Props[];
  hoverBoxRef?: typeof HoverBox;
  labelAlign?: "left" | "right" | "center";
  options?: Props[];
  stopEventPropagation?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export function getKey({ children, to, href }: Props): string {
  if (children !== undefined) return `${children}`;
  if (to !== undefined) return JSON.stringify(to);
  return href || "";
}

function getPath(to: string) {
  const searchIndex = to?.indexOf("?");
  return searchIndex > 0 ? to.substring(0, searchIndex) : to;
}

const Button = React.forwardRef((props: Props, ref) => {
  const {
    activeWhenMatch,
    activeWhenExactMatch,
    to,
    type = "primary",
    children,
    size: propsSize = 1,
    href,
    loading: propsLoading,
    icon,
    iconComponent: IconComponent,
    iconPosition = "left",
    labelAlign,
    active: propsActive,
    onClick,
    onMouseDown,
    disabled,
    options,
    stopEventPropagation,
    ...otherProps
  } = props;

  const theme = useTheme();
  const [stateLoading, setStateLoading] = React.useState<boolean>();
  const [hoverOptionsKeyPath, setHoverOptionsKeyPath] = React.useState<
    string[]
  >([]);
  const location = useLocation();
  const notifications = useNotifications();
  const mountedRef = useMountedRef();
  const loading = propsLoading || stateLoading;
  const size =
    propsSize === "small" ? theme.smallFontSize / theme.fontSize : propsSize; // So that the "small" size makes the button text same size as theme.smallFontSize

  const [showOptions, setShowOptions] = React.useState(
    (to &&
      (activeWhenMatch || activeWhenExactMatch) &&
      matchPath(location.pathname, {
        path: getPath(to),
        exact: activeWhenExactMatch,
      })) ||
      options?.some(
        (option) =>
          (option.activeWhenMatch || option.activeWhenExactMatch) &&
          option.to &&
          matchPath(location.pathname, {
            path: getPath(option.to),
            exact: option.activeWhenExactMatch,
          })
      )
  );

  // Since react by default adds passive event listeners, and only active touch events support preventDefault(). Also react seems to trigger onMouseDown on an element that appears under the mouse even if that element wasn't there when the press started
  const innerRefCleanupRef = React.useRef<() => void>();
  const innerRef = React.useCallback(
    (actualRef: HTMLElement) => {
      if (
        (onClick || typeof onMouseDown === "function" || options) &&
        !loading
      ) {
        if (innerRefCleanupRef.current) {
          innerRefCleanupRef.current();
          innerRefCleanupRef.current = undefined;
        }

        if (actualRef) {
          const doAction = async (event: MouseEvent | TouchEvent) => {
            event.preventDefault();
            if (stopEventPropagation) event.stopPropagation();
            if (disabled) return;

            try {
              if (options) setShowOptions((show: boolean) => !show);
              else {
                const retVal =
                  (onClick && onClick()) ||
                  (typeof onMouseDown === "function" && onMouseDown()) ||
                  undefined;
                if (isPromise(retVal)) {
                  setStateLoading(true);
                  await retVal;
                }
              }
            } catch (error) {
              notifications.addNotification({ error: errorLogger.log(error) });
            } finally {
              if (mountedRef.current) setStateLoading(false);
            }
          };

          if (onMouseDown) {
            actualRef.addEventListener("touchstart", doAction, {
              passive: false,
            });
            actualRef.addEventListener("mousedown", doAction);
          } else actualRef.addEventListener("click", doAction);

          innerRefCleanupRef.current = () => {
            actualRef.removeEventListener("touchstart", doAction);
            actualRef.removeEventListener("mousedown", doAction);
            actualRef.removeEventListener("click", doAction);
          };
        }
      }
      return undefined;
    },
    [onClick, onMouseDown, loading, disabled]
  );

  // Hover stuff
  let actualHoverContent;
  {
    const {
      hoverContent,
      hoverOptions,
      hoverBoxRef,
      hoverContentMaxWidth,
      ...passDownProps
    } = props;

    actualHoverContent = hoverContent;

    if (hoverOptions) {
      const hoverOption = hoverOptionsKeyPath.reduce(
        (accumulator, key) =>
          accumulator.hoverOptions?.find((option) => getKey(option) === key),
        { hoverOptions }
      );

      actualHoverContent =
        hoverOption.hoverContent ||
        (({ fullscreen, safeAreaInsets, hideHoverContent }) => (
          <div
            css={{
              width: "100%",
              height: "100%",
              display: "flex",
              overflow: "auto",
            }}
          >
            <div
              css={{
                width: fullscreen ? undefined : "100%",
                paddingTop: Math.floor(theme.spacing / 2) + safeAreaInsets.top,
                paddingBottom:
                  Math.floor(theme.spacing / 2) + safeAreaInsets.bottom,
                margin: fullscreen ? "auto" : undefined,
              }}
            >
              {hoverOption.hoverOptions.map((option) => {
                const {
                  hoverContent: optionHoverContent,
                  hoverOptions: optionHoverOptions,
                  onClick: optionOnClick,
                  options: optionOptions,
                  ...otherButtonProps
                } = option;

                const key = getKey(option);

                return (
                  <Button
                    key={key}
                    css={{
                      display: "block",
                      paddingTop: Math.floor(theme.spacing / 2),
                      paddingRight: theme.spacing + safeAreaInsets.right,
                      paddingBottom: Math.floor(theme.spacing / 2),
                      paddingLeft: theme.spacing + safeAreaInsets.left,
                    }}
                    labelAlign="left"
                    type="discrete"
                    onClick={async () => {
                      if (optionHoverContent || optionHoverOptions)
                        setHoverOptionsKeyPath([...hoverOptionsKeyPath, key]);
                      else {
                        if (optionOnClick) await optionOnClick();
                        hideHoverContent();
                      }
                    }}
                    options={optionOptions?.map(
                      ({ optionOptionOnClick, ...otherOptionOptionProps }) => ({
                        ...otherOptionOptionProps,
                        onClick: () => {
                          if (optionOptionOnClick) optionOptionOnClick();
                          hideHoverContent();
                        },
                      })
                    )}
                    {...otherButtonProps}
                  />
                );
              })}
            </div>
          </div>
        ));
    }

    if (actualHoverContent) {
      return (
        <HoverBox
          hoverBoxRef={hoverBoxRef}
          hoverContent={actualHoverContent}
          hoverContentMaxWidth={hoverContentMaxWidth}
          onHoverContentDidHide={() => setHoverOptionsKeyPath([])}
        >
          {({ ref: hoverBoxRef, toggleHoverContent }) => (
            <Button
              {...passDownProps}
              ref={ref ? composeRefs(hoverBoxRef, ref) : hoverBoxRef}
              onClick={
                onClick
                  ? () => {
                      toggleHoverContent();
                      onClick();
                    }
                  : toggleHoverContent
              }
            />
          )}
        </HoverBox>
      );
    }
  }

  const matchActive =
    to &&
    (activeWhenMatch || activeWhenExactMatch) &&
    matchPath(location.pathname, {
      path: getPath(to),
      exact: activeWhenExactMatch,
    });

  const active = propsActive || matchActive;

  let mainColorColor = color(theme.mainColor);
  if (disabled)
    mainColorColor = color(theme.backgroundColor)
      .mix(mainColorColor, 0.3)
      .grayscale();
  const mainColor = colorToHex(mainColorColor);

  let mainCss: { [key: string]: any } = {
    boxSizing: "border-box",
    display: "inline-block",
    verticalAlign: "middle", // Removes descender gap below inline-block
    cursor: disabled ? "default" : "pointer",
    borderRadius: Math.floor(theme.roundCornerBorderRadius * size),
    fontWeight: 400,
    fontSize: Math.floor(theme.fontSize * size),
    textDecoration: "none",
    userSelect: "none",
    lineHeight: theme.lineHeight * size,
  };

  const labelCss: { [key: string]: any } = {};

  if (type === "primary") {
    mainCss = {
      ...mainCss,
      background: mainColor,
      border: 0,
      paddingTop: Math.floor(6 * size),
      paddingRight: Math.floor(12 * size),
      paddingBottom: Math.floor(6 * size),
      paddingLeft: Math.floor(12 * size),
    };
    labelCss.color = "var(--background-color)";
  } else if (type === "alternative") {
    mainCss = {
      ...mainCss,
      background: "var(--background-color)",
      borderWidth: Math.floor(size),
      borderStyle: "solid",
      borderColor: disabled ? "var(--separator-color)" : "var(--main-color)",
      paddingTop: Math.floor(5 * size),
      paddingRight: Math.floor(12 * size),
      paddingBottom: Math.floor(5 * size),
      paddingLeft: Math.floor(12 * size),
    };
    labelCss.color = mainColor;
  } else if (type === "danger") {
    mainCss = {
      ...mainCss,
      background: "var(--error-color)",
      border: 0,
      paddingTop: Math.floor(6 * size),
      paddingRight: Math.floor(12 * size),
      paddingBottom: Math.floor(6 * size),
      paddingLeft: Math.floor(12 * size),
    };
    labelCss.color = "var(--background-color)";
  } else if (type === "secondary") {
    mainCss = {
      ...mainCss,
      background: "var(--background-color)",
      borderWidth: Math.floor(size),
      borderStyle: "solid",
      borderColor: "var(--separator-color)",
      paddingTop: Math.floor(5 * size),
      paddingRight: Math.floor(12 * size),
      paddingBottom: Math.floor(5 * size),
      paddingLeft: Math.floor(12 * size),
    };
    labelCss.color = colorToHex(
      color(theme.textColor).mix(color(theme.backgroundColor), 0.5)
    );
  } else if (type === "naked") {
    mainCss = {
      ...mainCss,
      background: "transparent",
      border: 0,
    };
    labelCss.color = mainColor;
  } else if (type === "tab") {
    mainCss = {
      ...mainCss,
      background: "transparent",
      border: 0,
    };
    labelCss.color = active
      ? "var(--text-color)"
      : colorToHex(
          color(theme.textColor).mix(color(theme.backgroundColor), 0.5)
        );
    labelCss.borderBottom = `2px solid ${
      active ? "var(--main-color)" : "transparent"
    }`;
  } else if (type === "discrete") {
    mainCss = {
      ...mainCss,
      background: "transparent",
      border: 0,
    };
    labelCss.color =
      (active && "var(--main-color)") ||
      (disabled &&
        colorToHex(
          color(theme.textColor).mix(color(theme.backgroundColor), 0.8)
        )) ||
      "var(--note-color)";
  }

  if (loading) labelCss.visibility = "hidden";

  const actualIcon =
    icon ||
    (IconComponent && (
      <IconComponent height={theme.iconSize * size} color={labelCss.color} />
    ));
  const actualChildren =
    typeof children === "function"
      ? children({ color: labelCss.color })
      : children;
  const iconIsLeft = iconPosition === "left";

  const label = (
    <div
      css={{
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <div
        css={{
          ...labelCss,
          [(labelAlign === "left" && "marginRight") ||
          (labelAlign === "right" && "marginLeft") ||
          "margin"]: "auto",
          minHeight: mainCss.fontSize * mainCss.lineHeight,
          display: "flex",
          flexWrap: "nowrap",
          whiteSpace: "nowrap",
          flexDirection: iconIsLeft ? "row" : "row-reverse",
        }}
      >
        {actualIcon && (
          <div
            css={{
              [`margin${iconIsLeft ? "Right" : "Left"}`]: children ? 4 : 0,
              ">*": { display: "block" },
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            {actualIcon}
          </div>
        )}
        {actualChildren && <div css={{ margin: "auto" }}>{actualChildren}</div>}
      </div>

      {/* Increase hit area, especially for naked buttons */}
      <div
        css={{
          position: "absolute",
          top: Math.floor(-theme.spacing / 2),
          right: Math.floor(-theme.spacing / 2),
          bottom: Math.floor(-theme.spacing / 2),
          left: Math.floor(-theme.spacing / 2),
          padding: Math.floor(-theme.spacing / 2),
        }}
      />

      {loading && (
        <div
          css={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
          }}
        >
          <LoadingIndicator
            size={Math.floor(size * 20)}
            css={{ margin: "auto" }}
            color={labelCss.color}
            showDelay={0}
          />
        </div>
      )}
    </div>
  );

  const passDownProps = {
    role: "button",
    ...omit(otherProps, [
      "hoverContent",
      "hoverOptions",
      "hoverBoxRef",
      "hoverContentMaxWidth",
    ]),
    css: mainCss,
    tabIndex: 0,
  };

  return (
    <>
      {to || href ? (
        <Link
          {...passDownProps}
          ref={ref}
          onMouseDown={onMouseDown === true || undefined}
          onClick={onClick}
          to={to}
          href={href}
        >
          {label}
        </Link>
      ) : (
        <div
          {...passDownProps}
          ref={ref ? composeRefs(ref, innerRef) : innerRef}
        >
          {label}
        </div>
      )}
      {showOptions &&
        options?.map((option) => (
          <Button
            key={getKey(option)}
            type={type}
            css={{
              display: "block",
              paddingLeft: Math.floor(theme.spacing / 2),
              paddingTop: Math.floor(theme.spacing / 2),
              paddingBottom: Math.floor(theme.spacing / 2),
              marginLeft: theme.spacing,
            }}
            className={otherProps.className}
            labelAlign={labelAlign}
            {...option}
          />
        ))}
    </>
  );
});

export default Button;
