import * as React from "react";
import {
  fetchQuery,
  graphql,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { paddedCss, strongCss } from "../../common/css";
import config from "../../config";
import getModelDetails from "../Models/common/getModelDetails";
import { ModelBox_model$key } from "./__generated__/ModelBox_model.graphql";
import { ModelBoxImagesQuery } from "./__generated__/ModelBoxImagesQuery.graphql";
import Button from "../../common/Button.tsx";
import LeftArrowIcon from "../../common/icons/LeftArrowIcon";
import RightArrowIcon from "../../common/icons/RightArrowIcon";
import { useModelList } from "./ModelListContext";
import HeartIcon from "../../common/icons/HeartIcon";

export default React.memo(function ModelBox({
  model: modelProp,
  imageWidth,
  imageHeight,
  ...otherProps
}: {
  imageWidth: number;
  imageHeight: number;
  model: ModelBox_model$key;
} & React.HTMLAttributes<HTMLDivElement>): React.ReactElement {
  const relayEnvironment = useRelayEnvironment();
  const index = React.useRef(0);
  const [indexState, setIndexState] = React.useState(index.current);
  const loadedAllImages = React.useRef(false);
  const [images, setImages] = React.useState<{ id: string }>();
  const modelList = useModelList();

  const model = useFragment(
    graphql`
      fragment ModelBox_model on Model {
        ...getModelDetails_model @relay(mask: false)
        id
        publicName
        images(limit: 2) {
          id
        }
      }
    `,
    modelProp,
  );

  const details = getModelDetails(model);
  const actualImages = images || model.images;

  const loadImagesIfNeeded = React.useCallback(() => {
    if (!loadedAllImages.current) {
      loadedAllImages.current = true;

      fetchQuery<ModelBoxImagesQuery>(
        relayEnvironment,
        graphql`
          query ModelBoxImagesQuery($id: ID!) {
            model(id: $id) {
              images {
                id
              }
            }
          }
        `,
        {
          id: model.id,
        },
      ).subscribe({
        next: (data) => setImages(data.model.images),
      });
    }
  }, []);

  const [props, api] = useSpring(() => ({ transform: "translate3d(0px,0,0)" }));

  const bind = useDrag(
    ({ active, movement: [mx], direction: [xDir], cancel, last }) => {
      loadImagesIfNeeded();

      if (active && Math.abs(mx) > imageWidth / 2) {
        index.current = Math.min(
          actualImages.length - 1,
          Math.max(0, index.current + (xDir > 0 ? -1 : 1)),
        );
        cancel();
      }

      api.start({
        transform: `translate3d(${
          -index.current * imageWidth + (active ? mx : 0)
        }px,0,0)`,
      });

      if (last) setIndexState(index.current);
    },
  );

  const goToImage = React.useCallback(
    (toIndex: number) => {
      index.current = toIndex;
      setIndexState(toIndex);

      api.start({
        transform: `translate3d(${-index.current * imageWidth}px,0,0)`,
      });
    },
    [actualImages],
  );

  return (
    <div
      {...otherProps}
      onMouseOver={loadImagesIfNeeded}
      onFocus={loadImagesIfNeeded}
    >
      <div
        css={{
          touchAction: "pan-y",
          width: imageWidth,
          height: imageHeight,
          overflow: "hidden",
          position: "relative",
          whiteSpace: "nowrap",
        }}
        {...bind()}
      >
        <animated.div style={props}>
          {actualImages.map((image) => (
            <div
              key={image.id}
              css={{
                display: "inline-block",
                width: imageWidth,
                height: imageHeight,
                background: `url(${config.files.imgixBaseUrl}${image.id}?fit=crop&w=${imageWidth}&h=${imageHeight})`,
              }}
            />
          ))}
        </animated.div>

        <div
          css={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            opacity: 0,
            "&:hover": { opacity: 1 },
            transition: "opacity .1s ease-in-out",
          }}
        >
          {details.length > 0 && (
            <div
              css={{
                ...paddedCss,
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                background: "rgba(255, 255, 255, .9)",
                fontSize: "var(--small-font-size)",
                display: "flex",
                flexWrap: "wrap",
                ">*": {
                  whiteSpace: "nowrap",
                  width: "50%",
                  boxSizing: "border-box",
                  "&:not(:last-child)": {
                    paddingRight: "var(--spacing)",
                  },
                },
              }}
            >
              {details.map((detail) => (
                <div key={detail.name}>
                  <span css={strongCss}>{detail.name}:</span> {detail.value}
                </div>
              ))}
            </div>
          )}

          <Button
            type="naked"
            icon={
              <LeftArrowIcon
                height="calc(1.5 * var(--icon-size))"
                color={!indexState ? "var(--note-color)" : "white"}
              />
            }
            css={{
              padding: 10,
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translate(0, -50%)",
            }}
            onClick={() => goToImage(index.current - 1)}
            stopEventPropagation
            disabled={!indexState}
          />

          <Button
            type="naked"
            icon={
              <RightArrowIcon
                height="calc(1.5 * var(--icon-size))"
                color={
                  indexState >= actualImages.length - 1
                    ? "var(--note-color)"
                    : "white"
                }
              />
            }
            css={{
              padding: 10,
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translate(0, -50%)",
            }}
            onClick={() => goToImage(index.current + 1)}
            stopEventPropagation
            disabled={indexState >= actualImages.length - 1}
          />

          <Button
            type="naked"
            icon={
              <HeartIcon
                height="calc(1.4 * var(--icon-size))"
                color="white"
                solid={modelList.modelIds?.includes(model.id)}
              />
            }
            css={{
              padding: 10,
              position: "absolute",
              right: 0,
              top: 0,
            }}
            onClick={() =>
              modelList.modelIds?.includes(model.id)
                ? modelList.removeModel(model.id)
                : modelList.addModel(model.id)
            }
            stopEventPropagation
          />
        </div>
      </div>
      <div
        css={{
          marginTop: "calc(var(--spacing) / 2)",
          textAlign: "center",
        }}
      >
        {model.publicName}
      </div>
    </div>
  );
});
