import * as React from "react";
import { Subtract } from "utility-types";
import { FocusableParent } from "./Focuser";

const FocusableParentContext = React.createContext<FocusableParent>();

export const useFocusableParent = () =>
  React.useContext<FocusableParent>(FocusableParentContext);

type InjectedProps = { focusableParent: FocusableParent };

export function withFocusableParent<P extends InjectedProps>(
  Component: React.ComponentType<P>
): React.FC<Subtract<P, InjectedProps>> {
  return (props: Subtract<P, InjectedProps>) => {
    const focusableParent = useFocusableParent();
    return <Component focusableParent={focusableParent} {...(props as P)} />;
  };
}

export default FocusableParentContext;
