import * as React from "react";
import { hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/browser";
import App from "./App";
import errorLogger from "./common/ErrorLogger";
import initErrorLogger from "./common/initErrorLogger";

initErrorLogger({ sentry: Sentry, breadCrumbs: true });

// Temporary fix for google translate breaking react https://bugs.chromium.org/p/chromium/issues/detail?id=872770
// Taken from https://github.com/facebook/react/issues/11538
if (typeof Node === "function" && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error(
          "Cannot remove a child from a different parent",
          child,
          this
        );
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  };
  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          "Cannot insert before a reference node from a different parent",
          referenceNode,
          this
        );
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  };
}

try {
  hydrateRoot(
    document.getElementById("content"),
    <BrowserRouter>
      <App
        cookieManager={{
          getCookie: (name: string) => Cookies.get(name),
          setCookie: (name: string, value: string) =>
            Cookies.set(name, value, { expires: 365 }),
          removeCookie: (name: string) => Cookies.remove(name),
        }}
        userAgent={navigator.userAgent}
      />
    </BrowserRouter>
  );
} catch (error) {
  errorLogger.log(error);
}
