import * as React from "react";
import { Subtract } from "utility-types";

export enum VirtualKeyboardStyle {
  Dark,
  Light,
}

export type VirtualKeyboardContextValue = {
  futureHeight?: number;
  height?: number;
  setStyle: (style: VirtualKeyboardStyle) => void;
};

const VirtualKeyboardContext = React.createContext<VirtualKeyboardContextValue>(
  {
    setStyle: () => {},
  }
);

export const useVirtualKeyboard = () =>
  React.useContext(VirtualKeyboardContext);

type InjectedProps = { virtualKeyboard: VirtualKeyboardContextValue };

export function withVirtualKeyboard<P extends InjectedProps>(
  Component: React.ComponentType<P>
): React.FC<Subtract<P, InjectedProps>> {
  return (props: Subtract<P, InjectedProps>) => {
    const virtualKeyboard = useVirtualKeyboard();
    return <Component virtualKeyboard={virtualKeyboard} {...(props as P)} />;
  };
}

export default VirtualKeyboardContext;
