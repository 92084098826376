import * as React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import urljoin from "url-join";
import queryString from "query-string";
import { h2Css, noteCss, paddedCss } from "../../../common/css";
import Logo from "../../../common/Logo";
import config from "../../../config";
import getSectionTitleFromStatuses from "../getSectionTitleFromStatuses";
import SmartQueryRenderer from "../../../common/SmartQueryRenderer";

export default function ModelImagecards({
  model: modelProp,
}: {
  model: any;
}): React.ReactNode {
  const searchParameters = queryString.parse(location.search);

  const imageIds =
    (Array.isArray(searchParameters.imageId) && searchParameters.imageId) ||
    (searchParameters.imageId && [searchParameters.imageId]);

  const model = useFragment(
    graphql`
      fragment ModelImagecards_model on Model {
        id
        publicName
        statuses
        images {
          id
        }
      }
    `,
    modelProp
  );

  return (
    <SmartQueryRenderer
      query={
        imageIds
          ? graphql`
              query ModelImagecardsSelectedImagesQuery($ids: [ID!]!) {
                modelImages(ids: $ids) {
                  id
                  model {
                    id
                  }
                }
              }
            `
          : graphql`
              query ModelImagecardsAllImagesQuery($id: ID!) {
                model(id: $id) {
                  images {
                    id
                  }
                }
              }
            `
      }
      variables={imageIds ? { ids: imageIds } : { id: model.id }}
      render={({ props }) => (
        <div id="imagecards" css={{ height: "100%" }}>
          {(imageIds ? props.modelImages : props.model.images).map((image) => (
            <div
              key={image.url}
              css={{
                ...paddedCss,
                boxSizing: "border-box",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                css={{
                  display: "flex",
                  marginLeft: "auto",
                  marginRight: "auto",
                  paddingBottom: "var(--spacing)",
                }}
              >
                <div
                  css={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    ">*": {
                      gridRowStart: "1",
                      gridColumnStart: "1",
                    },
                  }}
                >
                  <Logo height={40} />
                  <div
                    css={{
                      ...noteCss,
                      fontWeight: "500",
                      marginTop: 24,
                      marginLeft: 139,
                    }}
                  >
                    {getSectionTitleFromStatuses(model.statuses)}
                  </div>
                </div>
              </div>

              <div
                css={{
                  flex: 1,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundImage: `url(${urljoin(
                    config.files.imgixBaseUrl,
                    `${image.id}?max-w=5000`
                  )})`,
                }}
              />

              <div
                css={{
                  ...h2Css,
                  flexShrink: 0,
                  lineHeight: 1,
                  width: "100%",
                  textAlign: "center",
                  paddingTop: "var(--spacing)",
                }}
              >
                {model.publicName}
              </div>
            </div>
          ))}
        </div>
      )}
    />
  );
}
