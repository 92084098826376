import config from "../../config";

export default function getInstagramUrlFromStatuses(
  statuses: string[],
): string {
  if (
    statuses.includes("Babies") ||
    statuses.includes("Child") ||
    statuses.includes("Teens")
  )
    return config.instagramUrls.kids;
  if (statuses.includes("Commercial")) return config.instagramUrls.commercial;
  return config.instagramUrls.general;
}
