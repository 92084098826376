import * as React from "react";
import ReactDOM from "react-dom";
import { withTheme, Global, Theme } from "@emotion/react";
import color from "color";
import { mainCss, roundedBorderedCss, themeVarsCss } from "./css";
import Button from "./Button.tsx";
import CloseIcon from "./icons/CloseIcon";
import MeasuredDiv from "./MeasuredDiv";
import SafeAreaInsetsContext from "./SafeAreaInsetsContext";
import {
  VirtualKeyboardContextValue,
  withVirtualKeyboard,
} from "./VirtualKeyboardContext";
import {
  withAppMeasurements,
  AppMeasurementsContextValue,
} from "./AppMeasurementsContext";
import { SafeAreaInsets } from "./safeAreaInsets";
import Focuser from "./Focuser";

export type HoverContent = (props: {
  fullscreen: boolean;
  safeAreaInsets: SafeAreaInsets;
  toggleHoverContent: () => void;
  hideHoverContent: () => void;
  maxHeight?: number;
}) => React.ReactNode;

type Props = {
  children: (props: {
    ref: React.Ref<any>;
    toggleHoverContent: () => void;
    showHoverContent: () => void;
    hideHoverContent: () => void;
    onBlur?: () => void;
    showingHoverContent: boolean;
  }) => React.ReactElement;
  hoverContent: HoverContent;
  onHoverContentDidHide?: () => void;
  theme: Theme;
  hoverContentMaxWidth?: number;
  virtualKeyboard?: VirtualKeyboardContextValue;
  appMeasurements: AppMeasurementsContextValue;
  defaultShowingHoverContent?: boolean;
  keepHoverContentOnBlur?: boolean;
};

type State = {
  showingHoverContent?: boolean;
  fullscreenHoverContent?: boolean;
  coordinates?: { top?: number; bottom?: number; left?: number };
  bottomHeight?: number;
  hoverContentMaxHeight?: number;
};

const zeroSafeAreaInsets = { top: 0, right: 0, bottom: 0, left: 0 };

function getElement() {
  return document.getElementById("hoverBoxContents");
}

class HoverBox extends React.Component<Props, State> {
  state: State = {
    showingHoverContent: this.props.defaultShowingHoverContent,
  };

  unmounted?: boolean;

  children?: HTMLElement | null;

  hoverContent?: HTMLDivElement | null;

  hoverContentFocuser?: typeof Focuser | null;

  documentMouseDownListener?: (event: MouseEvent | TouchEvent) => void;

  componentDidUpdate(prevProps: Props) {
    if (prevProps.appMeasurements !== this.props.appMeasurements)
      this.updateHoverContentSizeAndPosition();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  onHoverContentDidShow = () => {
    if (
      document.activeElement &&
      !this.hoverContent?.contains(document.activeElement)
    )
      this.hoverContent?.focus();
    this.hoverContentFocuser?.autoFocus();
  };

  onHoverContentDidHide = () => {
    if (this.documentMouseDownListener)
      document.removeEventListener("mousedown", this.documentMouseDownListener);
    if (this.props.onHoverContentDidHide) this.props.onHoverContentDidHide();
  };

  toggleHoverContent = () => {
    if (!this.unmounted)
      this.setState(
        (state) => ({
          showingHoverContent: !state.showingHoverContent,
        }),
        () => {
          if (
            this.state.showingHoverContent &&
            !this.props.keepHoverContentOnBlur
          ) {
            this.documentMouseDownListener = (
              event: MouseEvent | TouchEvent
            ) => {
              if (
                this.children?.contains(event.target) === false &&
                getElement()?.contains(event.target) === false
              )
                this.hideHoverContent();
            };
            document.addEventListener(
              "mousedown",
              this.documentMouseDownListener
            );
            document.addEventListener(
              "touchstart",
              this.documentMouseDownListener
            );
            this.updateHoverContentSizeAndPosition();
            this.onHoverContentDidShow();
          } else this.onHoverContentDidHide();
        }
      );
  };

  showHoverContent = () => {
    if (!this.unmounted)
      this.setState({ showingHoverContent: true }, () => {
        this.updateHoverContentSizeAndPosition();
        this.onHoverContentDidShow();
      });
  };

  hideHoverContent = () => {
    if (!this.unmounted)
      this.setState({ showingHoverContent: false }, () => {
        this.updateHoverContentSizeAndPosition();
        this.onHoverContentDidHide();
      });
  };

  onBlur = (event: FocusEvent) => {
    // Children must be focusable, for example using tabindex=0. Otherwise event.relatedTarget will be null
    if (
      !this.props.keepHoverContentOnBlur &&
      !getElement()?.contains(event.relatedTarget) &&
      !this.children?.contains(event.relatedTarget)
    )
      this.hideHoverContent();
  };

  setHoverContentFocuser = (ref: typeof Focuser | null) => {
    this.hoverContentFocuser = ref;
  };

  setHoverContent = (ref: HTMLDivElement | null) => {
    if (ref) this.updateHoverContentSizeAndPosition();
    this.hoverContent = ref;
  };

  setChildren = (ref: HTMLElement | null) => {
    this.children?.removeEventListener("blur", this.onBlur);
    if (ref) {
      ref.addEventListener("blur", this.onBlur);
      this.updateHoverContentSizeAndPosition();
    }
    this.children = ref;
  };

  updateHoverContentSizeAndPosition = () => {
    if (this.unmounted) return;
    const { theme, appMeasurements, virtualKeyboard } = this.props;
    if (
      !this.state.showingHoverContent ||
      !this.children ||
      !appMeasurements?.safeAreaInsets
    )
      return;
    const childrenRect = this.children.getBoundingClientRect();
    const spaceTop = childrenRect.top - appMeasurements.safeAreaInsets.top;
    const spaceLeft = childrenRect.left;
    const spaceRight = window.innerWidth - spaceLeft;
    const spaceBottom =
      window.innerHeight -
      childrenRect.bottom -
      Math.max(
        appMeasurements.safeAreaInsets.bottom,
        virtualKeyboard?.height || 0,
        virtualKeyboard?.futureHeight || 0
      );
    const positionAbove = spaceTop > spaceBottom;
    const newState: { [key: string]: any } = {
      hoverContentMaxHeight: (positionAbove ? spaceTop : spaceBottom) - 2, // Adjust for border
    };

    if (this.hoverContent) {
      if (window.innerHeight < 500 || window.innerWidth < 500) {
        newState.coordinates = undefined;
        newState.fullscreenHoverContent = true;
      } else {
        const hoverContentRect = this.hoverContent.getBoundingClientRect();
        const hoverContentMarginRight = spaceRight - hoverContentRect.width;
        newState.coordinates = {
          top: positionAbove ? undefined : childrenRect.bottom,
          bottom: positionAbove
            ? window.innerHeight - childrenRect.top
            : undefined,
          left:
            hoverContentMarginRight < theme.paddingRight
              ? Math.max(
                  theme.paddingLeft,
                  childrenRect.left +
                    hoverContentMarginRight -
                    theme.paddingRight
                )
              : childrenRect.left,
        };
      }
    } else {
      newState.coordinates = undefined;
      newState.fullscreenHoverContent = undefined;
    }

    this.setState(newState);
  };

  onBottomMeasuredChange = ({ height }: { height: number }) =>
    this.setState({ bottomHeight: height });

  render() {
    const {
      children,
      hoverContent,
      hoverContentMaxWidth,
      theme,
      virtualKeyboard,
      appMeasurements,
    } = this.props;
    const {
      coordinates,
      showingHoverContent,
      fullscreenHoverContent: fullscreen,
      bottomHeight,
      hoverContentMaxHeight,
    } = this.state;

    return (
      <>
        {children({
          ref: this.setChildren,
          toggleHoverContent: this.toggleHoverContent,
          showHoverContent: this.showHoverContent,
          hideHoverContent: this.hideHoverContent,
          showingHoverContent: !!showingHoverContent,
        })}

        {hoverContent &&
          showingHoverContent &&
          typeof window !== "undefined" &&
          ReactDOM.createPortal(
            (() => {
              let actualHoverContent = null;

              if (!fullscreen || bottomHeight !== undefined) {
                let hoverContentSafeAreaInsets: SafeAreaInsets;
                if (fullscreen) {
                  hoverContentSafeAreaInsets = {
                    ...appMeasurements.safeAreaInsets,
                    bottom: Math.max(
                      appMeasurements.safeAreaInsets.bottom +
                        (bottomHeight || 0),
                      (virtualKeyboard?.futureHeight === undefined
                        ? virtualKeyboard?.height
                        : virtualKeyboard?.futureHeight) || 0
                    ),
                  };
                  if (hoverContentMaxWidth !== undefined) {
                    const leftRightSpace =
                      (appMeasurements.width - hoverContentMaxWidth) / 2;
                    if (leftRightSpace > 0) {
                      hoverContentSafeAreaInsets.left = Math.max(
                        0,
                        hoverContentSafeAreaInsets.left - leftRightSpace
                      );
                      hoverContentSafeAreaInsets.right = Math.max(
                        0,
                        hoverContentSafeAreaInsets.right - leftRightSpace
                      );
                    }
                  }
                } else hoverContentSafeAreaInsets = zeroSafeAreaInsets;

                actualHoverContent = (
                  <Focuser ref={this.setHoverContentFocuser}>
                    <SafeAreaInsetsContext.Provider
                      value={hoverContentSafeAreaInsets}
                    >
                      {hoverContent({
                        fullscreen: !!fullscreen,
                        toggleHoverContent: this.toggleHoverContent,
                        hideHoverContent: this.hideHoverContent,
                        safeAreaInsets: hoverContentSafeAreaInsets,
                        maxHeight: fullscreen
                          ? undefined
                          : hoverContentMaxHeight,
                      })}
                    </SafeAreaInsetsContext.Provider>
                  </Focuser>
                );
              }

              return (
                <div
                  ref={this.setHoverContent}
                  tabIndex={0}
                  onBlur={this.onBlur}
                  css={{
                    ...themeVarsCss(theme),
                    ...mainCss,
                    ...(fullscreen ? {} : roundedBorderedCss()),
                    background: fullscreen
                      ? `rgba(${color(theme.backgroundColor)
                          .rgb()
                          .array()
                          .map((component) => Math.round(component))
                          .join(",")},0.95)`
                      : "var(--background-color)",
                    "@supports(backdrop-filter: blur(10px))": {
                      backdropFilter: fullscreen ? "blur(10px)" : undefined,
                      background: fullscreen
                        ? `rgba(${color(theme.backgroundColor)
                            .rgb()
                            .array()
                            .map((component) => Math.round(component))
                            .join(",")},0.7)`
                        : "var(--background-color)",
                    },
                    boxSizing: "border-box",
                    position: "fixed",
                    top: fullscreen ? 0 : coordinates?.top,
                    right: fullscreen ? 0 : undefined,
                    bottom: fullscreen
                      ? 0
                      : (coordinates?.bottom &&
                          virtualKeyboard &&
                          Math.max(
                            virtualKeyboard?.height + theme.paddingBottom,
                            coordinates.bottom
                          )) ||
                        coordinates?.bottom,
                    left: fullscreen ? 0 : coordinates?.left,
                    width:
                      hoverContentMaxWidth && !fullscreen ? "100%" : undefined,
                    maxWidth: fullscreen ? undefined : hoverContentMaxWidth,
                    maxHeight: fullscreen ? undefined : hoverContentMaxHeight,
                    visibility:
                      !fullscreen &&
                      coordinates?.top === undefined &&
                      coordinates?.bottom === undefined
                        ? "hidden"
                        : undefined,
                    zIndex: 10,
                    overflow: "auto",
                    display: "flex",
                  }}
                >
                  {actualHoverContent}

                  {fullscreen && (
                    <>
                      <Global
                        styles={{
                          html: {
                            width: "100%",
                            height: "100%",
                            position: "fixed", // Disable bouncy scroll
                          },

                          "body, #content": {
                            height: "100%",
                            overflow: "hidden",
                          },
                        }}
                      />
                      {bottomHeight && (
                        <div
                          css={{
                            position: "fixed",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height:
                              bottomHeight +
                              (appMeasurements.safeAreaInsets.bottom || 0),
                            pointerEvents: "none",
                            background: `linear-gradient(rgba(${color(
                              theme.backgroundColor
                            )
                              .rgb()
                              .array()
                              .map(Math.round)
                              .join(",")},0), var(--background-color))`,
                          }}
                        />
                      )}
                      <MeasuredDiv
                        height
                        onChange={this.onBottomMeasuredChange}
                        css={{
                          position: "fixed",
                          paddingBottom: "var(--padding-bottom)",
                          bottom: appMeasurements.safeAreaInsets.bottom || 0,
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      >
                        <Button type="naked" onClick={this.hideHoverContent}>
                          <div
                            css={{
                              ...roundedBorderedCss({ radius: "50%" }),
                              padding: "var(--spacing)",
                              background: "var(--background-color)",
                            }}
                          >
                            <CloseIcon
                              width={Math.floor(theme.iconSize * 1.6)}
                              color="var(--note-color)"
                              css={{ display: "block" }}
                            />
                          </div>
                        </Button>
                      </MeasuredDiv>
                    </>
                  )}
                </div>
              );
            })(),
            getElement()
          )}
      </>
    );
  }
}

export default withAppMeasurements(withVirtualKeyboard(withTheme(HoverBox)));
