/**
 * @generated SignedSource<<31bf03d45294738fe1609b4818e5bc45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MainLoadModelListQuery$variables = {
  id: string;
};
export type MainLoadModelListQuery$data = {
  readonly modelList: {
    readonly models: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type MainLoadModelListQuery = {
  response: MainLoadModelListQuery$data;
  variables: MainLoadModelListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Model",
  "kind": "LinkedField",
  "name": "models",
  "plural": true,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MainLoadModelListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ModelList",
        "kind": "LinkedField",
        "name": "modelList",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MainLoadModelListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ModelList",
        "kind": "LinkedField",
        "name": "modelList",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2b7fcceef14cc8476815cafe713491b4",
    "id": null,
    "metadata": {},
    "name": "MainLoadModelListQuery",
    "operationKind": "query",
    "text": "query MainLoadModelListQuery(\n  $id: ID!\n) {\n  modelList(id: $id) {\n    models {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d055b4f3f00f93ca7cd98069d39361d8";

export default node;
