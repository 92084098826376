import * as React from "react";
import * as queryString from "query-string";
import urljoin from "url-join";
import config from "../config";

type Source =
  | string
  | {
      id: string;
      contentType?: string;
      width?: number;
      height?: number;
    }
  | File;

const dprs = [1, 2, 3, 4];

const isFile = (object: any) => !!object.path;

function getSrcAttributes({
  src,
  width,
  height,
  maxWidth,
  maxHeight,
  fit,
}: {
  src: any;
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
  fit?: string;
}): { src?: string; srcSet?: string } {
  let path: string;
  let useImgix = false;
  let category: "files" | "assets" | undefined;
  let queryParams: { [key: string]: any } = { fit };

  if (typeof src === "object" && src.id) {
    category = "files";
    useImgix = src.contentType !== "image/svg+xml";
    path = src.id;

    if (src.width && src.height) {
      const aspectRatio = src.width / src.height;

      if (width === undefined && height) width = height * aspectRatio;
      else if (height === undefined && width) height = width / aspectRatio;

      if (maxWidth && maxHeight && aspectRatio) {
        if (aspectRatio >= maxWidth / maxHeight) width = maxWidth;
        else height = maxHeight;
      }
    }
  } else if (typeof src === "string") {
    category = "assets";

    const questionMarkIndex = src.indexOf("?");
    path = questionMarkIndex === -1 ? src : src.substring(0, questionMarkIndex);

    useImgix = !path.endsWith(".svg");

    if (questionMarkIndex !== -1)
      queryParams = {
        ...queryParams,
        ...queryString.parse(src.substring(questionMarkIndex)),
      };

    if (width === undefined && queryParams.width !== undefined)
      width = Number(queryParams.width) || queryParams.width;
    if (height === undefined && queryParams.height !== undefined)
      height = Number(queryParams.height) || queryParams.height;
  }

  const passDownProps: { src?: string; srcSet?: string } = {};

  if (category) {
    if (!config[category].imgixBaseUrl) useImgix = false;

    const getUrl = (dpr: number) =>
      `${urljoin(
        config[category][useImgix ? "imgixBaseUrl" : "baseUrl"],
        path,
      )}${
        useImgix
          ? `?${queryString.stringify({
              ...queryParams,
              auto: "format",
              dpr: dpr > 1 ? dpr : undefined,
              w: width,
              h: height,
              q: 80 / 2 ** (dpr - 1),
            })}`
          : ""
      }`;

    passDownProps.src = getUrl(1);

    if (useImgix)
      passDownProps.srcSet = dprs
        .map((dpr) => `${getUrl(dpr)} ${dpr}x`)
        .join(", ");
  }

  return passDownProps;
}

export default function Image({
  src,
  darkModeSrc,
  width,
  height,
  maxWidth,
  maxHeight,
  lazyLoading = true,
  fit,
  ...otherProps
}: {
  src: Source;
  darkModeSrc?: Source;
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
  lazyLoading?: boolean;
  fit?: string;
}): React.ReactElement {
  const [srcFileDataUrl, setSrcFileDataUrl] = React.useState<string>();

  React.useEffect(() => {
    if (isFile(src)) {
      const fileReader = new FileReader();
      fileReader.onload = () => setSrcFileDataUrl(fileReader.result as string);
      fileReader.readAsDataURL(src as File);
    }
  }, [src]);

  const img = (
    <img
      css={{
        width,
        height,
        objectFit: "contain", // We are sure about the size calculations, but just in case they're wrong - to not stretch/distort the image
      }}
      loading={lazyLoading ? "lazy" : undefined}
      {...(isFile(src)
        ? { src: srcFileDataUrl }
        : getSrcAttributes({
            src,
            width,
            height,
            maxWidth,
            maxHeight,
            fit,
          }))}
      {...otherProps}
    />
  );

  if (darkModeSrc) {
    const darkModeSourceAttributes = getSrcAttributes({
      src: darkModeSrc,
      width,
      height,
      maxWidth,
      maxHeight,
      fit,
    });

    return (
      <picture>
        <source
          media="(prefers-color-scheme: dark)"
          srcSet={
            darkModeSourceAttributes.srcSet || darkModeSourceAttributes.src
          }
        />
        {img}
      </picture>
    );
  }

  return img;
}
