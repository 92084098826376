export default {
  light: {
    mainColor: "#000000",
    backgroundColor: "#ffffff",
    alternativeBackgroundColor: "#f5f5f5",
    textColor: "#373e42",
    errorColor: "#ff0033",
    separatorColor: "#e5e5e5",
  },
};
