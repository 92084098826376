import { remove as removeDiacritics } from "diacritics";
import escapeRegexpString from "./escapeRegexpString";

export type Range = [number, number];

export default class WordPrefixMatcher {
  regexp: RegExp;

  constructor(searchString: string) {
    this.regexp = new RegExp(
      `${removeDiacritics(searchString.trim())
        .split(/\s/)
        .map((word) => escapeRegexpString(word))
        .join(".*?\\s+?")}`,
      "i"
    );
  }

  matches(string: string): boolean {
    return this.regexp.test(removeDiacritics(string));
  }

  getMatches(string: string): Range[] | null {
    const match = this.regexp.exec(removeDiacritics(string));

    return match ? [[match.index, match.index + match[0].length]] : null;
  }
}
