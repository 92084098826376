import * as React from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "@emotion/react";
import errorLogger from "./ErrorLogger";
import AuthenticationError from "./AuthenticationError";
import AuthorizationError from "./AuthorizationError";
import { useSecurity } from "./SecurityContext";
import ErrorMessage from "./ErrorMessage";
import Button from "./Button.tsx";
import config from "../config";

export default function ErrorHandler({
  error,
  extra,
  retry,
  ...otherProps
}: {
  error: any;
  extra?: any;
  retry?: (() => void) | null;
} & React.HtmlHTMLAttributes<HTMLDivElement>): React.ReactElement {
  const security = useSecurity();
  const theme = useTheme();
  const history = useHistory();

  const canLogOut =
    error instanceof AuthenticationError || error instanceof AuthorizationError;

  return (
    <div {...otherProps}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <ErrorMessage error={errorLogger.log(error, extra)} />
        {(retry || canLogOut) && (
          <div
            css={{
              marginTop: "var(--spacing)",
              ">*:not(:last-child)": {
                marginRight: theme.spacing * 2,
              },
            }}
          >
            {retry && (
              <Button type="naked" onClick={retry}>
                Retry
              </Button>
            )}
            {canLogOut && (
              <Button
                type="naked"
                onClick={() => {
                  history.push(config.logInPath);
                  security.logOut();
                }}
              >
                Log out
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
