import isEmail from "validator/lib/isEmail";
import { Validator } from "./forms/Field.tsx";

// Validators contract: return error message (first word uppercase, once sentence and no ending punctuation) or false

export const validateEmail: Validator = ({ value }) =>
  value && !isEmail(value) && "Invalid e-mail address";

export const emailValidators = [validateEmail];

export const validateNumber: Validator = ({ value }) => {
  if (!value) return false;
  return isNaN(value) && "Invalid number";
};

export const validateMax255Chars: Validator = ({ value }) =>
  value &&
  typeof value === "string" &&
  value.length > 255 &&
  "Text can't be longer than 255 characters";

export const validateRequired: Validator = ({ value }) =>
  (value === "" || value === null || value === undefined) && "Required";

export const validateNonWhitespaceRequired = ({ value }) =>
  ((typeof value === "string" && value.trim() === "") ||
    value === null ||
    value === undefined) &&
  "Required";
