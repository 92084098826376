import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "relay-runtime";
import Button from "../common/Button.tsx";
import { doublePaddedCss, h2Css, paddedCss, strongCss } from "../common/css";
import CheckboxField from "../common/forms/CheckboxField.tsx";
import EmailAddressField from "../common/forms/EmailAddressField.tsx";
import FilesField from "../common/forms/FilesField.tsx";
import Form from "../common/forms/Form.tsx";
import TextField from "../common/forms/TextField.tsx";
import Link from "../common/Link";
import Footer from "./common/Footer";

export default function Updates(): React.ReactElement {
  return (
    <div
      css={{ ...doublePaddedCss, maxWidth: "var(--max-width)", margin: "auto" }}
    >
      <Helmet title="Updates" />
      <Form
        mutation={graphql`
          mutation UpdatesMutation($input: CreateModelUpdateInput!) {
            createModelUpdate(input: $input) {
              modelUpdate {
                id
              }
            }
          }
        `}
        mutationVariables={({ files, ...values }) => ({
          input: { ...values, files: "files" },
        })}
        mutationUploadables={({ files }) => ({
          files,
        })}
        notification="Update sent"
      >
        {({ submit, submitted }) =>
          submitted ? (
            "Thank you!"
          ) : (
            <div
              css={{
                marginBottom: "calc(-2 * var(--spacing))",
                ">*": {
                  marginBottom: "calc(2 * var(--spacing))",
                },
                "@media (min-width: 800px)": {
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  ">*": {
                    flex: 1,
                    minWidth: "calc(50% - 2 * var(--spacing))",
                    ":not(:last-child)": {
                      marginRight: "calc(4 * var(--spacing))",
                    },
                  },
                },
              }}
            >
              <div>
                <div css={h2Css}>Updates</div>
                If you wish to apply as a model, please go to{" "}
                <Link to="/become-a-model">Become a model</Link>. Updates is for
                our models who wish to send us their new images.
                <br />
                <br />
                We kindly ask you not to e-mail images.
                <br />
                <br />
                Denna funktion är till för dig som redan är medlem i
                Stockholmsgruppen. Har du nya bilder på dig själv eller ditt
                barn laddar du upp dem här.
                <br />
                <br />
                Vi ber dig att inte skicka bilderna via e-mail.
                <br />
                <br />
                Många har laddat upp semesterbilder på sina barn i keps,
                solglasögon och med glass över hela ansiktet.
                <br />
                <br />
                Gulligt i sig, men inte vad vi vill ha. Kunden ska kunna se hur
                barnet ser ut!
              </div>
              <div
                css={{
                  ...paddedCss,
                  background: "var(--alternative-background-color)",
                  borderRadius: "var(--round-corner-border-radius)",
                  boxSizing: "border-box",
                  ">*:not(:last-child)": {
                    marginBottom: "var(--spacing)",
                  },
                }}
              >
                <TextField
                  label="Your name"
                  name="name"
                  nonWhitespaceRequired
                />
                <TextField
                  label="Model name"
                  name="modelName"
                  nonWhitespaceRequired
                />

                <EmailAddressField
                  label="Your e-mail"
                  name="emailAddress"
                  required
                />

                <TextField
                  label="Your phone number"
                  name="phoneNumber"
                  nonWhitespaceRequired
                />

                <div css={{ ...strongCss, marginBottom: "var(--spacing)" }}>
                  Files
                </div>
                <FilesField name="files" />

                <TextField
                  label="Message"
                  name="message"
                  multiline
                  autoSize
                  minRows={5}
                  note="New sizes etc"
                />

                <CheckboxField
                  label="Jag har läst och godkänt villkoren / I have read and agreed to the terms"
                  note={
                    <Link href="/updates-terms" target="_blank">
                      Läs villkoren / Read the terms
                    </Link>
                  }
                  required
                />

                <Button onClick={submit} css={{ display: "block" }}>
                  Submit
                </Button>
              </div>
            </div>
          )
        }
      </Form>

      <Footer css={{ marginTop: "calc(2 * var(--spacing))" }} />
    </div>
  );
}
