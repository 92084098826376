import * as React from "react";
import { useTheme } from "@emotion/react";
import ErrorMessage from "./ErrorMessage";
import { roundedBorderedCss, noteCss } from "./css";
import {
  Notification as NotificationType,
  NotificationType as Type,
  useNotifications,
} from "./NotificationsContext";

import Button from "./Button.tsx";
import nl2br from "./nl2br";

export default function Notification({
  children,
  notification: propsNotification,
  notifications,
  error,
  type: propsType,
  compact,
  ...otherProps
}: {
  children?: React.ReactNode;
  notification?: NotificationType;
  notifications?: NotificationType[];
  error?: any;
  type?: Type;
  compact?: boolean;
}): React.ReactElement {
  const { dismissNotifications } = useNotifications();
  const theme = useTheme();
  const [dismissing, setDismissing] = React.useState<boolean>();

  const notification = propsNotification || (notifications && notifications[0]);

  React.useEffect(() => {
    if (notification && notification.autoHide) {
      const timeoutRef = setTimeout(() => setDismissing(true), 2000);
      return () => clearTimeout(timeoutRef);
    }
    return undefined;
  }, []);

  let type = propsType;
  let content;

  if (children) content = children;
  else if (error) {
    type = "error";
    content = <ErrorMessage error={error} />;
  } else if (notification) {
    const { message, error } = notification;
    type = notification.type;

    if (message) content = nl2br(message);
    else if (error) content = <ErrorMessage error={error} />;
  }

  let borderColor;
  if (type === "error") borderColor = "var(--error-color)";
  else if (type === "warning") borderColor = "orange";

  const paddingMultiplier = compact ? 0.5 : 1;
  const paddingBottom = theme.paddingBottom * paddingMultiplier;
  const showCloseButton = notification && !notification.autoHide;

  const dismiss = () => dismissNotifications(notifications || [notification]);

  return (
    <div {...otherProps}>
      <div
        css={{
          ...roundedBorderedCss({
            width: borderColor ? undefined : 0,
            color: borderColor,
          }),
          paddingTop: theme.paddingTop * paddingMultiplier,
          paddingRight: theme.paddingRight * paddingMultiplier,
          paddingBottom: showCloseButton ? 0 : paddingBottom,
          paddingLeft: theme.paddingLeft * paddingMultiplier,
          background: !borderColor
            ? "var(--alternative-background-color)"
            : "var(--background-color)",
          overflow: "hidden",
          boxSizing: "border-box",
          opacity: dismissing ? 0 : 1,
          transition: "opacity 0.5s",
          position: "relative",
        }}
        onTransitionEnd={dismiss}
      >
        {notifications && (
          <div
            css={{
              ...noteCss,
              position: "absolute",
              top: theme.spacing,
              left: theme.spacing,
            }}
          >
            {notifications.length}x
          </div>
        )}
        <span css={{ verticalAlign: "middle" }}>{content}</span>
        {showCloseButton && (
          <Button
            type="naked"
            css={{ display: "block", paddingTop: theme.spacing, paddingBottom }}
            onClick={dismiss}
          >
            Close
          </Button>
        )}
      </div>
    </div>
  );
}
