import * as React from "react";
import composeRefs from "@seznam/compose-react-refs";
import { Theme, useTheme } from "@emotion/react";
import { createLocation } from "history";
import { useHistory, useLocation } from "react-router-dom";
import Field from "./Field.tsx";
import DownArrowIcon from "../icons/DownArrowIcon";
import { roundedBorderedCss } from "../css";
import HoverBox, { HoverContent } from "../HoverBox";
import Focuser from "../Focuser";
import { generateUniqueId } from "../unique-id";
import Measured from "../Measured";
import CloseIcon from "../icons/CloseIcon";
import Table from "../Table";
import MeasuredDiv from "../MeasuredDiv";
import Button from "../Button.tsx";
import getTextWidth from "../getTextWidth";

export function getWidth({
  label,
  theme,
}: {
  label: string;
  theme: Theme;
}): number {
  return (
    getTextWidth(label, {
      fontFamily: theme.fontFamily,
      fontSize: theme.fontSize,
      fontWeight: 400,
    }) +
    theme.spacing * 2 +
    2
  );
}

const optionColumn = "option";
const columns = [optionColumn];

export default function SelectField({
  changeValueGetter,
  labelGenerator = (option: any) => option,
  valueGenerator = (option: any) => option,
  hoverContentGenerator,
  options,
  loading,
  showClearButton,
  multi,
  naked,
  ...otherProps
}: {
  options?: any[];
  loading?: boolean;
  labelGenerator?: (option: any) => React.ReactNode;
  valueGenerator?: (option: any) => string;
  value?: any;
  readOnly?: boolean;
  placeholder?: React.ReactElement;
  changeValueGetter: (event: any) => any;
  naked?: boolean;
  hoverContentGenerator?: ({
    option,
    onChange,
  }: {
    option: any;
    onChange: (option: any) => void;
  }) => HoverContent;
  showClearButton?: boolean;
  columns: string[];
  multi: boolean;
} & Omit<
  React.ComponentPropsWithoutRef<typeof Field>,
  "renderInput"
>): React.ReactElement {
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const [hoverContentOption, setHoverContentOption] = React.useState();
  const [optionHoverContentKey, setOptionHoverContentKey] =
    React.useState<string>();

  return (
    <Measured width>
      {({ ref: measuredRef, width: fieldWidth }) => (
        <Field
          {...otherProps}
          renderInput={(
            { value, readOnly, onChange },
            { focused, validationErrorMessages },
          ) => {
            const optionHoverContent =
              hoverContentGenerator &&
              hoverContentOption &&
              hoverContentGenerator({ option: hoverContentOption, onChange });

            return (
              <HoverBox
                onHoverContentDidHide={setHoverContentOption}
                hoverContentMaxWidth={fieldWidth}
                hoverContent={
                  readOnly
                    ? undefined
                    : (optionHoverContent &&
                        ((hoverContentProps: any) => (
                          <Focuser key={optionHoverContentKey} autoFocusOnMount>
                            {optionHoverContent(hoverContentProps)}
                          </Focuser>
                        ))) ||
                      (({
                        fullscreen,
                        safeAreaInsets,
                        hideHoverContent,
                        maxHeight,
                      }) => (
                        <MeasuredDiv
                          width
                          height
                          css={{
                            width: "100%",
                            height: fullscreen ? "100%" : undefined,
                            display: "flex",
                          }}
                        >
                          {({ width, height }) =>
                            !!width && (
                              <Table
                                css={{ margin: "auto" }}
                                width={width}
                                maxHeight={fullscreen ? height : maxHeight}
                                items={options}
                                loading={loading}
                                selectedItemKeys={
                                  (value !== undefined &&
                                    (Array.isArray(value) ? value : [value])) ||
                                  undefined
                                }
                                itemKeyGenerator={valueGenerator}
                                columns={columns}
                                hideRowSeparator
                                rowCellRenderer={({ item }) => {
                                  const { to, href, icon, iconComponent } =
                                    item || {};
                                  return (
                                    <Button
                                      css={{ display: "block" }}
                                      type="discrete"
                                      to={to}
                                      href={href}
                                      icon={icon}
                                      iconComponent={iconComponent}
                                      labelAlign={
                                        fullscreen ? "center" : "left"
                                      }
                                    >
                                      {labelGenerator(item)}
                                    </Button>
                                  );
                                }}
                                onRowClick={({ item }) => {
                                  const { to, href } = item || {};
                                  if (to)
                                    history.push(
                                      createLocation(
                                        to,
                                        undefined,
                                        undefined,
                                        location,
                                      ),
                                    );
                                  else if (href) history.push(href);
                                  else if (
                                    hoverContentGenerator &&
                                    hoverContentGenerator({ option: item })
                                  ) {
                                    setHoverContentOption(item);
                                    setOptionHoverContentKey(
                                      generateUniqueId(),
                                    );
                                  } else {
                                    const itemValue = valueGenerator(item);
                                    if (multi) {
                                      const oldValue = value || [];
                                      onChange(
                                        oldValue.includes(itemValue)
                                          ? oldValue.filter(
                                              (v: any) => v !== itemValue,
                                            )
                                          : [...oldValue, itemValue],
                                      );
                                    } else {
                                      onChange(itemValue);
                                      hideHoverContent();
                                    }
                                  }
                                }}
                                paddingTop={
                                  safeAreaInsets.top +
                                  Math.floor(theme.spacing / 2)
                                }
                                paddingBottom={
                                  safeAreaInsets.bottom +
                                  Math.floor(theme.spacing / 2)
                                }
                                contentPaddingLeft={
                                  theme.spacing + safeAreaInsets.right
                                }
                                contentPaddingRight={
                                  theme.spacing + safeAreaInsets.right
                                }
                              />
                            )
                          }
                        </MeasuredDiv>
                      ))
                }
              >
                {({ ref: hoverRef, toggleHoverContent, hideHoverContent }) => (
                  <div
                    ref={composeRefs(hoverRef, measuredRef)}
                    css={{
                      ...(naked
                        ? {}
                        : roundedBorderedCss({
                            color:
                              (focused && "var(--main-color)") ||
                              (validationErrorMessages &&
                                "var(--error-color)") ||
                              "var(--separator-color)",
                          })),
                      background: "var(--background-color)",
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                      cursor: readOnly ? undefined : "pointer",
                      position: "relative",
                      paddingRight: naked ? 0 : Math.floor(8 * theme.size),
                      paddingLeft: naked ? 0 : Math.floor(8 * theme.size),
                      height: naked
                        ? undefined
                        : Math.floor(
                            theme.fontSize * theme.lineHeight +
                              (10 + 2) * theme.size,
                          ),
                    }}
                    tabIndex={0}
                  >
                    <div
                      css={{
                        flex: naked ? undefined : 1,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        overflowX: "hidden",
                        textOverflow: "ellipsis",
                        userSelect: "none",
                        ">*:not(:last-child):after": {
                          content: "', '",
                          whiteSpace: "pre",
                        },
                      }}
                      onClick={toggleHoverContent}
                    >
                      {options &&
                        (
                          (multi && (value || [])) ||
                          (value !== undefined ? [value] : [])
                        ).map((v: any) => (
                          <span key={v}>
                            {labelGenerator(
                              options.find(
                                (option) => v === valueGenerator(option),
                              ),
                            )}
                          </span>
                        ))}
                    </div>
                    {!readOnly && (
                      <div
                        css={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: Math.floor(4 * theme.size),
                          paddingRight: naked ? 0 : Math.floor(2 * theme.size),
                          ">*": { display: "block" },
                        }}
                        onClick={() => {
                          if (
                            showClearButton &&
                            value !== undefined &&
                            value !== null
                          ) {
                            onChange();
                            hideHoverContent();
                          } else toggleHoverContent();
                        }}
                      >
                        {showClearButton &&
                        value !== undefined &&
                        value !== null ? (
                          <CloseIcon
                            height={theme.iconSize}
                            color="var(--note-color)"
                          />
                        ) : (
                          <DownArrowIcon
                            height={theme.iconSize}
                            color="var(--note-color)"
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </HoverBox>
            );
          }}
          changeValueGetter={(value: any) =>
            changeValueGetter ? changeValueGetter(value) : value
          }
        />
      )}
    </Measured>
  );
}
