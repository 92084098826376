import * as React from "react";
import TextField from "./TextField.tsx";
import { useLocale } from "../LocaleContext";
import NumberParser from "../NumberParser";

export default function NumberField({
  changeValueGetter,
  validators,
  minValue,
  maxValue,
  ...otherProps
}: React.ComponentPropsWithoutRef<typeof TextField> & {
  minValue?: number;
  maxValue?: number;
}): React.ReactElement {
  const locale = useLocale();
  const numberParser = React.useMemo(
    () => new NumberParser({ locale, allowGrouping: false }),
    [locale]
  );

  const actualValidators = React.useMemo(
    () => [
      ({ value }) =>
        minValue !== undefined &&
        (value === 0 || (!!value && !Number.isNaN(Number(value)))) &&
        value < minValue &&
        `Must be at least ${minValue}`,
      ({ value }) =>
        maxValue !== undefined &&
        (value === 0 || (!!value && !Number.isNaN(Number(value)))) &&
        value > maxValue &&
        `Can be at most ${maxValue}`,
      ({ value }) => {
        if (!value) return false;
        const number =
          typeof value === "string" ? numberParser.parse(value) : value;
        return (
          (Number.isNaN(number) || !Number.isFinite(number)) && "Invalid number"
        );
      },
      ...(validators || []),
    ],
    [validators, minValue, maxValue, numberParser]
  );

  const actualChangeValueGetter = React.useCallback(
    (v: string) => {
      if (v?.trim && v?.trim() === "") return null;
      const numberValue = numberParser.parse(v);
      const actualValue =
        Number.isNaN(numberValue) || !Number.isFinite(numberValue)
          ? v
          : numberValue;
      return changeValueGetter ? changeValueGetter(actualValue) : actualValue;
    },
    [numberParser, changeValueGetter]
  );

  return (
    <TextField
      type="decimal"
      validators={actualValidators}
      changeValueGetter={actualChangeValueGetter}
      renderValue={(value) =>
        (typeof value === "number" &&
          new Intl.NumberFormat(locale, { useGrouping: false }).format(
            value
          )) ||
        value
      }
      {...otherProps}
    />
  );
}
